import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ListComponent from "../../form-bb-components/list-component/list-component";
import produce from "immer";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import { IconsMap } from "../../../_helpers/icons_set";

class Section6 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-6'),
            showValidation: props.showValidationErrors,
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    };

    render() {
        const {
          disabled,
          subsectionName,
          sectionNumber,
          translateKey,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
        } = this.props;
        const { section } = this.state;
        const isOrientationTemporal = section.getFieldByKey('is_orientation_temporal');
        const isOrientationLocational = section.getFieldByKey('is_orientation_locational');
        const isOrientationPersonal = section.getFieldByKey('is_orientation_personal');
        const classForList = "col-sm-12 col-lg-4 form-margin-bottom-fix text-align-center";
        const isNewUI = true; 

        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <ListComponent 
                                    disabled={disabled}
                                    newUI={isNewUI}
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={this.props.showValidationErrors}
                                    className={classForList}
                                    transKey="form-text-zeitlich"
                                    key="is_orientation_temporal"
                                    keyName="is_orientation_temporal"
                                    field={isOrientationTemporal}
                                    type="column"
                                    svgIcon
                                    icon={IconsMap.svg.zeitlich}
                                    value={isOrientationTemporal.value}
                                    validationErrors={isOrientationTemporal.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: translateKey("form-text-uneingeschrankt")},
                                        { value: '717700001', label: translateKey("form-text-teilweise")},
                                        { value: '717700002', label: translateKey("form-text-eingeschrankt")}
                                    ]}
                                />

                                <ListComponent
                                    disabled={disabled}
                                    newUI={isNewUI}
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={this.props.showValidationErrors}
                                    className={classForList}
                                    transKey="form-text-ortlich"
                                    key="is_orientation_locational"
                                    keyName="is_orientation_locational"
                                    field={isOrientationLocational}
                                    type="column"
                                    svgIcon
                                    icon={IconsMap.svg.ortlich}
                                    value={isOrientationLocational.value}
                                    validationErrors={isOrientationLocational.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: translateKey("form-text-uneingeschrankt")},
                                        { value: '717700001', label: translateKey("form-text-teilweise")},
                                        { value: '717700002', label: translateKey("form-text-eingeschrankt")}
                                    ]}
                                />

                                <ListComponent
                                    disabled={disabled}
                                    newUI={isNewUI}
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={this.props.showValidationErrors}
                                    className={classForList}
                                    transKey="form-text-personlich"
                                    key="is_orientation_personal"
                                    keyName="is_orientation_personal"
                                    field={isOrientationPersonal}
                                    type="column"
                                    svgIcon
                                    icon={IconsMap.svg.personlich}
                                    value={isOrientationPersonal.value}
                                    validationErrors={isOrientationPersonal.validationErrors}
                                    valuesList={[
                                        { value: '717700000', label: translateKey("form-text-uneingeschrankt")},
                                        { value: '717700001', label: translateKey("form-text-teilweise")},
                                        { value: '717700002', label: translateKey("form-text-eingeschrankt")}
                                    ]}
                                />
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section6));
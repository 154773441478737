import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ListTileComponent from "../../form-bb-components/list-tile-component/list-tile-component";
import SwitchComponent from "../../form-bb-components/switch-component/switch-component";
import ListPflegegradComponent from "../../form-bb-components/list-pflegegrad-component/list-pflegegrad-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import produce from "immer";

const isAppliedLast6Months = "is_applied_last_6_months";
const isAppliedPflegegrad = "is_applied_pflegegrad";

class Section3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-3'),
            showValidation: props.showValidationErrors,
            is_kein_pflegegrad: false
        };
    }

    componentDidMount(){
        const { section } = this.state;
        let isPflegegrad = section.getFieldByKey('is_pflegegrad');
        if(isPflegegrad.value!==''){
            this.setState({is_kein_pflegegrad: false})
        }
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChange = (key, value) => {
        if(!this.props.disabled){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value
                }
                if(key===isAppliedLast6Months){
                    if(value==='717700001'){
                        const field = section.getFieldByKey(isAppliedPflegegrad);
                        if (field) {field.value = ''}
                    }
                }
            });
            this.setState(nextState);
        }
    };

    clearMeFields = (key, value) =>{
        if(value===0){
            const { section } = this.state;
            section.getFieldByKey('is_pflegegrad').value = '';
            //section.getFieldByKey('is_applied_pflegegrad').value = '717700007';
        }else{
            const { section } = this.state;
            section.getFieldByKey('is_pflegegrad').value = '';
            //section.getFieldByKey('is_applied_pflegegrad').value = '';
        }
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);
    }

    isPostalCodeBawaria=()=>{
        const { cities, form } = this.props;
        const isServiceAddressPostalCode = form.getFieldByKey('is_service_address_postal_code').value;
        if(cities.length>0){
            for (let index = 0; index < cities.length; index++) {
                if(cities[index].PostalCode.indexOf(isServiceAddressPostalCode) > -1){
                    if(cities[index].Bawaria===1){
                        return true;
                    }
                }
            }
            return false;
        }else{
            return false;
        }
    }

    shouldIShowKrankenkasse = () =>{
        const { section } = this.state;
        const isKeinPflegegrad = section.getFieldByKey('is_kein_pflegegrad');
        const isPflegegrad = section.getFieldByKey("is_pflegegrad");
        if(isPflegegrad.value!==''){
            return true
        }
        if(isKeinPflegegrad.value===true){
            return false
        }
        if(isKeinPflegegrad.value===0){
            return false;
        }
    }

    render() {
        const {
          disabled,
          showValidationErrors,
          translateKey,
          subsectionName,
          sectionNumber,
          form,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
        } = this.props;
        const { section } = this.state;
        const isAppliedPflegegradField = section.getFieldByKey(isAppliedPflegegrad);
        const isAppliedLast6MonthsField = section.getFieldByKey(isAppliedLast6Months);
        const isKeinPflegegrad = section.getFieldByKey('is_kein_pflegegrad');
        const isPflegegrad = section.getFieldByKey("is_pflegegrad");
        const isInsuranceType = section.getFieldByKey('is_insurance_type')
        if(isPflegegrad.value!==''){
            isKeinPflegegrad.value=false;
        }else{
            if(isAppliedPflegegradField.value==="717700007"){
                isKeinPflegegrad.value=true;
            }
        }
        const isBawaria = this.isPostalCodeBawaria();
        const isNewUI = true;
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled}>
                        <div className="newUI-section-padding">
                            <div className="row"> 
                                <ListPflegegradComponent 
                                    section={section} 
                                    newUI={isNewUI} 
                                    className="col-sm-12" 
                                    showValidation={showValidationErrors} 
                                    setTypeChangeToParent={this.setTypeChange} 
                                    clear={this.clearMeFields} 
                                    disabled={disabled}
                                    isBawaria={isBawaria}
                                    transKey="form_text_aktueller_pflegegrad"
                                />
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row">
                                <SwitchComponent
                                    setTypeChangeToParent={this.setTypeChange}
                                    showValidation={showValidationErrors}
                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                    transKey="form_text_beantragt_in_den_letzten"
                                    key="is_applied_last_6_months"
                                    keyName="is_applied_last_6_months"
                                    value={isAppliedLast6MonthsField.value}
                                    validationErrors={isAppliedLast6MonthsField.validationErrors}
                                    valueYes = '717700000'
                                    valueNo = '717700001'
                                    disabled={disabled}
                                />
                            </div>
                        </div>

                        {isAppliedLast6MonthsField.value=== '717700000' &&
                            <div className="newUI-section-padding">
                                <div className="row">
                                    <ListTileComponent
                                        newUI={isNewUI} 
                                        disabled={disabled}
                                        setTypeChangeToParent={this.setTypeChange}
                                        showValidation={showValidationErrors}
                                        className="col-sm-12 col-lg-12 form-margin-bottom-fix"
                                        transKey="form-text-beantragt"
                                        key={isAppliedPflegegrad}
                                        keyName={isAppliedPflegegrad}
                                        value={isAppliedPflegegradField.value}
                                        validationErrors={isAppliedPflegegradField.validationErrors}
                                        valuesList={[
                                            { value: '717700008', label: translateKey('form_text_pflegegrad_beantragt') },
                                            { value: '717700000', label: translateKey('from-text-pflegegrad1') },
                                            { value: '717700001', label: translateKey('from-text-pflegegrad2') },
                                            { value: '717700002', label: translateKey('from-text-pflegegrad3') },
                                            { value: '717700003', label: translateKey('from-text-pflegegrad4') },
                                            { value: '717700004', label: translateKey('from-text-pflegegrad5') }
                                        ]}
                                    />
                                </div>
                            </div>
                        }

                        {this.shouldIShowKrankenkasse() &&
                            <div className="newUI-section-padding">
                                <div className="row">
                                    <SwitchComponent
                                        setTypeChangeToParent={this.setTypeChange}
                                        showValidation={showValidationErrors}
                                        className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                        transKey="form-text-krankenkasse"
                                        key="is_insurance_type"
                                        keyName="is_insurance_type"
                                        value={isInsuranceType.value}
                                        validationErrors={isInsuranceType.validationErrors}
                                        valueYes = '717700000'
                                        valueNo = '717700001'
                                        labelYes = {translateKey("form-text-gesetzlich")}
                                        labelNo= {translateKey("form-text-privat")}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>    
                        }

                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cities: state.enums.citiesList,
        citiesStatus: state.enums.citiesListStatus,
    };
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section3));
import * as FieldTypes from "../fieldTypes";
import Form from "../Form";
import FormSection from "../FormSection";
import FormField from "../FormField";
import * as Sections from "./values/sections";
import * as IsContactPersonDataSameAs from "./values/isContactPersonDataSameAs";
import * as Entity from "./values/entity";
import * as Relationship from "./values/relationship";
import * as SkillRange from "./values/skillRange";
import { skipList } from "./values/formKeySkipList";
const LAST_SECTION_KEY = 'is_last_section';
const IS_MOST_IMPORTANT_TAKING_CARE_PATIENT = 'is_most_important_taking_care_patient'; 
const SKIP_SAVE_IS_DIET_DIABETES = 'skip_save_is_diet_diabetes'; 
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 
const IS_DIET_DIABETES  = "is_diet_diabetes";

/*
hidden due to FP-321
  const importantListForSection17 = [
    { key: "is_cooking", type: "BOOLEAN" },
    { key: "is_purchases", type: "BOOLEAN" },
    { key: "is_laundry", type: "BOOLEAN" },
    { key: "is_poted_flower_care", type: "BOOLEAN" },
    { key: "is_ironing", type: "BOOLEAN" },
    { key: "is_accompanying_patient_while_out", type: "BOOLEAN" },
    { key: "is_cleaning_the_house", type: "BOOLEAN" },
    { key: "is_company_during_medical_visits", type: "BOOLEAN" },
    { key: "is_common_trips", type: "BOOLEAN" },
    { key: "is_organization_of_free_time", type: "BOOLEAN" },
    { key: "is_driving", type: "BOOLEAN" },
    { key: "is_driving_automat_gearbox", type: "BOOLEAN" },
  ]
  const importantListForSection4 = [
    { key: "is_allergies_list", type: "BOOLEAN" },
    { key: "is_difficulty_in_walking_determined_by_age", type: "BOOLEAN" },
    { key*
    { key: "is_asthma_list", type: "BOOLEAN" },
    { key: "is_chronic_diarrhea_list", type: "BOOLEAN" },
    { key: "is_bedsores_list", type: "BOOLEAN" },
    { key: "is_initial_stages_of_dementia_list", type: "BOOLEAN" },
    { key: "is_dementia_list", type: "BOOLEAN" },
    { key: "is_atopic_dermatitis", type: "BOOLEAN" },
    { key: "is_diabetes", type: "BOOLEAN" },
    { key: "is_diabetes_mellitus_list", type: "BOOLEAN" },
    { key: "is_myocardial_infarction_list", type: "BOOLEAN" },
    { key: "is_heart_failure_list", type: "BOOLEAN" },
    { key: "is_arrhythmias_list", type: "BOOLEAN" },
    { key: "is_hypertension_list", type: "BOOLEAN" },
    { key: "is_incontinence_list", type: "BOOLEAN" },
    { key: "is_multiple_sclerosis_the_list", type: "BOOLEAN" },
    { key: "is_osteoporosis_list", type: "BOOLEAN" },
    { key: "is_parkinsons_disease_list", type: "BOOLEAN" },
    { key: "is_rheumatic_disease_list", type: "BOOLEAN" },
    { key: "is_left_sided_paresis_list", type: "BOOLEAN" },
    { key: "is_right_sided_paresis_list", type: "BOOLEAN" },
    { key: "is_stoma_list", type: "BOOLEAN" },
    { key: "is_cancer_list", type: "BOOLEAN" },
    { key: "is_other_description_de", type: "STRING" }
  ]
*/
const skipListPatient = [
  "is_patient_last_name",
  "is_patient_first_name",
  "is_service_address_street",
  "is_service_address_street_number",
  "is_service_address_house_number",
  "is_service_address_postal_code",
  "is_service_address_city",
  "is_patient_cellphone",
  "is_patient_phone"
];
const flattenFields = fieldsList =>
  fieldsList.reduce(
    (previous, field) =>
      Object.assign(previous, {
        [field.key]: field.value
      }),
    {}
  );
//const flattenFieldsArray = fieldsList => fieldsList.map(field => field.key);
const flattenFieldsArraywithSkiped = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipList.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key)
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })
   
const flattenFieldsArraySkipedBoth = fieldsList =>
  fieldsList
    .filter(element => {
      if (skipListPatient.indexOf(element.key) !== -1 || skipList.indexOf(element.key) !== -1) {
        return false;
      }
      return true;
    })
    .map(field => field.key)
    .filter((item, pos, self) => {
      return self.indexOf(item) === pos;
    })

const birthdayField = new FormField({
  defaultValue: "",
  key: "is_birthday",
  entity: Entity.PATIENT,
  isRequired: false
});
const weightField = new FormField({
  defaultValue: "",
  key: "is_weight",
  entity: Entity.PATIENT,
  isRequired: false
});
const heightField = new FormField({
  defaultValue: "",
  key: "is_height",
  entity: Entity.PATIENT,
  isRequired: false
});
const homeDoctorField = new FormField({
  defaultValue: "",
  key: "is_home_doctor",
  entity: Entity.PATIENT,
  isRequired: false
});

export default class BBForm extends Form {
  constructor() {
    super({
      sections: [
        new FormSection({
          key: "section-rodo-init",
          sectionName: "form-rodo1-text-akceptieren",
          number: 1,
          fields: [
            new FormField({
              key: "rodo_first_choice",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_degree_relationship_with_patient_carer",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              defaultValue: false,
              key: "franchise-partner",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false
            }),
          ],
          nextSectionSelector: form => {
            return Sections.RODO1;
          },
        }
        ),
        new FormSection({
          key: "section-rodo-consents",
          sectionName: "form-rodo1-text-akceptieren",
          number: 2,
          fields: [
            new FormField({
              //ip_address
              defaultValue: false,
              key: "is_customer_area_ip_address",
              entity: Entity.CONTACT,
            }),
            new FormField({
              key: "is_patient_last_name",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_patient_first_name",
              entity: Entity.CONTACT
            }),
            new FormField({
              //rodo1
              defaultValue: false,
              key: "einwilligung_zur_unterbeitung",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              //rodo2
              defaultValue: null,
              key: "einwilligung_in_die_verarbeitung",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              //rodo2
              defaultValue: null,
              key: "die_verwendent_werden",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "marketing_einwilligungen_post_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "marketing_einwilligungen_post_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "marketing_einwilligungen_telephone_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "marketing_einwilligungen_telephone_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_email_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_email_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_telephone_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_telephone_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_post_yes",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: false,
              key: "einwilligungen_die_daten_post_no",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
            }),
            new FormField({
              defaultValue: null,
              key: "marketing_zustimmungen",
              entity: Entity.CONTACT,
              isFalseable: true,
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_1",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_2",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_3",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_4",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_5",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_6",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_7",
              entity: Entity.CONTACT,
              isFalseable: false
            }),
            new FormField({
              defaultValue: null,
              key: "is_zg_de_8",
              entity: Entity.CONTACT,
              isFalseable: false
            })
          ],
          nextSectionSelector: form => {
            return Sections.CLIENT;
          },
        }),
        // Section 1
        new FormSection({
          key: "section-0",
          sectionName: "bb_client_data_p_0",
          number: 3,
          fields: [
            new FormField({
              key: "lastname",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "firstname",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_degree_relationship_with_patient_carer",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_registered_street",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_registered_street_number",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_registered_zip_postal_code",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_registered_house_number",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_mobile_phone",
              entity: Entity.CONTACT,
              isRequired: false,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_home_phone",
              entity: Entity.CONTACT,
              isRequired: false,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_registered_city",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_email",
              entity: Entity.CONTACT,
              isRequired: false,
              validationRules: {
                fieldType: FieldTypes.EMAIL
              }
            }),
            new FormField({
              key: "is_address_type",
              entity: Entity.CONTACT,
              defaultValue: " 717700000"
            }),
            new FormField({
              key: "is_correspondence_name",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isLastName = form.getFieldByKey("lastname");
                const isFirstName = form.getFieldByKey("firstname");
                return isLastName.value + isFirstName.value;
              }
            }),
            new FormField({
              key: "is_correspondence_city",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_city");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_zip_postal_code",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_zip_postal_code");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_house_number",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_house_number");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_street",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_street");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_correspondence_street_number",
              entity: Entity.CONTACT,
              valueMapper: form => {
                const isRegisteredCity = form.getFieldByKey("is_registered_street_number");
                return isRegisteredCity.value;
              }
            }),
            new FormField({
              key: "is_contact_person_data_same_as",
              entity: Entity.CONTACT,
              isRequired: false,
              validationRules: {
                possibleValues: IsContactPersonDataSameAs.LIST
              },
              defaultValue: IsContactPersonDataSameAs.YES
            }),
            birthdayField,
            weightField,
            heightField,
            homeDoctorField
          ],
          nextSectionSelector: form => {
            const isContactPersonDataSameAs = form.getFieldByKey("is_contact_person_data_same_as");
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
              if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
                return Sections.PFLEGEGRAD;
              } else {
                return Sections.PATIENT;
              }
            } else {
              return Sections.CONTACT_PERSON;
            }
          },
          prevSectionSelector: form => {
            return Sections.CLIENT;
          },
          validationExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationshipWithPatientCarer.value !== Relationship.TYPE_8) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          },
          payloadExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationshipWithPatientCarer.value !== Relationship.TYPE_8) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          }
        }),

        // Section 2
        new FormSection({
          key: "section-1",
          sectionName: "bb_contact_data_p_1",
          number: 4,
          fields: [
            new FormField({
              key: "is_contact_person_lastname",
              entity: Entity.CONTACT,
              isRequired: false,
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("lastname").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_firstname",
              entity: Entity.CONTACT,
              isRequired: false,
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("firstname").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_mobile_phone",
              entity: Entity.CONTACT,
              isRequired: false,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              },
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("is_mobile_phone").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_home_phone",
              entity: Entity.CONTACT,
              isRequired: false,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              },
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("is_home_phone").value;
                }
              }
            }),
            new FormField({
              key: "is_contact_person_email",
              entity: Entity.CONTACT,
              isRequired: false,
              validationRules: {
                fieldType: FieldTypes.EMAIL
              },
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey(
                  "is_contact_person_data_same_as"
                );
                if (
                  isContactPersonDataSameAs.value ===
                  IsContactPersonDataSameAs.YES
                ) {
                  return form.getFieldByKey("is_email").value;
                }
              }
            }),
            new FormField({
              key: "is_relationship_with_patient_op",
              entity: Entity.CONTACT,
              isRequired: false,
              valueMapper: form => {
                const isContactPersonDataSameAs = form.getFieldByKey("is_contact_person_data_same_as");
                if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
                  return form.getFieldByKey("is_degree_relationship_with_patient_carer").value;
                }
              }
            })
          ],
          nextSectionSelector: form => {
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
              return Sections.PFLEGEGRAD;
            } else {
              return Sections.PATIENT;
            }
          },
        }),

        // Section 3
        new FormSection({
          key: "section-2",
          sectionName: "bb_lead_data_p_2",
          number: 5,
          fields: [
            birthdayField,
            weightField,
            heightField,
            homeDoctorField,
            new FormField({
              key: "is_patient_last_name",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_patient_first_name",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_service_address_street",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_service_address_street_number",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_service_address_house_number",
              entity: Entity.CONTACT
            }),
            new FormField({
              key: "is_service_address_postal_code",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_service_address_city",
              entity: Entity.CONTACT,
              isRequired: false
            }),
            new FormField({
              key: "is_first_name_and_last_name_of_patient",
              entity: Entity.PATIENT,
              // valueMapper: form => {
              //   const isPatientLastName = form.getFieldByKey("is_patient_last_name");
              //   const isPatientFirstName = form.getFieldByKey("is_patient_first_name");
              //   return isPatientFirstName.value + " " + isPatientLastName.value;
              // }
            }),
            new FormField({
              key: "is_patient_cellphone",
              entity: Entity.CONTACT,
              isRequired: false,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
            new FormField({
              key: "is_patient_phone",
              entity: Entity.CONTACT,
              isRequired: false,
              validationRules: {
                fieldType: FieldTypes.PHONE_NUMBER
              }
            }),
          ],
          prevSectionSelector: form => {
            const isContactPersonDataSameAs = form.getFieldByKey("is_contact_person_data_same_as");
            if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
              return Sections.CLIENT;
            }
            return Sections.CONTACT_PERSON;
          },
          validationExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          },
          payloadExceptions: form => {
            const isDegreeRelationshipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (
              isDegreeRelationshipWithPatientCarer.value === Relationship.TYPE_8
            ) {
              return [
                "is_birthday",
                "is_weight",
                "is_height",
                "is_home_doctor"
              ];
            }
          }
        }),
 
        // Section 4
        new FormSection({
          key: "section-3",
          sectionName: "bb_pflegegrad_data_p_3",
          number: 6,
          fields: [
            new FormField({
              key: "is_pflegegrad",
              entity: Entity.PATIENT,
              isRequired: false
            }),
            new FormField({
              key: "is_applied_pflegegrad",
              entity: Entity.PATIENT,
              isRequired: false,
            }),
            new FormField({
              key: "is_applied_last_6_months",
              entity: Entity.PATIENT,
              isRequired: false,
              defaultValue: '717700001'
            }),
            new FormField({
              key: "is_insurance_type",
              entity: Entity.PATIENT,
              isRequired: false,
              defaultValue: '717700001'
            }),
            new FormField({
              key: "is_kein_pflegegrad",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false
              /*valueMapper: (form) => {
                  const isPflegegrad = form.getFieldByKey('is_pflegegrad');
                  const isAppliedPflegegrad = form.getFieldByKey('is_applied_pflegegrad');
                  if(
                      (isPflegegrad.value!==null && isPflegegrad.value.toString().trim() !=='') ||
                      (isAppliedPflegegrad.value!==null && isAppliedPflegegrad.value.toString().trim() !=='') ||
                      (isKrankenkasse.value!==null && isKrankenkasse.value.toString().trim() !=='')
                  ){
                      return false
                  }else{
                      return true
                  }
              }*/
            })
          ],
          prevSectionSelector: form => {
            const isContactPersonDataSameAs = form.getFieldByKey("is_contact_person_data_same_as");
            const isDegreeRelationShipWithPatientCarer = form.getFieldByKey("is_degree_relationship_with_patient_carer");
            if (isDegreeRelationShipWithPatientCarer.value !== Relationship.TYPE_8 ) {
                return Sections.PATIENT;
            } else {
              if (isContactPersonDataSameAs.value !== IsContactPersonDataSameAs.YES){
                return Sections.CONTACT_PERSON;
              }
            }
            return Sections.CLIENT;
          },
          payloadExceptions: () => {
            return ["is_kein_pflegegrad"];
          }
        }),

        // Section 5   //    2.1
        new FormSection({
          key: "section-4",
          sectionName: "bb_ills_data_p_4",
          number: 7,
          fields: [
            new FormField({
              key: "is_patient_smokes",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              defaultValue: false
            }),
            new FormField({
              key: "is_allergies_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: null
            }),
            new FormField({
              key: "is_difficulty_in_walking_determined_by_age",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_parkinsons_disease_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_stoma_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_cancer_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_osteoporosis_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_incontinence_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_bedsores_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_hypertension_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_other_description_de",
              entity: Entity.PATIENT,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
            }),
            new FormField({
              key: "is_asthma_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_chronic_diarrhea_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_dementia_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_alzheimers_disease_the_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_diabetes",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_diabetes_mellitus_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_atopic_dermatitis",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_heart_failure_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_initial_stages_of_dementia_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_rheumatic_disease_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_multiple_sclerosis_the_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_left_sided_paresis_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_right_sided_paresis_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_myocardial_infarction_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            }),
            new FormField({
              key: "is_arrhythmias_list",
              entity: Entity.PATIENT,
              isFalseable: true,
              isRequired: false,
              //fieldImportantList: importantListForSection4,
              defaultValue: false
            })
          ]
        }),

        // Section 6 // 2.2
        new FormSection({
          key: "section-5",
          sectionName: "bb_comnunication_data_p_5",
          number: 8,
          fields: [
            new FormField({
              key: "is_communication_hearing",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_communication_vision",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_communication_speech",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // Section 7 2.3
        new FormSection({
          key: "section-6",
          sectionName: "bb_orientation_data_p_6",
          number: 9,
          fields: [
            new FormField({
              key: "is_orientation_temporal",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_orientation_locational",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_orientation_personal",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // SECTION NUMBER 8
        new FormSection({
          key: "section-8", 
          sectionName: "bb_movement_data_p_8",
          number: 10,
          fields: [
            new FormField({
              key: "is_bed_care",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_palliative_care",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_patient_need_help_with_transfer",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_rollator_or_walker_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_lying_patient",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_regular_change_of_position",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_walking_stick",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_specialist_bed_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_chair_lift",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_transfer_lifter",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_wheelchair",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_getting_up",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_patient_is_active_during_transfer",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_carrying_bed_wheelchair",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_move",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            }),
            new FormField({
              key: "is_antibedsore_mattress_list",
              entity: Entity.PATIENT,
              isFalseable: true
            }),
            new FormField({
              key: "is_walking_up_stairs",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: { possibleValues: SkillRange.LIST }
            }),
            new FormField({
              key: "is_moving_with_rollator_alone",
              entity: Entity.PATIENT,
              isRequired: false,
              defaultValue: ''
            }),
            new FormField({
              key: "is_moving_with_rollator_in_flat_alone",
              entity: Entity.PATIENT,
              isRequired: false,
              defaultValue: ''
            }),
            new FormField({
              key: "is_moving_rollator_outside_flat",
              entity: Entity.PATIENT,
              isRequired: false,
              defaultValue: ''
            }),
            new FormField({
              key: "is_moving_on_wheelchair_alone",
              entity: Entity.PATIENT,
              isRequired: false, 
              defaultValue: ''
            }),
            new FormField({
              key: "is_moving_wheelchair_in_apartment_alone",
              entity: Entity.PATIENT,
              isRequired: false,
              defaultValue: '',
            }),
            new FormField({
              key: "is_moving_wheelchair_outside_alone",
              entity: Entity.PATIENT,
              isRequired: false,
              defaultValue: ''
            }),
            new FormField({
              key: "is_patient_help_change_position",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false
            })
          ]
        }),

        // SECTION NUMBER 9
        new FormSection({
          key: "section-pflegedienst-simple", 
          sectionName: "bb_pflegedienst_data_p_14",
          number: 11,
          fields: [
            new FormField({
              key: "is_outpatient_help",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false,
              //hidden due to FP-321 fieldImportantList: [{ key: "is_outpatient_help", type: "BOOLEAN", basedOnField: 'is_contract_pflegedienst_services_signed', skipCondition: true }]
            }),
            new FormField({
              key: "is_contract_pflegedienst_services_signed",
              entity: Entity.PATIENT,
              isFalseable: true,
              defaultValue: false,
              //hidden due to FP-321 fieldImportantList: [{ key: "is_contract_pflegedienst_services_signed", type: "BOOLEAN", basedOnField: 'is_outpatient_help', skipCondition: true }]
            })
          ]
        }),

        // SECTION NUMBER 10
        new FormSection({
          key: "section-16",
          sectionName: "bb_expectations_data_p_16",
          number: 12,
          fields: [
            new FormField({
              key: "is_most_important_taking_care_patient",
              entity: Entity.PATIENT,
              isRequired: false
            }),
            new FormField({
              key: "is_start_date_contact",
              entity: Entity.CONTACT,
              isRequired: false,
            }),
            new FormField({
              key: "is_end_date_contact",
              entity: Entity.CONTACT,
              isRequired: false,
            }),
            new FormField({
              key: "is_permanent_contract_contact",
              entity: Entity.CONTACT,
              isFalseable: true,
              isRequired: false,
              validationRules: { possibleValues: ['717700000', '717700001'] },
              defaultValue: "717700001"              
            }),
            new FormField({
              key: "is_candidate_gender",
              entity: Entity.PATIENT,
              isRequired: false,
              validationRules: {
                possibleValues: SkillRange.LIST
              }
            })
          ]
        }),

        // SECTION NUMBER 11
        new FormSection({
          key: "section-17",
          sectionName: "bb_actions_data_p_17",
          number: 13,
          fields: [
            new FormField({
              key: "is_cooking",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_purchases",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_laundry",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_poted_flower_care",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_ironing",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_accompanying_patient_while_out",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_cleaning_the_house",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_company_during_medical_visits",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_common_trips",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_organization_of_free_time",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_driving",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            }),
            new FormField({
              key: "is_driving_automat_gearbox",
              entity: Entity.PATIENT,
              isFalseable: true,
              //fieldImportantList: importantListForSection17
            })
          ]
        }),

        // SECTION NUMBER 12
        new FormSection({
          key: "section-beschreibung",
          sectionName: "bb_place_data_p_18",
          number: 14,
          fields: [
            new FormField({
              key: "is_patient_lives",
              entity: Entity.PATIENT,
              isRequired: false
            }),
            new FormField({
              key: SKIP_SAVE_IS_DIET_DIABETES,
              entity: Entity.PATIENT,
              isRequired: false,
            }),
            new FormField({
              key: SKIP_SAVE_STRING_TILES,
              entity: Entity.PATIENT,
              isRequired: false,
            }),
            new FormField({
              key: IS_DIET_DIABETES,
              entity: Entity.PATIENT,
              isFalseable: true
            }),
          ]
        }),

        // SECTION INITIAL OFFERT  NO NUMBER
        /*
          hidden due to FP-331
          new FormSection({
            key: "section-initial-offer",
            sectionName: "section-initial-offer",
            number: 14,
            isSpecialOffer: true,
            fields: [
              new FormField({
                key: "is_most_important_taking_care_patient",
                entity: Entity.PATIENT,
                isRequired: false,
              })
            ]
          }),
        */

        // SECTION INITIAL OFFERT  NO NUMBER
        new FormSection({
          key: "zone-text-ende-der-fragen",
          sectionName: "zone-text-ende-der-fragen",
          number: 15,
          isSpecialOffer: false,
          fields: []
        }),
        new FormSection({
          key: "section-hidden",
          fields: [
            new FormField({
              key: "is_contact_source",
              entity: Entity.CONTACT,
              defaultValue: "717700001"
            }),
            new FormField({
              key: "is_last_section",
              entity: Entity.PATIENT,
              defaultValue: "rodo_section_1"
            })
          ]
        })
      ]
    });
  }

  get payload() {
    const patientFields = this.getFieldsByEntity(Entity.PATIENT);
    const contactFields = this.getFieldsByEntity(Entity.CONTACT);

    return {
      patient: flattenFields(patientFields),
      contact: flattenFields(contactFields)
    };
  }

  getRetiveDataALl(lkId, patietList) {
    let retrieveData = [];
    let patietListWithOutZero = patietList.filter(element => element !== 0);
    if (patietList.length !== "0") {
      const patientFields = this.getFieldsByEntity(Entity.PATIENT);
      const flattenFields = flattenFieldsArraywithSkiped(patientFields);
      patietListWithOutZero.forEach(element => {
        retrieveData.push({
          id: element,
          entity: Entity.PATIENT,
          fields: flattenFields
        });
      });
    }
    if (lkId !== "0") {
      const contactFields = this.getFieldsByEntity(Entity.CONTACT);
      if (patietList.length !== "0") {
        if (patietListWithOutZero.length > 0) {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraywithSkiped(contactFields)
          });
        } else {
          retrieveData.push({
            id: lkId,
            entity: Entity.CONTACT,
            fields: flattenFieldsArraySkipedBoth(contactFields)
          });
        }
      } else {
        retrieveData.push({
          id: lkId,
          entity: Entity.CONTACT,
          fields: flattenFieldsArraySkipedBoth(contactFields)
        });
      }
    }
    return retrieveData;
  }

  getPayloadForSection(sectionKey, lkId, patientId) {
    const patientFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.PATIENT,
      true
    );
    const contactFields = this.getFieldsBySectionAndEntity(
      sectionKey,
      Entity.CONTACT,
      true
    );
    let patientDataToSave = flattenFields(patientFields);
    if (patientId === "0") {
      if (Object.keys(patientDataToSave).length > 0) {
        patientDataToSave["is_contact_id"] = lkId;
      }
    }
    let returnArray = [];
    if (patientId === "0" || patientId === 0) {
      patientDataToSave["is_contact_id"] = lkId;
    }
    let field = this.getFieldByKey(LAST_SECTION_KEY);
    if(field){
      patientDataToSave[LAST_SECTION_KEY] = field.value
    }

    const shouldSaveTilesString = this.getFieldByKey(SKIP_SAVE_STRING_TILES);
    if(shouldSaveTilesString.value){
      let isTakingCarePatientField = this.getFieldByKey(IS_MOST_IMPORTANT_TAKING_CARE_PATIENT);
      if(isTakingCarePatientField){
        patientDataToSave[IS_MOST_IMPORTANT_TAKING_CARE_PATIENT] = isTakingCarePatientField.value
      }
    }

    const shouldSaveDietDiabetisField = this.getFieldByKey(SKIP_SAVE_IS_DIET_DIABETES);
    if(shouldSaveDietDiabetisField.value){
      let isDietDiabetes = this.getFieldByKey(IS_DIET_DIABETES);
      if(isDietDiabetes){
        patientDataToSave[IS_DIET_DIABETES] = isDietDiabetes.value
      }
    }

    returnArray.push({
      Id: patientId,
      Entity: Entity.PATIENT,
      Fields: patientDataToSave
    });
    const contactFieldsToSave = flattenFields(contactFields);
    if (Object.keys(contactFieldsToSave).length > 0) {
      returnArray.push({
        Id: lkId,
        Entity: Entity.CONTACT,
        Fields: contactFieldsToSave
      });
    }
    return returnArray;
  }
}

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import { TableDateFormat } from "../index";
import DatePickerComponent from "../../_components/date-picker-component/DatePickerComponent";
import Select from 'react-select';
import * as ConcernsType from "../../_constance/enums/concernsType";
import { ConfirmModal } from "../../_components/index";
import { baseData } from '../../_constance/base_data';

class TaskDetailsBasicComponent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            edited: false,
            disabled: this.props.element.status,
            selectedOption: null,
            confirmCancellation: false
        };
    }

    componentDidMount() {
      this._isMounted = true;
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    handleChangeTitle(event){
      this.props.element.title = event;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                  ...prevState.element,
                  title: event
              }
          }
      });
      this.props.editedData();
    }

    handleChangePriority(event){
      this.props.element.priority = event;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                  ...prevState.element,
                  priority: event
              }
          }
      });
      this.props.editedData();
    }

    handleChangeStatus(event){
      this.props.element.status = event;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                  ...prevState.element,
                  status: event
              }
          }
      });
      this.props.editedData();
    }

    handleChangeDescription(event){
      this.props.element.description = event;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                  ...prevState.element,
                  description: event
              }
          }
      });
      this.props.editedData();
    }

    handleChangeConcerns = event => {
      this.props.element.concernType = event.type;
      this.props.element.concernName = event.label;
      this.props.element.concerns = event.value;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                  ...prevState.element,
                  concerns: event.value,
                  concernType: event.type,
                  concernName: event.label
              }
          }
      });
      this.props.editedData();
    }

    handleChangeDirection(event){
      this.props.element.direction = event;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                  ...prevState.element,
                  direction: event
              }
          }
      });
      this.props.editedData();
    }

    handleDueDayChange(event){
      let timestamp = new Date(event).getTime();
      timestamp = Math.floor(timestamp / 1000);
      this.props.element.dueDay = timestamp;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                  ...prevState.element,
                  dueDay: event
              }
          }
      });
      this.props.editedData();
    }

    handleStartDayChange(event){
      let timestamp = new Date(event).getTime();
      timestamp = Math.floor(timestamp / 1000);
      this.props.element.StartTime = timestamp;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                  ...prevState.element,
                  StartTime: event
              }
          }
      });
      this.props.editedData();
    }

    handleFinishDayChange(event){
      let timestamp = new Date(event).getTime();
      timestamp = Math.floor(timestamp / 1000);
      this.props.element.FinishTime = timestamp;
      this.setState(prevState => {
          return {
              ...prevState,
              element: {
                  ...prevState.element,
                  FinishTime: event
              }
          }
      });
      this.props.editedData();
    }
    
    createPrioritySelect(){
        return (
            <select disabled={this.props.element.status==="Completed" || this.props.element.status==="Closed"} className="custom-select col-sm-10 lead-input-wrapper" onChange={(e)=>this.handleChangePriority(e.target.value)} value={this.props.element.priority}>
              <option value='' hidden>{this.props.translateKey('choose_select')}</option>
              {this.props.taskPriority.map((value, index) => {
                if(value.Value=== this.props.element.priority){
                  return <option key={value.Label} value={value.Value}>{value.Label}</option>
                }else{
                  return <option key={value.Label} value={value.Value}>{value.Label}</option>
                }
              })}
            </select>
        )
    }

    prepareMeClientsOption(){
      return (
        this.props.clientsData.map((value, index) => {
          let fullname = ''
          if(value.lastName){
            fullname += value.lastName;
            if(value.firstName){
              fullname += ' '+value.firstName;
            }
          }
          if(fullname){
            return { value: value.id, label: fullname, type:ConcernsType.CLIENT }
          }else{
            return null;
          }
        }).filter(x => x)
      )
    }

    prepareMeInvoiceOption(){
      return (
        this.props.invoicesData.map((value, index) => {
          return { value: value.Id, label: value.invoiceNumber, type:ConcernsType.INVOICE }
        })
      )
    }

    prepareMeLeadOption(){
      return (
          this.props.leadsData.map((value, index) => {
            let fullname = ''
            if(value.client){
              if(value.client.fullname){
                fullname +=value.client.fullname;
              }else{
                if(value.client.lastName){
                  fullname +=value.client.lastName;
                  if(value.client.firstName){
                    fullname += ' '+value.client.firstName;
                  }
                }else{
                  if(value.client.firstName){
                    fullname += ' '+value.client.firstName;
                  }
                }
              }
            }
            if(fullname){
              return { value: value.leadId, label: fullname, type:ConcernsType.LEAD }
            }else{
              return null;
            }
          }).filter(x => x)
      )
    }

    prepareMeMultipliersOption(){
      return (
          this.props.multipliersData.map((value, index) => {
            let fullname = ''
            if(value.lastName){
              fullname += value.lastName;
              if(value.firstName){
                fullname += ' '+value.firstName;
              }

            }
            if(fullname){
              return { value: value.id, label: fullname, type:ConcernsType.MULTIPLIER }
            }else{
              return null;
            }
          }).filter(x => x)
      )
    }

    prepareMeSelectConcernsGroup = () =>{
      const finalGroup = [];
      if(this.props.clientsData.length>0){
        finalGroup.push({label: this.props.translateKey("clients"), options: this.prepareMeClientsOption()})
      }
      if(this.props.invoicesData.length>0){
        finalGroup.push({label: this.props.translateKey("invoices"), options: this.prepareMeInvoiceOption()})
      }
      if(this.props.leadsData.length>0){
        finalGroup.push({label: this.props.translateKey("leads"), options: this.prepareMeLeadOption()})
      }
      if(this.props.multipliersData.length>0){
        finalGroup.push({label: this.props.translateKey("multipliers"), options: this.prepareMeMultipliersOption()})
      }
      return finalGroup;
    }

    getSelectedValueInConcerns = (selected, data)=>{
      if(!selected){
        const { match: { params } } = this.props;
        this.props.element.concerns = params.assignTo;
        selected = params.assignTo;
      }
      let selectedObj=[];
      for(let i=0; i<data.length; i++){
        selectedObj = data[i].options.filter(item => {
          if(item.value===selected){
            return true
          }else{
            return false;
          }
        });
        if(selectedObj.length>0){
          break;
        }
      }
      if(selectedObj.length>0){
        this.props.element.concernType = selectedObj[0].type;
        this.props.element.concernName = selectedObj[0].label;
        return selectedObj[0];
      }else{
        return { value: this.props.element.concerns, label: this.props.element.concernName, type:this.props.element.concernType }
      }
    }

    goToConcern=(concern)=>{
      const { history } = this.props;
      if(concern){
        switch(concern.type){
          case ConcernsType.CLIENT: history.push(`${baseData.subFolders}clientDetails/${concern.value}`);break;
          case ConcernsType.INVOICE: history.push(`${baseData.subFolders}invoices/${concern.value}`);break;
          case ConcernsType.LEAD: history.push(`${baseData.subFolders}leadDetails/${concern.value}`);break;
          case ConcernsType.MULTIPLIER: history.push(`${baseData.subFolders}multiplierDetails/${concern.value}`);break;
          default: 
        }
      }
    }

    createDirectionSelect(){
      return (
        <select className="custom-select col-sm-10 lead-input-wrapper" onChange={(e)=>this.handleChangeDirection(e.target.value)} value={this.props.element.direction}>
          <option value='' hidden>{this.props.translateKey('choose_select')}</option>
          {this.props.callEnums.map((value, index) => {
            if(this.props.element.direction!==null){
              if(value.Value === this.props.element.direction.toString()){
                return <option key={Math.random().toString(36).substr(2, 9)} value={value.Value}>{value.Label}</option>
              }else{
                return <option key={Math.random().toString(36).substr(2, 9)} value={value.Value}>{value.Label}</option>
              }
            }else{
              return <option key={Math.random().toString(36).substr(2, 9)} value={value.Value}>{value.Label}</option>
            }
          })}
        </select>
      )
    }

    toggleCancelation = () =>{
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              confirmCancellation: !prevState.confirmCancellation
          }
        });
      };
    }

    confirmCancellationYes = () =>{
      this.props.markAsCancelled();
      this.toggleCancelation();
    }

    render() {
      const {translateKey, element, callEnums, newOne} = this.props;
      const {confirmCancellation} = this.state;
      const preparedSelectGroup = this.prepareMeSelectConcernsGroup();
      const selectedConcerntInGroup = this.getSelectedValueInConcerns(element.concerns, preparedSelectGroup);
      return (
          <div className="lead-first-row" key="taskDetailsBasisComp01">
            <ConfirmModal rejectMe={this.toggleCancelation} acceptMe={this.confirmCancellationYes} show={confirmCancellation} titleMessage="" mainMessage={translateKey("confirm_cancel_task_status")} messageYes={translateKey("yes")} messageNo={translateKey("no")}/>
            <div className="col-sm-12 col-lg-6">
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("title")}</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control lead-input-wrapper" disabled={element.status==="Completed" || element.status==="Closed"} onChange={e => this.handleChangeTitle(e.target.value)} value={element.title?element.title:''}/>
                </div>
              </div>
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("concerns")}</label>
                <div className="col-sm-8 concern-select-wrapper">
                  <Select
                      className="lead-input-wrapper"
                      value={selectedConcerntInGroup}
                      onChange={this.handleChangeConcerns}
                      options={preparedSelectGroup}
                      isDisabled={element.status==="Completed" || element.status==="Closed"}
                  />
                  {selectedConcerntInGroup.value &&
                    <div className="go-to-concern-class" onClick={()=>this.goToConcern(selectedConcerntInGroup)}>
                      <i className="fas fa-angle-double-right"/>
                    </div>
                  }
                </div>
              </div>
              {!newOne &&
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("created")}</label>
                  <div className="col-sm-8 lead-input-wrapper">
                    <TableDateFormat formClass={true} timestamp={element.createdDate}/>
                  </div>
                </div>
              }
              {newOne?
                element.taskType !== 'Event'?
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("due_day")}</label>
                    <div className="col-sm-8 lead-input-wrapper">
                      <DatePickerComponent disabled={element.status==="Completed" || element.status==="Closed"} key="due_date_01" timestamp={element.dueDay} withTime={true} onDateChange={(e)=>this.handleDueDayChange(e)}/>
                    </div>
                  </div>
                  :
                  ''
                :
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("due_day")}</label>
                  <div className="col-sm-8 lead-input-wrapper">
                    <DatePickerComponent disabled={element.status==="Completed" || element.status==="Closed"} key="due_date_01" timestamp={element.dueDay} withTime={true} onDateChange={(e)=>this.handleDueDayChange(e)}/>
                  </div>
                </div>
              }
              {element.taskType === 'Call' &&  callEnums.length>0 && 
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("direction")}</label>
                    <div className="col-sm-8">
                      {this.createDirectionSelect()}
                    </div>
                  </div>
              }
              {!newOne &&
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("status")}</label>
                  <div className="col-sm-8">
                    <input type="text" disabled className="form-control lead-input-wrapper" value={element.status?element.status:''}/>
                  </div>
                </div>
              }
              {this.props.taskPriority.length>0 &&
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("priority")}</label>
                  <div className="col-sm-8">
                    {this.createPrioritySelect()}
                  </div>
                </div>
              }
              <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("notes")}</label>
                <div className="col-sm-8">
                  <textarea disabled={element.status==="Completed" || element.status==="Closed"} type="text" className="form-control lead-input-wrapper" rows="3" onChange={e => this.handleChangeDescription(e.target.value)} value={element.description?element.description:''}/>
                </div>
              </div>
              {element.taskType === 'Event' &&
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("start_time")}</label>
                  <div className="col-sm-8 lead-input-wrapper">
                    <DatePickerComponent disabled={element.status==="Completed" || element.status==="Closed"} key="start_time_01" timestamp={element.StartTime} withTime={true} onDateChange={(e)=>this.handleStartDayChange(e)}/>
                  </div>
                </div>
              }
              {element.taskType === 'Event' &&
                <div className="form-group row flexAlignCenter no-margin-row">
                  <label className="col-sm-4 col-form-label form-label-basic">{translateKey("finish_time")}</label>
                  <div className="col-sm-8 lead-input-wrapper">
                    <DatePickerComponent disabled={element.status==="Completed" || element.status==="Closed"} key="finish_time_01" timestamp={element.FinishTime} withTime={true} onDateChange={(e)=>this.handleFinishDayChange(e)}/>
                  </div>
                </div>
              }
            </div>
            {!newOne && (element.status==="Open" || element.status==="Scheduled") &&
              <div className="col-sm-12 col-lg-6 mark-as-completed-wrapper">
                <button className="btn btn-default btn-mark-as-cancelled-button" onClick={this.toggleCancelation}>
                    <i className="fas fa-times fa-2x"></i>
                </button>
                <button className="btn btn-default btn-mark-as-completed-button" onClick={()=>this.props.markAsComplete()}>
                    <i className="fas fa-check fa-2x"></i>
                </button>
              </div>
            }
        </div>
      );
    }
}

const mapStateToProps = state => {
  return {
      taskPriority: state.enums.taskPriority,
      leadsData: state.database.leads,
      multipliersData: state.database.multipliers,
      invoicesData: state.database.invoices,
      clientsData: state.database.clients,
      callEnums: state.enums.callDirection
  };
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskDetailsBasicComponent));
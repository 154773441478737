import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import MultiplePhonesComponent from '../../multiple-phones-component/MultiplePhonesComponent';
import WrappedDatePickerComponent from "../../form-bb-components/wrapped-date-picker-component/wrapped-date-picker-component";
import ReqHelper from '../../../_helpers/request_helper';
import * as Relationship from '../../../_forms/BBForm/values/relationship';
import WrappedAutoSuggestComponent from "../../../_components/wrapped-autosuggest-component/WrappedAutoSuggestComponent";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import produce from "immer";

class Section2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-2'),
            showValidation: props.showValidationErrors
        };
    }

    componentDidMount() {
        const { match: { params }, form } = this.props;
        const { section } = this.state;
        const isBirthday = section.getFieldByKey('is_birthday');
        const isHeight = section.getFieldByKey('is_height');
        const isWeight = section.getFieldByKey('is_weight');
        const isHomeDoctor = section.getFieldByKey('is_home_doctor');
        if(params.patientId==="0"){
            if(isBirthday.value !== null){isBirthday.value = null;}
            if(isHeight.value !== null){isHeight.value = null;}
            if(isWeight.value !== null){isWeight.value = null;}
            if(isHomeDoctor.value !== null){isHomeDoctor.value = null;}
        }
        const nameFromSection = section.getFieldByKey('is_patient_first_name').value;
        const surnameFromSection = section.getFieldByKey('is_patient_last_name').value;
        const firstNameAndLastName = form.getFieldByKey('is_first_name_and_last_name_of_patient').value.split(' ');
        const nameFromMergedField = firstNameAndLastName[0];
        const surnameFromMergedField = firstNameAndLastName[1];

        if(nameFromSection !== nameFromMergedField || surnameFromSection !== surnameFromMergedField) {
          this.setTypeChange('is_patient_first_name', nameFromMergedField);
          this.setTypeChange('is_patient_last_name', surnameFromMergedField);
        }
      }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }

    setTypeChangePostalCode = (key, suggestion) => {
        if(typeof suggestion === 'object' && suggestion !== null){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = suggestion.value;
                }
                const city = section.getFieldByKey('is_service_address_city');
                if (city) {
                    city.value = suggestion.city;
                }
            });
            this.setState(nextState);
        }else{
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = suggestion;
                }
            });
            this.setState(nextState);
        }
    };

    setTypeChange = (key, value) => {
        if(!this.props.disabled){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });

            this.setState(nextState);
        }
    };

    changeTypeWithPatientName = (key, value) => { 
        const { section } = this.state;
        let firstName = section.getFieldByKey('is_patient_first_name').value;
        let lastName = section.getFieldByKey('is_patient_last_name').value;
  
        if( key === 'is_patient_first_name' ) {
          firstName = value;
        } else {
          lastName = value;
        }
        this.setTypeChange(key, value);
        this.setTypeChange('is_first_name_and_last_name_of_patient', `${firstName} ${lastName}`);
      };

    setTypeChangeNumber = (key, value) => {
        if(!this.props.disabled){
            if(value.trim()===''){
                const nextState = produce(this.state, draftState => {
                    const { section } = draftState;
                    const field = section.getFieldByKey(key);
                    if (field) {
                        field.value = value;
                    }
                });
                this.setState(nextState);
            }else{
                if(ReqHelper.isNumber(parseInt(value.trim()))){
                    const nextState = produce(this.state, draftState => {
                        const { section } = draftState;
                        const field = section.getFieldByKey(key);
                        if (field) {
                            field.value = value;
                        }
                    });
                    this.setState(nextState);
                }
            }
        }
    };

    prepareMeCitySelect = () =>{
        if(this.props.cities.length>0){
            return this.props.cities.map(x => ({
                value: x.PostalCode!==null?x.PostalCode:'', 
                city:  x.City!==null?x.City:'',
                label:  x.City!==null?x.PostalCode+"-"+x.City:''
            }));
        }else{
            return [];
        }
    }

    render() {
        const {
          disabled,
          translateKey,
          showValidationErrors,
          form,
          sectionNumber,
          subsectionName,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
        } = this.props;
        const { section } = this.state;
        const isBirthday = section.getFieldByKey('is_birthday');
        const isHeight = section.getFieldByKey('is_height');
        const isWeight = section.getFieldByKey('is_weight');
        const isHomeDoctor = section.getFieldByKey('is_home_doctor');
        const isServiceAddressPostalCode = section.getFieldByKey('is_service_address_postal_code')
        let isdisabledfields = disabled;
        const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
        const preparedSelectGroup = this.prepareMeCitySelect();
        if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8) {isdisabledfields = true}
        const isNewUI = true;
        const phones = [
            {
                newUI: isNewUI,
                showValidation: showValidationErrors,
                className: 'input-wrapper form-margin-bottom-fix',
                setTypeChangeToParent: this.setTypeChange,
                field: section.getFieldByKey('is_patient_phone'), 
                text: translateKey("new_phone_label"),
                transKey: 'new_phone_label',
                keyName: 'is_patient_phone'
            },
            {
                newUI: isNewUI,
                showValidation: showValidationErrors,
                className: 'input-wrapper form-margin-bottom-fix',
                setTypeChangeToParent: this.setTypeChange,
                field: section.getFieldByKey('is_patient_cellphone'), 
                text: translateKey("new_cellphone_label"),
                transKey: 'new_cellphone_label',
                keyName: 'is_patient_cellphone'
            }
        ];
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={isdisabledfields}>
                        <div className="row">
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.changeTypeWithPatientName} field={section.getFieldByKey('is_patient_last_name')} text={translateKey("last_name")} transKey="last_name" keyName="is_patient_last_name"/> 
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.changeTypeWithPatientName} field={section.getFieldByKey('is_patient_first_name')} text={translateKey("first_name")} transKey="first_name" keyName="is_patient_first_name"/> 
                            
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_service_address_street')} text={translateKey("street")} transKey="street" keyName="is_service_address_street"/> 
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-6 col-lg-3 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_service_address_street_number')} text={translateKey("form-text-section-0-2")} transKey="form-text-section-0-2" keyName="is_service_address_street_number"/>
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-6 col-lg-3 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_service_address_house_number')} text={translateKey("apartment_number")} transKey="apartment_number" keyName="is_service_address_house_number"/> 

                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={section.getFieldByKey('is_service_address_city')} text={translateKey("city")} transKey="city" keyName="is_service_address_city"/> 
                            <WrappedAutoSuggestComponent 
                                newUI={isNewUI} 
                                value={isServiceAddressPostalCode.value} 
                                suggestions={preparedSelectGroup}
                                showValidation={this.state.showValidation}
                                keyName="is_service_address_postal_code"
                                validationErrors={isServiceAddressPostalCode.validationErrors}
                                field={isServiceAddressPostalCode}
                                text={translateKey("postal_code")}
                                setTypeChangeToParent={this.setTypeChangePostalCode}
                                disabled={isdisabledfields}
                                className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                            /> 
                            <div className="col-sm-6 col-lg-3 form-margin-bottom-fix"></div>
                            <div className="col-sm-12 col-lg-6">
                                <MultiplePhonesComponent 
                                    phones={phones}
                                    showValidation={showValidationErrors}
                                    errorMessage={translateKey("error_for_multiple_fields")}
                                />
                            </div>
                            <div className="col-sm-12 col-lg-6 form-margin-bottom-fix"></div>
                            <WrappedDatePickerComponent
                                newUI={isNewUI} 
                                showValidation={showValidationErrors}
                                keyName="is_birthday"
                                value={isBirthday.value}
                                validationErrors={isBirthday.validationErrors}
                                field={isBirthday}
                                text={translateKey("born")}
                                setTypeChangeToParent={this.setTypeChange}
                                disabled={isdisabledfields}
                                className="col-sm-6 col-lg-3 form-margin-bottom-fix"
                                withYear={true}
                                birthDay={true}
                            />
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-6 col-lg-3 form-margin-bottom-fix" valueFormatter={ReqHelper.simpleValueFormatter} setTypeChangeToParent={this.setTypeChangeNumber} field={isHeight} text={translateKey("height_with_unit")} transKey="height_with_unit" keyName="is_height"/>
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-3 form-margin-bottom-fix" valueFormatter={ReqHelper.simpleValueFormatter} setTypeChangeToParent={this.setTypeChangeNumber} field={isWeight} text={translateKey("weight_with_unit")} transKey="weight_with_unit" keyName="is_weight"/>
                       
                            <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isHomeDoctor} text={translateKey("form-text-home-doctor")} transKey="form-text-home-doctor" keyName="is_home_doctor"/> 
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    cities: state.enums.citiesList,
    citiesStatus: state.enums.citiesListStatus
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Section2));
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import { LoaderModal, AlertModalInfo} from "../index";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Alert} from "../../_constance/classes/index";

class TableColumnSelect extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            columnList: this.props.columnList,
            columnListBackup: '',
            showColumns: false,
            errorComponent: Alert,
            loaderModal: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({columnListBackup: JSON.parse(JSON.stringify(this.props.columnList))});
        document.body.classList.remove('modal-open');
    }

    componentWillUnmount() {
        this._isMounted = false;
    };

    toggleColumn = () => {
        if (this._isMounted) {
            this.setState({showColumns: !this.state.showColumns});
        }
    }

    updateElement(items, element){
        console.log(items);
        console.log(element);
    }

    clearThisOptions(){

    }

    saveColumnChange = () =>{
        if(this._isMounted){
            this.setState({
                loaderModal: true
            })
            //saving
        }
        setTimeout(() => {
            this.setState({
                loaderModal: false
            })
         }, 1000);
    }

    render() {
        const { translateKey, columnList } = this.props;
        let columnsModal = (
            <MDBModal isOpen={this.state.showColumns} toggle={this.toggleColumn} fullHeight size="lg">
                <MDBModalHeader toggle={this.toggleColumn}></MDBModalHeader>
                <MDBModalBody className="filter-modal-body">
                    <div className="filer-category-wrapper">
                        <div className="filter-modal-option">
                            <div className="filter-modal-option-text">{translateKey("select-columns-to-show")}</div>
                        </div>
                        <div className="filter-modal-option-container">
                            {
                                columnList.map((column,iter)=>{
                                    return <button key={iter} type="button" onClick={()=>this.updateElement(iter, column)} className={"btn btn-light btn-sm option" + (column.enabled===true? ' active-filter': '')}>{translateKey(column.name)}</button>
                                })
                            }
                        </div>
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={this.toggleColumn}>{translateKey('cancel')}</MDBBtn>
                    <MDBBtn color="primary" onClick={this.saveColumnChange}>{translateKey('save')}</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )
        const { errorComponent } = this.state;
        let SaveAlert = (
          <AlertModalInfo size="lg" centeredVertical={true} centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
        )
        return (
            <React.Fragment>
                <LoaderModal show={this.state.loaderModal}/>
                <button type="button" className="btn norbsoft-filter-button btn-light" onClick={this.toggleColumn}><i className="fas fa-table norbsoft-filter-icon"></i>{translateKey("column")}</button>
                {columnsModal}
                {SaveAlert}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {return {appLanguage: state.lang.appLanguage}};
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableColumnSelect));
import * as React from "react";
import { Asterisk } from "../../../_helpers/field_asterix";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";

import { PHONE_PREFIXES } from "../../../_constance/enums/phonePrefixes"; 

// mobile phone key
const IS_MOBILE_PHONE_KEY = "is_mobile_phone";

// patient cell phone
const IS_PATIENT_CELLPHONE = "is_patient_cellphone";

//contact person phone
const IS_CONTACT_PERSON_MOBILE_PHONE = "is_contact_person_mobile_phone";

//contact person home phone
const IS_CONTACT_PERSON_HOME_PHONE = "is_contact_person_home_phone";

// home phone key
const IS_HOME_PHONE_KEY = "is_home_phone";

// patient home phone
const IS_PATIENT_PHONE_KEY = "is_patient_phone";

export class InputTypeTextComponent extends React.Component {
  static defaultProps = {
    valueFormatter: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      text: props.text,
      isIm: props.isImportant,
      phonePrefix: PHONE_PREFIXES[0].value
    };
  }

  componentDidMount() {
    // in case of telephone input with country prefix select
    const { keyName } = this.props;
    if (keyName === IS_MOBILE_PHONE_KEY || keyName === IS_PATIENT_CELLPHONE || keyName === IS_CONTACT_PERSON_MOBILE_PHONE || keyName === IS_CONTACT_PERSON_HOME_PHONE || keyName === IS_HOME_PHONE_KEY || keyName === IS_PATIENT_PHONE_KEY) {
      const prefix = this.props.field.value.substring(0,3);
      if (prefix) {
        this.setState({
          phonePrefix: prefix
        })
      } else {
        this.props.setTypeChangeToParent(keyName, PHONE_PREFIXES[0].value);
      }
    }
  }

  setPhonePrefix = (keyName, value) => {
    this.setState({phonePrefix: value})
    const {field, valueFormatter} = this.props;
    let prevValue = typeof valueFormatter === "function"
      ? valueFormatter(field.value)
      : field.value;
    if(prevValue.length>2){
      prevValue = prevValue.substring(3)
    }
    this.props.setTypeChangeToParent(keyName, value+''+prevValue);
  }

  setTypeChange(key, value) {
    const valueFormatted =
      typeof valueFormatter === "function"
        ? this.props.valueFormatter(value)
        : value;
    this.props.setTypeChangeToParent(key, valueFormatted);
  }

  render() {
    const {
      field,
      showValidation,
      keyName,
      valueFormatter,
      disabled,
      isImportant,
      labelClass,
      newUI,
      hideErrorRequired
    } = this.props;
    let validationErrors = field.validationErrors;
    const showErrors = validationErrors.length > 0 && showValidation;
    if (hideErrorRequired) {
      const index = field.validationErrors.indexOf('err_required');
      validationErrors = field.validationErrors.splice(index-1, 1);
    }

    const value =
      typeof valueFormatter === "function"
        ? valueFormatter(field.value)
        : field.value;

    if (labelClass) {
      return (
        <React.Fragment>
          <div className={labelClass}>
            <label className={`form-label-basic ${newUI ? "newUI" : ""}`}>
              <span
                className={`quick-label-position-fix ${
                  isImportant && field.checkImportant
                    ? "input_important_class"
                    : ""
                }`}
              >
                {this.props.text.toUpperCase()} <Asterisk isRequired={field.isRequired}/>
              </span>
            </label>
          </div>
          <div className={this.props.className}>
            <input
              type="text"
              disabled={disabled}
              className={`form-control ${newUI ? "newUI" : ""} ${
                showErrors ? "is-invalid" : ""
              } ${
                isImportant && field.checkImportant
                  ? "input_important_input"
                  : ""
              }`}
              data-field-name={keyName}
              onChange={(e) =>
                this.setTypeChange(keyName, e.target.value)
              }
              value={value}
            />
            {showErrors && (
              <ValidationErrorsList
                keyName={keyName}
                errors={validationErrors}
              />
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div className={`${this.props.className} ${showErrors?'is-invalid':''}`} >
          <label className={`form-label-basic ${newUI ? "newUI" : ""}`}>
            <span
              className={`quick-label-position-fix ${
                isImportant && field.checkImportant
                  ? "input_important_class"
                  : ""
              }`}
            >
              {this.props.text.toUpperCase()} <Asterisk isRequired={field.isRequired}/>
            </span>
          </label>
          <div className="d-flex">
            {(keyName === IS_MOBILE_PHONE_KEY || keyName === IS_PATIENT_CELLPHONE || keyName === IS_CONTACT_PERSON_MOBILE_PHONE || keyName === IS_CONTACT_PERSON_HOME_PHONE || keyName === IS_HOME_PHONE_KEY || keyName === IS_PATIENT_PHONE_KEY) && (    
              <select 
                className={`form-control newUI phone-prefix-select`} 
                data-field-name={keyName} 
                onChange={e => this.setPhonePrefix(keyName, e.target.value)} 
                value={this.state.phonePrefix}
              >
                {PHONE_PREFIXES.map(possibleValue => {
                  const id = `${keyName} + ${possibleValue.value}`;
                  return (
                    <option 
                      key={id} 
                      value={possibleValue.value}
                    >
                      {possibleValue.label}
                    </option>
                  )
                })}
              </select>
            )}
            <input
              type="text"
              disabled={disabled}
              className={`form-control input-flex-firefox-fix ${newUI ? "newUI" : ""} ${
                showErrors ? "is-invalid" : ""
              } ${
                isImportant && field.checkImportant ? "input_important_input" : ""
              }`}
              data-field-name={keyName}
              onChange={(e) =>
                this.setTypeChange(keyName, e.target.value)
              }
              value={value}
            />
          </div>
          {showErrors && (
            <ValidationErrorsList keyName={keyName} errors={validationErrors} />
          )}
        </div>
      );
    }
  }
}
import noop from '../_helpers/noop';
import * as Validation from './validation';

export default class FormField {
    form;
    key;
    entity;
    defaultValue = '';
    currentValue;
    isRequired;
    fieldImportantList;
    isFalseable;
    validationRules = {};
    valueMapper = noop;

    constructor({ key, entity, defaultValue = '', isRequired, fieldImportantList, isFalseable, validationRules = {}, valueMapper = noop } = {}) {
        this.key = key;
        this.entity = entity;
        this.defaultValue = defaultValue;
        this.isRequired = isRequired;
        this.fieldImportantList = fieldImportantList;
        this.isFalseable = isFalseable;
        this.validationRules = validationRules;
        this.valueMapper = valueMapper;
    }

    assignToForm(form) {
        this.form = form;
    }

    validate() {
        const isRequired = typeof this.isRequired === 'function' ? this.isRequired(this.form) : this.isRequired;
        const filterValidations = isRequired ? [] : ['possibleValues', "atLeastOneYes"];
        const validationRules = typeof this.validationRules === 'function' ? this.validationRules(this.form) : this.validationRules;
        const filteredValidationRules = Object.keys(validationRules).filter(rule => filterValidations.indexOf(rule) === -1);
        const validators = filteredValidationRules.map(rule => {
            const params = validationRules[rule];
            return {
                validator: Validation[rule],
                params,
            };
        });
        if (isRequired) validators.push({ validator: Validation.required });

        return validators.reduce((previous, { validator, params }) => {
            try {
                const { isValid, errors } = validator(this.value, params);
                return {
                    isValid: isValid && previous.isValid,
                    errors: [...previous.errors, ...errors],
                };
            } catch (e) {
                console.log(`Error: Something's wrong with validators`);
                return previous;
            }
        }, { isValid: true, errors: [] });
    }

    checkImportantByFieldList = () => {
        if(this.fieldImportantList){
            const isImportant = this.fieldImportantList.filter(field => {
                const fieldToCheck = this.form.getFieldByKey(field.key);
                if(field.type === "BOOLEAN"){
                    if(field.basedOnField){
                        const basedField= this.form.getFieldByKey(field.basedOnField);
                        if(basedField.value === field.skipCondition){
                            return true;
                        }else{
                            if(fieldToCheck.value===true){
                                return true;
                            }
                        }
                    }else{
                        if(fieldToCheck.value===true){
                            return true;
                        }
                    }
                }else{
                    if(field.type === "STRING"){
                        if(fieldToCheck.value.length>0){
                            return true;
                        }
                    }
                }
                return false;
            });
            if(isImportant.length===0){
                // all important fields are clear, user need to file at least one
                return true;
            }else{
                // one of this fields are filled. User can go to next section
                return false;
            }
        }else{
            return false
        }
     }

    get validationErrors() {
        // TODO: think about usage and performance, maybe static validationErrors field, populated inside validate() would be better?
        const { errors } = this.validate();
        return errors;
    }

    get isValid() {
        // TODO: think about usage and performance, maybe static isValid field, populated inside validate() would be better?
        const { isValid } = this.validate();
        if(this.key === 'is_home_phone' || this.key === 'is_mobile_phone' || this.key === 'is_contact_person_home_phone' || this.key === 'is_contact_person_mobile_phone' || this.key === 'is_patient_phone' || this.key === 'is_patient_cellphone') {
            if(this.isRequired === false) {
                return true
            }
        }
        return isValid;
    }

    get checkImportant(){
        let check = this.checkImportantByFieldList();
        return check
    }

    get value() {
        const mappedValue = this.valueMapper(this.form);
        if (this.isFalseable) {
            if (mappedValue !== null && mappedValue !== undefined) {
                return mappedValue;
            }
            if (this.currentValue !== null && this.currentValue !== undefined) {
                return this.currentValue;
            }
            return this.defaultValue;
        }
        return mappedValue || this.currentValue || this.defaultValue
    }

    checkIsNullForImportantPurpose() {
        if(this.isFalseable){
            if(this.currentValue === null){
                return true
            }else{
                return false;
            }
        }else{
            return this.value();
        }
    }

    set value(value) {
        // TODO: remove if API booleans are fixed
        let newValue = value;
        if (value === "False") newValue = false;
        if (value === "True") newValue = true;
        this.currentValue = newValue;
    }
}


export default class DnDPriorityOffer {
  fields = [];

  constructor({ fields = [] } = {}) {
    this.fields = fields;
  }

  prepareMeListFromString = (list) => {
    if (list && list !== null) {
      if (list.length > 0) {
        let arrList = list.split(';');
        if (arrList.length > 0) {
          for (let index = 0; index < arrList.length; index++) {
            let oldIndex = this.getFieldPosition(arrList[index]);
            if (oldIndex > -1) {
              this.setActive(oldIndex);
            }
          }
        }
      }
    }
  }

  prepareMeListOfActiveItems = () => {
    return this.fields.filter(item => item.active)
  }

  setActive(index) {
    if (index < this.fields.length) {
      this.fields[index].active = true;
    }
  }

  changeActive(item) {
    let index = this.getFieldPosition(item.id);
    if (index > -1) {
      this.fields[index].active = !this.fields[index].active
    }
  }

  prepareMeStringFromList = () => {
    return this.fields.map(element => {
      if (element.action === true) {
        return element.id
      } else {
        return ''
      }
    }).join(';')
  }

  prepareMeStringFromListActive = () => {
    return this.fields.map(element => {
      if (element.action === true && element.active) {
        return element.id_deutch
      } else {
        return ''
      }
    }).filter(el=>el!=='').join(';')
  }

  changePosition = (from, to, item) => {
    this.fields.splice(from, 1);
    this.fields.splice(to, 0, item)
  }

  changePositionByIndex = (from, to, index) => {
    const oldPrior = this.fields;
    const newPriorList = Array.from(oldPrior);
    newPriorList.splice(from, 1);
    newPriorList.splice(to, 0, oldPrior[index]);
    this.fields = newPriorList;
  }

  getFieldPosition(id) {
    return this.fields.findIndex(item => {
      if(item.posibileKeys.indexOf(id)>-1){
        return true;
      }
      return false;
    });
  }

  getFieldPositionByIndex(index) {
    if (index < this.fields.length) {
      return this.fields[index];
    }
  }

  getFieldByKey(id) {
    return this.fields.find(field => {
      if(field.posibileKeys.indexOf(id)>-1){
        return true;
      }
      return false;
    });
  }
}

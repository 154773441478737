
export default class DnDPriorityOfferElement {
    id;
    id_deutch;
    posibileKeys;
    name;
    active;
    action;
    icon;
    iconActive;
    show

    constructor({ id, id_deutch, posibileKeys, name, active, action, icon, iconActive, show} = {}) {
        this.id = id;
        this.id_deutch = id_deutch;
        this.posibileKeys = posibileKeys;
        this.name = name;
        this.active = active;
        this.action = action;
        this.icon = icon;
        this.iconActive = iconActive;
        this.show = show
    }
}

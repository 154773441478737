import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";
import { IconsMap } from "../../../_helpers/index";
import PflegegradElement from "./pflegegradElement";

const keyName=  'is_pflegegrad';
const isKeine=  'is_kein_pflegegrad';

class ListPflegegradComponent extends React.Component {

    setTypeChange = (value) => {
        if(!this.props.disabled){
            if(value===null || value === 0){
                this.props.clear(isKeine, value);
            }else{
                this.props.setTypeChangeToParent(keyName, value);
            }
        }
    };

    render() {
        const { section, showValidation, className, translateKey, disabled, isBawaria } = this.props;
        const isPflegegrad = section.getFieldByKey(keyName);
        const showErrors = showValidation && isPflegegrad.validationErrors.length > 0;
        return (
          <div className={`${className} list-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
            <div className={`pflegegrad-wrapper ${disabled?'no-pointer-events':''}`}>
                <PflegegradElement
                    isActive={isPflegegrad.value.toString()===''}
                    special={true}
                    translateKey={translateKey}
                    name="form-text-kein-pflegegrad"
                    value={0}
                    changeMeValue={this.setTypeChange}
                />

                <PflegegradElement
                    isActive={isPflegegrad.value.toString()==='717700000'}
                    icon={IconsMap.other.human_gray}
                    iconActive={IconsMap.other.human_white}
                    pCost="-0.00 €"
                    bCost="-83,33€"
                    special={false}
                    translateKey={translateKey}
                    name="from-text-pflegegrad1"
                    value={717700000}
                    changeMeValue={this.setTypeChange}
                    isBawaria={isBawaria}
                />

                <PflegegradElement
                    isActive={isPflegegrad.value.toString()==='717700001'}
                    icon={IconsMap.other.human_walk_gray}
                    iconActive={IconsMap.other.human_walk_white}
                    pCost="-316.00 €"
                    bCost="-83,33€"
                    special={false}
                    translateKey={translateKey}
                    name="from-text-pflegegrad2"
                    value={717700001}
                    changeMeValue={this.setTypeChange}
                    isBawaria={isBawaria}
                />

                <PflegegradElement
                    isActive={isPflegegrad.value.toString()==='717700002'}
                    icon={IconsMap.other.human_wheel_gray}
                    iconActive={IconsMap.other.human_wheel_white}
                    pCost="-545.00 €"
                    bCost="-83,33€"
                    special={false}
                    translateKey={translateKey}
                    name="from-text-pflegegrad3"
                    value={717700002}
                    changeMeValue={this.setTypeChange}
                    isBawaria={isBawaria}
                />

                <PflegegradElement
                    isActive={isPflegegrad.value.toString()==='717700003'}
                    icon={IconsMap.other.human_bed_gray}
                    iconActive={IconsMap.other.human_bed_white}
                    pCost="-728.00 €"
                    bCost="-83,33€"
                    special={false}
                    translateKey={translateKey}
                    name="from-text-pflegegrad4"
                    value={717700003}
                    changeMeValue={this.setTypeChange}
                    isBawaria={isBawaria}
                />

                <PflegegradElement
                    isActive={isPflegegrad.value.toString()==='717700004'}
                    icon={IconsMap.other.human_bed_2_gray}
                    iconActive={IconsMap.other.human_bed_2_white}
                    pCost="-901.00 €"
                    bCost="-83,33€"
                    special={false}
                    translateKey={translateKey}
                    name="from-text-pflegegrad5"
                    value={717700004}
                    changeMeValue={this.setTypeChange}
                    isBawaria={isBawaria}
                />

            </div>
            {showErrors && (
              <ValidationErrorsList
                errors={isPflegegrad.validationErrors}
                keyName={keyName}
              />
            )}
          </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default withRouter(connect(null, mapDispatchToProps)(ListPflegegradComponent));
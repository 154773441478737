import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ContractStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
        };
    }

    getStatusText(status){
        if(this.props.contractStatus){
            for(let i=0; i<this.props.contractStatus.length; i++){
                if(this.props.contractStatus[i].Value!==null && status!==null && status!== undefined){
                    if(this.props.contractStatus[i].Value.toString() === status.toString()){
                        return this.props.contractStatus[i].Label;
                    }
                }
            }
        }
    }

    render() {
        return (
            <span>{this.getStatusText(this.props.element)}</span>
        );
    }
}
const mapStateToProps = state => {return {contractStatus: state.enums.contractStatus}};
export default withRouter(connect(mapStateToProps, null)(ContractStatus));
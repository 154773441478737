import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import { IconsMap } from "../../_helpers/index";


class TablePagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paginationObj: props.paginationObj,
            loading: props.loading,
        };
    }

    changeItemsPerPage(itemsPerPage){
        if(this.props.paginationObj.itemsPerPage!==itemsPerPage){
            this.setState({
                paginationObj: {
                    ...this.state.paginationObj,
                    itemsPerPage: itemsPerPage
                }
            })
            this.props.paginationObj.itemsPerPage = itemsPerPage;
        }
        this.props.recalculateAll();
    }

    onPreviousPageClick(i){
        if(this.props.paginationObj.currentPage>1){
            if(this.props.paginationObj.pageFrom===this.props.paginationObj.currentPage){
                this.setState(prevState => {
                    return {
                      ...prevState,
                      paginationObj: {
                        ...prevState.paginationObj,
                        currentPage: prevState.paginationObj.currentPage--,
                        pageFrom: prevState.paginationObj.pageFrom-5,
                        pageTo:prevState.paginationObj.pageTo-5
                      }
                    }
                });
                this.props.paginationObj.pageFrom = this.props.paginationObj.pageFrom-5;
                this.props.paginationObj.pageTo = this.props.paginationObj.pageTo-5;
            }else{
                this.setState(prevState => {
                    return {
                      ...prevState,
                      paginationObj: {
                        ...prevState.paginationObj,
                        currentPage: prevState.paginationObj.currentPage--
                      }
                    }
                });
            }
            this.props.paginationObj.currentPage--;
            this.props.simpleDataUpdate();
        }
    }

    onNextPageClick(){
        if(this.props.paginationObj.currentPage<this.props.paginationObj.totalPages){
            if(this.props.paginationObj.pageTo===this.props.paginationObj.currentPage){
                this.setState(prevState => {
                    return {
                        ...prevState,
                        paginationObj: {
                            ...prevState.paginationObj,
                            currentPage: prevState.paginationObj.currentPage++,
                            pageFrom: prevState.paginationObj.pageFrom+5,
                            pageTo:prevState.paginationObj.pageTo+5
                        }
                    }
                });
                this.props.paginationObj.pageFrom = this.props.paginationObj.pageFrom+5;
                this.props.paginationObj.pageTo = this.props.paginationObj.pageTo+5;
            }else{
                this.setState(prevState => {
                    return {
                      ...prevState,
                      paginationObj: {
                        ...prevState.paginationObj,
                        currentPage: prevState.paginationObj.currentPage++
                      }
                    }
                });
            }
            this.props.paginationObj.currentPage++;
            this.props.simpleDataUpdate();
        }
    }

    onPreviousFivesClick(i){
        if(this.props.paginationObj.pageFrom>this.props.paginationObj.visiblePages){
            this.setState(prevState => {
                return {
                    ...prevState,
                    paginationObj: {
                    ...prevState.paginationObj,
                    pageFrom: prevState.paginationObj.pageFrom-5,
                    pageTo:prevState.paginationObj.pageTo-5
                    }
                }
            });
            this.props.paginationObj.pageFrom = this.props.paginationObj.pageFrom-5;
            this.props.paginationObj.pageTo = this.props.paginationObj.pageTo-5;
        }
    }

    onNextFivesClick(){
        if(this.props.paginationObj.pageTo<this.props.paginationObj.totalPages){
            this.setState(prevState => {
                return {
                    ...prevState,
                    paginationObj: {
                        ...prevState.paginationObj,
                        pageFrom: prevState.paginationObj.pageFrom+5,
                        pageTo:prevState.paginationObj.pageTo+5
                    }
                }
            });
            this.props.paginationObj.pageFrom = this.props.paginationObj.pageFrom+5;
            this.props.paginationObj.pageTo = this.props.paginationObj.pageTo+5;
        }
    }

    onBeginPageClick(){
        if(this.props.paginationObj.currentPage>1){
            this.setState(prevState => {
                return {
                    ...prevState,
                    paginationObj: {
                    ...prevState.paginationObj,
                    currentPage: 1,
                    pageFrom: 1,
                    pageTo:5
                    }
                }
            });
            this.props.paginationObj.pageFrom = 1;
            this.props.paginationObj.pageTo = 5;
            this.props.paginationObj.currentPage = 1;
            this.props.simpleDataUpdate();
        }
    }

    onEndPageClick(){
        if(this.props.paginationObj.currentPage!==this.props.paginationObj.totalPages){
            this.setState(prevState => {
                return {
                    ...prevState,
                    paginationObj: {
                        ...prevState.paginationObj,
                        currentPage: prevState.paginationObj.totalPages,
                        pageFrom: ((Math.ceil(prevState.paginationObj.totalPages/5)-1)*5) > 0 ? ((Math.ceil(prevState.paginationObj.totalPages/5)-1)*5) : 1,
                        pageTo: (Math.ceil(prevState.paginationObj.totalPages/5))*5
                    }
                }
            });
            if(this.props.paginationObj.totalPages>=5){
                this.props.paginationObj.pageFrom = (Math.ceil(this.props.paginationObj.totalPages/5)-1)*5;
            }else{
                this.props.paginationObj.pageFrom = 1;
            }
            this.props.paginationObj.pageTo = (Math.ceil(this.props.paginationObj.totalPages/5))*5;
            this.props.paginationObj.currentPage = this.props.paginationObj.totalPages;
            this.props.simpleDataUpdate();
        }
    }

    changePageTo(i){
        this.setState({
            paginationObj: {
                ...this.state.paginationObj,
                currentPage: i
            }
        })
        this.props.paginationObj.currentPage = i;
        this.props.simpleDataUpdate();
    }

    prepareMePages(){
        let pages = [];
        let finalMax = this.props.paginationObj.pageTo;
        if(this.props.paginationObj.pageTo>this.props.paginationObj.totalPages){
            finalMax = this.props.paginationObj.totalPages;
        }
        for(let i=this.props.paginationObj.pageFrom; i<=finalMax;i++){
            pages.push(<button key={Math.random().toString(36).substring(2)} type="button" onClick={()=> this.changePageTo(i)} className={"page-number-button" + (this.props.paginationObj.currentPage===i? " btn-primary text-white" :" btn-light text-primary")}>{i}</button>);
        }
        return pages;
    }

    render() {
        let previousItem = ''
        if(this.props.paginationObj.currentPage!==1 && this.props.loading!==true && this.props.paginationObj.totalItems>0){
            previousItem = (
                <div className="previousPage" onClick={()=>this.onPreviousPageClick()}>
                    <img className="previousIcon" src={IconsMap.xxxhdpi.left_arrow} alt="<"/>
                    <span className="text-primary">{this.props.translateKey("previous")}</span>
                </div>
            )
        }
        let nextItem = ''
        if(this.props.paginationObj.currentPage!==this.props.paginationObj.totalPages && this.props.loading!==true  && this.props.paginationObj.totalItems>0){
            nextItem = (
                <div className="nextPage" onClick={()=>this.onNextPageClick()}>
                    <span className="text-primary">{this.props.translateKey("next")}</span>
                    <img className="nextIcon" src={IconsMap.xxxhdpi.right_arrow} alt=">"/>
                </div>
            )
        }
        let spacer = '';
        if(nextItem!=='' && previousItem!==''){
            spacer = (
                <div className="spacerNextPrevious"></div>
            )
        }
        let pageToShow=(
            <div className="btn-group">
                {this.prepareMePages()}
            </div>
        )
        let paginationMainComponent = '';
        if(this.props.paginationObj.totalPages>1){
            paginationMainComponent = (
                <div className="btn-group mainPagingComponent">
                    <button type="button" className="btn-light left-arrow-wall-pagination-wrapper" onClick={()=>this.onBeginPageClick()}>
                        <img className="paging-icons-class-wall left-arrow-wall-pagination" src={IconsMap.xxxhdpi.left_arrow_wall} alt="|<"/>
                    </button>
                    <button type="button" className="btn-light left-arrow-pagination-wrapper" onClick={()=>this.onPreviousFivesClick()}>
                        <img className="paging-icons-class left-arrow-pagination" src={IconsMap.xxxhdpi.left_arrow} alt="<"/>
                    </button>
                    {pageToShow}
                    <button type="button" className="btn-light right-arrow-pagination-wrapper" onClick={()=>this.onNextFivesClick()}>
                        <img className="paging-icons-class right-arrow-pagination" src={IconsMap.xxxhdpi.right_arrow} alt=">"/>
                    </button>
                    <button type="button" className="btn-light right-arrow-wall-pagination-wrapper" onClick={()=>this.onEndPageClick()}>
                        <img className="paging-icons-class-wall right-arrow-wall-pagination" src={IconsMap.xxxhdpi.right_arrow_wall} alt=">|"/>
                    </button>
                </div>
            )
        }

        let itemPerPageElement = '';
        if(this.props.paginationObj.totalItems>25){
            if(this.props.paginationObj.totalItems>50){
                itemPerPageElement = (
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" onClick={()=>this.changeItemsPerPage(25)} className={"btn" + (this.props.paginationObj.itemsPerPage===25 ? " btn-primary selected-items-per-page": " btn-light")}>25</button>
                        <button type="button" onClick={()=>this.changeItemsPerPage(50)} className={"btn" + (this.props.paginationObj.itemsPerPage===50 ? " btn-primary selected-items-per-page": " btn-light")}>50</button>
                        <button type="button" onClick={()=>this.changeItemsPerPage(100)} className={"btn" + (this.props.paginationObj.itemsPerPage===100? " btn-primary selected-items-per-page": " btn-light")}>100</button>
                    </div>
                )
            }else{
                itemPerPageElement = (
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" onClick={()=>this.changeItemsPerPage(25)} className={"btn" + (this.props.paginationObj.itemsPerPage===25 ? " btn-primary selected-items-per-page": " btn-light")}>25</button>
                        <button type="button" onClick={()=>this.changeItemsPerPage(50)} className={"btn" + (this.props.paginationObj.itemsPerPage===50 ? " btn-primary selected-items-per-page": " btn-light")}>50</button>
                    </div>
                )
            }
        }
        
        if(!this.props.loading){
            return (
                <div className="tablePagingWrapper">
                    <div className="firstRowPagination">
                        <div className="previousNextClass">
                            {previousItem}
                            {spacer}
                            {nextItem}
                        </div>
                    </div>
                    <div className="secondRowPagination">
                        <div className="itemsPerPageSelectionClass">
                            {itemPerPageElement}
                        </div>
                        {paginationMainComponent}
                        <div className="totalItemsComponent">
                            <span className="text-primary">{this.props.paginationObj.totalItems} {this.props.translateKey("results")}</span>
                        </div>
                    </div>
                </div>
            );
        }else{
            return ('')
        }
    }
}

const mapStateToProps = state => {return {i18: state.lang.i18data};}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TablePagination));
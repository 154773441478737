import * as React from 'react';

export class LoaderTable extends React.Component {
    render() {
        return (
            <div className="empty-table-wrapper">
                <i className="fas fa-spinner fa-spin fa-4x empty-table-icon"></i>
            </div>
        );
    }
}
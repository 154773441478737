import * as ValidationError from './errors';
import * as FieldTypes from './fieldTypes';

export const required = value => ({
    isValid: !!value,
    errors: !!value ? [] : [ValidationError.ERR_REQUIRED],
});

export const minLength = (value, minLength) => {
    const isValid = typeof value === "string" && value.length >= minLength;
    const errors = isValid ? [] : [ValidationError.ERR_TEXT_MIN_LENGTH];
    return {
        isValid,
        errors,
    };
};

export const maxLength = (value, maxLength) => {
    const isValid = typeof value === "string" && value.length <= maxLength;
    const errors = isValid ? [] : [ValidationError.ERR_TEXT_MAX_LENGTH];
    return {
        isValid,
        errors,
    };
};

export const fieldType = (value, type, minMaxLenth) => {
    switch (type) {
        case FieldTypes.EMAIL:
            return emailValidator(value);
        case FieldTypes.PHONE_NUMBER:
            return phoneNumberValidator(value);
        case FieldTypes.TEXT:
            return minLength(minMaxLenth);
        default:
            // TODO: add proper handling
            throw new Error('Something went wrong');
    }
};

export const possibleValues = (value, list) => {
    const isValid = list.findIndex(item => item === value) !== -1;
    const errors = isValid ? [] : [ValidationError.ERR_INCORRECT_VALUE];
    return { isValid, errors };
};

export const atLeastOneYes = (value, list) => {
    let isTrue = list.filter((elem)=>{
        return elem.value !== elem.neededValue
    })
    let isValid = true;
    let errors = [];
    if(isTrue.length>0){
        isValid = false;
        errors = [ValidationError.AT_LEAST_ONE_YES];
        return { isValid, errors };
    }else{
        return { isValid, errors};
    }
};

// Field types validations
const emailValidator = value => {
    const isValid = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value);
    return {
        isValid,
        errors: isValid ? [] : [ValidationError.ERR_NOT_AN_EMAIL_ADDRESS],
    }
};
const defaultPhoneNumberError = {
    isValid: false,
    errors: [ValidationError.ERR_INCORRECT_PHONE_NUMBER],
};
const phoneNumberValidator = value => {
    if (typeof value !== 'string') {
        return defaultPhoneNumberError;
    }
    if (value[0] !== '+' && value !== '') {
        return {
            isValid: false,
            errors: [ValidationError.ERR_MISSING_COUNTRY_CODE],
        };
    }

    const countryCode = value.substring(0, 3);
    const number = value.substr(3);

    if (number[0] === '0') {
        return {
            isValid: false,
            errors: [ValidationError.ERR_INCORRECT_ZERO_PHONE_NUMBER],
        };
    }

    switch (countryCode) {
        case '+48':
            if (number.length !== 9) {
                return {
                    isValid: false,
                    errors: [ValidationError.ERR_INCORRECT_POLISH_PHONE_NUMBER],
                };
            }
            break;
        case '+44':
            if (number.length < 9 || number.length > 10) {
                return {
                    isValid: false,
                    errors: [ValidationError.ERR_INCORRECT_ENGLISH_PHONE_NUMBER],
                };
            }
            break;
        case '+49':
            if (number.length < 7 || number.length > 12) {
                return {
                    isValid: false,
                    errors: [ValidationError.ERR_INCORRECT_GERMAN_PHONE_NUMBER],
                };
            }
            break;
        default:
            break;
    }

    return {
        isValid: true,
        errors: [],
    };
};
import * as React from 'react';

export class LoaderModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
        };
    }

    render() {
        if(this.props.show){
            return (
                <div className="modal fade show modalShowFull" id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="filterModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm modalShowLoaderDialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="empty-table-wrapper loader-as-modal-wrapper">
                                    <i className="fas fa-spinner fa-spin fa-4x empty-table-icon"></i>
                                    {this.props.text?
                                        <div className="empty-table-icon">{this.props.text}</div>
                                        :
                                        ""
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            return '';
        }
    }
}
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { FormConfig } from '../../../_forms/BBForm/formConfig';

class SectionNavigationButtonsComponent extends React.Component {
    
    render() {
        const { translateKey, rerender, analizeFinished, currentSection, previousPageClick, nextPageClick, finishMe} = this.props;
        return (
            <div rerender={rerender} className='zone-form-navigation-buttons'>
            {analizeFinished &&
              <div className="previous-next-wrapper">
                {currentSection!==0 &&
                  <button type="button" className="btn btn-primary cursor-pointer next-previous-class prev-next-normal-button" onClick={previousPageClick}>
                    <i className="fas fa-chevron-left fa-1x section-navigation-icon"></i>
                    <div className="section-navigation-button-text">{translateKey("previous").toUpperCase()}</div>
                  </button>
                }
                {currentSection!==FormConfig.finalPageNumber &&
                  <button type="button" className="btn btn-primary cursor-pointer next-previous-class prev-next-active-button"onClick={nextPageClick}>
                    <div className="section-navigation-button-text">{translateKey("next").toUpperCase()}</div>
                    <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
                  </button>
                }
                {currentSection===FormConfig.finalPageNumber &&
                  <button type="button" className="btn btn-primary cursor-pointer next-previous-class prev-next-active-button" onClick={finishMe}>
                    <div className="section-navigation-button-text">{translateKey("zone-text-senden-und-beenden").toUpperCase()}</div>
                    <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
                  </button>
                }
              </div>
            }
          </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default withRouter(connect(null, mapDispatchToProps)(SectionNavigationButtonsComponent));
import * as React from 'react';

export class SimpleLoader extends React.Component {
    render() {
        return (
            <div className="empty-table-wrapper">
                <i className="fas fa-spinner fa-spin fa-2x empty-table-icon"></i>
            </div>
        );
    }
}
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";

class SectionKundeFinished extends React.PureComponent {
    render() {
        const {
          subsectionName,
          translateKey,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe
        } = this.props;
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {subsectionName}
                </div>
                <div className="zone-conent-section-title">
                    <div className="subsection">
                        <div>{translateKey("zone-text-finish-page-text")}</div>
                    </div>
                </div>
                <SectionNavigationButtonsComponent
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                />
            </div>
        ); 
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionKundeFinished));
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import { Tiles } from "../../../_constance/classes/dndPriorityOffer/TilesIds";
import ReqHelper from "../../../_helpers/request_helper";
import produce from "immer";

// filed to store tiles data
const KEY_NAME_TILES = 'is_most_important_taking_care_patient';
const KEY_IS_DRIVING = 'is_driving';
const KEY_IS_DRIVING_AUTOMAT_GEARBOX = 'is_driving_automat_gearbox';
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 

class Section17 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-17'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({showValidation: showValidationErrors});
        }
    }

    saveDiabetesAndTiles = () =>{
        const saveDiabetesTileField = this.props.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
        if(saveDiabetesTileField){saveDiabetesTileField.value = true}
    }
    
    setTileFieldValue = (action, string) =>{
        const { form } = this.props;
        const tilesString = form.getFieldByKey(KEY_NAME_TILES);
        const list = ReqHelper.prepareMeListFromString(tilesString.value, ';');
        if(ReqHelper.notNullAndUndefinied(tilesString.value) && action===true){
            if(list.indexOf(string)<0){
                tilesString.value += ';'+string;
            }
        }else{
            if(action){
                tilesString.value += ';'+string;
            }else{
                if(list.indexOf(string)>-1){
                    tilesString.value += ';'+string;
                    list.splice(list.indexOf(string),1);
                    tilesString.value = list.join(';')
                }
            }
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
            if(key === KEY_IS_DRIVING_AUTOMAT_GEARBOX || key ===  KEY_IS_DRIVING){
                if(value){
                    this.setTileFieldValue(true, Tiles.TILEELEVENCAR);
                    this.saveDiabetesAndTiles();
                }else{
                    let conditionField = section.getFieldByKey(KEY_IS_DRIVING);
                    if(key !== KEY_IS_DRIVING_AUTOMAT_GEARBOX){
                        conditionField = section.getFieldByKey(KEY_IS_DRIVING_AUTOMAT_GEARBOX);
                    }
                    if(!conditionField.value){
                        this.setTileFieldValue(false, Tiles.TILEELEVENCAR);
                        this.saveDiabetesAndTiles();
                    }
                }
            }
        });
        this.setState(nextState);
    };

    render() {
        const {
          disabled,
          translateKey,
          showValidationErrors,
          isImportant,
          subsectionName,
          sectionNumber,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe
        } = this.props;
        const { section } = this.state;
        const is_cooking = section.getFieldByKey('is_cooking');
        const is_purchases = section.getFieldByKey('is_purchases');
        const is_laundry = section.getFieldByKey('is_laundry');
        const is_poted_flower_care = section.getFieldByKey('is_poted_flower_care');
        const is_ironing = section.getFieldByKey('is_ironing');
        const is_accompanying = section.getFieldByKey('is_accompanying_patient_while_out');
        const is_cleaning_the_house = section.getFieldByKey('is_cleaning_the_house');
        const is_company = section.getFieldByKey('is_company_during_medical_visits');
        const is_common_trips = section.getFieldByKey('is_common_trips');
        const is_organization_of_free_time = section.getFieldByKey('is_organization_of_free_time');
        const is_driving = section.getFieldByKey(KEY_IS_DRIVING)
        const is_driving_automat_gearbox = section.getFieldByKey(KEY_IS_DRIVING_AUTOMAT_GEARBOX);

        const checboxClass="col-sm-12 col-lg-3";
        const classForCheckBox = "newUI-radio-check-box-class";
        const isNewUI = true; 
        
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>                  
                        <div className="newUI-section-padding"> 
                            <div className="form-label-basic newUI cursor-pointer quick-label-position-fix">{subsectionName.toUpperCase()}</div>
                            <div className="checkboxes_wrapper row">
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_cooking.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_cooking.value} validationErrors={is_cooking.validationErrors} text={translateKey("form-text-kochen")} transKey="form-text-kochen" keyName="is_cooking"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_purchases.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_purchases.value} validationErrors={is_purchases.validationErrors} text={translateKey("form-text-einkaufengehen")} transKey="form-text-einkaufengehen" keyName="is_purchases"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_laundry.checkImportant}  checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_laundry.value} validationErrors={is_laundry.validationErrors} text={translateKey("form-text-waschewaschen")} transKey="form-text-waschewaschen" keyName="is_laundry"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_poted_flower_care.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_poted_flower_care.value} validationErrors={is_poted_flower_care.validationErrors} text={translateKey("form-text-zimmerpflanzen")} transKey="form-text-zimmerpflanzen" keyName="is_poted_flower_care"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_ironing.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_ironing.value} validationErrors={is_ironing.validationErrors} text={translateKey("form-text-bugeln")} transKey="form-text-bugeln" keyName="is_ironing"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_accompanying.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_accompanying.value} validationErrors={is_accompanying.validationErrors} text={translateKey("form-text-spazierengehen")} transKey="form-text-spazierengehen" keyName="is_accompanying_patient_while_out"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_cleaning_the_house.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_cleaning_the_house.value} validationErrors={is_cleaning_the_house.validationErrors} text={translateKey("form-text-arbeitsplatzes")} transKey="form-text-arbeitsplatzes" keyName="is_cleaning_the_house"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_company.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_company.value} validationErrors={is_company.validationErrors} text={translateKey("form-text-arztbesuche")} transKey="form-text-arztbesuche" keyName="is_company_during_medical_visits"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_common_trips.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_common_trips.value} validationErrors={is_common_trips.validationErrors} text={translateKey("form-text-gemeinsame")} transKey="form-text-gemeinsame" keyName="is_common_trips"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_organization_of_free_time.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_organization_of_free_time.value} validationErrors={is_organization_of_free_time.validationErrors} text={translateKey("form-text-freizeitgestaltung")} transKey="form-text-freizeitgestaltung" keyName="is_organization_of_free_time"/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_driving.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_driving.value} validationErrors={is_driving.validationErrors} text={translateKey("form-text-schaltgetriebe")} transKey="form-text-schaltgetriebe" keyName={KEY_IS_DRIVING}/>
                                <CheckBoxComponent newUI={isNewUI} isImportant={isImportant&&is_driving_automat_gearbox.checkImportant} checkClassWrapper={classForCheckBox} showValidation={showValidationErrors} className={checboxClass} setTypeChangeToParent={this.setTypeChange} value={is_driving_automat_gearbox.value} validationErrors={is_driving_automat_gearbox.validationErrors} text={translateKey("form-text-automatikgetriebe")} transKey="form-text-automatikgetriebe" keyName={KEY_IS_DRIVING_AUTOMAT_GEARBOX}/>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section17));
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import SectionNavigationBarElement from './SectionNavigationBarElement'
import * as Relationship from '../../../_forms/BBForm/values/relationship';
import * as IsContactPersonDataSameAs from '../../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../../_forms/BBForm/values/sections';

class SectionNavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionList: this.props.sectionList,
            activeSection: this.props.activeSection
        };
    }

    changeSectionPage(number){
        this.props.changeSection(number);
    }

    render() {
        const { sectionList, sectionMax, translateKey, showSectionValidation, activeSection, rerender } = this.props;
        let mapedSectionList = '';
        if(sectionList){
            mapedSectionList = sectionList.sections.filter((element, index) => {
                if (element.key==="section-hidden") {
                    return false;
                }else{
                    return true;
                }
              }).map((element, index) => {
                    let isDisabled = false;
                    if(index===Sections.CONTACT_PERSON){
                        if (sectionList.getFieldByKey('is_contact_person_data_same_as').value === IsContactPersonDataSameAs.YES) {
                            isDisabled = true
                        }
                    }else{
                        if(index===Sections.PATIENT){
                            if(sectionList.getFieldByKey('is_degree_relationship_with_patient_carer').value === Relationship.TYPE_8){
                                isDisabled = true
                            }
                        }
                    }
                    return (
                      <SectionNavigationBarElement
                        rerender={rerender}
                        sectionMax={sectionMax}
                        isDisabled={isDisabled}
                        showSectionValidation={showSectionValidation}
                        key={"section_form_bb_" + index}
                        activeSection={activeSection}
                        isActive={index === activeSection ? true : false}
                        changeSection={(number) => this.changeSectionPage(number)}
                        sectionNumber={index}
                        validated={element.isValid}
                        sectionKey={element.key}
                        isSpecialOffer = {element.isSpecialOffer}
                        elementNumber={element.number}
                        sectionName={translateKey(element.sectionName)}
                      />
                    );
              })
        }
        return (
            <div className="sectionNavigationBarWrapper container">
                {mapedSectionList}
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionNavigationBar));

import axiosBackend from './config/axios-backend';

export const sendMeetingEmail = function (docs, content, email) {
    let dataS={
        "documents_to_send": docs,
        "content": content,
        "emailAddress": email
    }
    return axiosBackend.post('Api/sendMeetingMail', dataS)
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}

export const getIp = function () {
    return axiosBackend.get('Api/getMyIp')
        .then((data) => {
            return data;
        })
        .catch(error => {
            return error;
        });
}
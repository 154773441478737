import * as React from 'react';

export class RouteButtonComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            text: this.props.text,
        };
    }

    openInNewTab(url) {
        if(!this.props.disabled){
            var win = window.open(url, '_blank');
            win.focus();
        }
    }

    giveMeButton(){
        if(this.props.element.latitude!==0 && this.props.element.longitude!==0){
            return <span onClick={()=> this.openInNewTab("https://www.google.com/maps?q="+this.props.element.latitude+","+this.props.element.longitude)}><div className="btn btn-default btn-route-button"><i className="fas fa-map-marker-alt btn-route-icon"></i>{this.props.text}</div></span>;
        }else{
            let stringToSearch = ''
            if(this.props.element.city!==null){
                stringToSearch+=this.props.element.city;
                if(this.props.element.street!==null){
                    stringToSearch = stringToSearch+","+this.props.element.street;
                    if(this.props.element.buildingNumber!==null){
                        stringToSearch = stringToSearch+"+"+this.props.element.buildingNumber;
                    }
                }
            }else{
                if(this.props.element.street!==null){
                    stringToSearch += this.props.element.street;
                    if(this.props.element.buildingNumber!==null){
                        stringToSearch = stringToSearch+"+"+this.props.element.buildingNumber;
                    }
                }
            }
            return <span onClick={()=> this.openInNewTab("https://www.google.com/maps?q="+stringToSearch)}><div className="btn btn-default btn-route-button"><i className="fas fa-map-marker-alt btn-route-icon"></i>{this.props.text}</div></span>
        }
    }

    render() {
        return (this.giveMeButton());
    }
}
export class FormLoadedState{
    showValidationErrors;
    currentSection;
    savingData;
    modalLoader;
    prevPageNumber;
    rodoDisabled;
    constructor(showValidationErrors, currentSection, savingData, modalLoader, prevPageNumber, rodoDisabled){
        this.showValidationErrors = showValidationErrors;
        this.currentSection = currentSection;
        this.savingData = savingData;
        this.modalLoader = modalLoader;
        this.prevPageNumber = prevPageNumber;
        this.rodoDisabled = rodoDisabled;
    }
};
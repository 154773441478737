import * as React from 'react';

export default class CalendarCustomInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
        };
    }

    render() {
        const {value} = this.props;
        return (
            <div className="form-group no_margin">
                <input type="text" className="form-control newUI" value={value!==null||value!==''?value:' '} onChange={()=>{}} onClick={this.props.onClick}/>
                <i onClick={this.props.onClick} className="far fa-2x fa-calendar-alt cursor-pointer calendar-input-icon-right"></i>
            </div>
        )
    }
}
export const Consents = [
    {
        key: 'is_zg_de_1',
        value: null
    },
    {
        key: 'is_zg_de_2',
        value: null
    },
    {
        key: 'is_zg_de_3',
        value: null
    },
    {
        key: 'is_zg_de_4',
        value: null
    },
    {
        key: 'is_zg_de_5',
        value: null
    },
    {
        key: 'is_zg_de_6',
        value: null
    },
    {
        key: 'is_zg_de_7',
        value: null
    },
    {
        key: 'is_zg_de_8',
        value: null
    },
    {
        key: 'is_zg_uk_1',
        value: null
    }
]
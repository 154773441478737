import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import DatePickerComponent from "../../_components/date-picker-component/DatePickerComponent"

class FilterDateComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timestamp: this.props.timestamp
        };
    }

    handleDateChange(selectedDate){
        let timestamp = new Date(selectedDate);
        timestamp = new Date(selectedDate);
        timestamp.setUTCHours(0,0,0,0);
        timestamp = timestamp.getTime()/1000;
        this.props.onChangeDate(timestamp);
    }

    render() {
        return (
            <div className="form-group col-md-6">
                <label>{this.props.name}</label>
                <DatePickerComponent key="simpleTimeStamp01" timestamp={this.props.timestamp} withTime={false} withPortal={true} onDateChange={(e)=>this.handleDateChange(e)}/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(FilterDateComponent));
import * as React from 'react';

export class AlertMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            message: this.props.message
        };
    }

    render() {
        if(this.props.show){
            return (
                <div className={"alert alert-" + this.props.type +' '+(this.props.centeredAlert? "centeredAlert":"")} role="alert">
                    {this.props.message}
                </div>
            );
        }else{
            return null
        }

    }
}
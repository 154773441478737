import * as React from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBBtn, MDBModalFooter} from 'mdbreact';

export class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.show,
        };
    }

    accept = () => {
        this.props.acceptMe();
    }

    reject = () => {
        this.props.rejectMe();
    }

    other = () => {
        this.props.otherMe();
    }


    render() {
        const {titleMessage, mainMessage, messageYes, messageNo, otherMessage} = this.props;
        return (  
            <MDBModal isOpen={this.props.show} toggle={this.reject} fullHeight size="md">
                <MDBModalHeader toggle={this.reject}>{titleMessage}</MDBModalHeader>
                <MDBModalBody>
                    <div className="confirm-modal-main-message">
                        {mainMessage}
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="danger" onClick={this.accept}>{messageYes}</MDBBtn>
                    <MDBBtn color="primary" onClick={this.reject} >{messageNo}</MDBBtn>
                    {otherMessage && <MDBBtn color="info" onClick={this.other} >{otherMessage}</MDBBtn> }
                </MDBModalFooter>
            </MDBModal>
        )
    }
}
import * as React from 'react';
import * as Validation from '../../_forms/validation';
import * as FieldTypes from '../../_forms/fieldTypes';

export class MailToPhoneComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.phone
        };
    }

    generateMailTo(phone){
        if(phone){
            if(this.validatePhone(phone)){
                return <a href={"tel:"+phone}>{phone}</a>
            }else{
                return phone;
            }
        }else{
            return phone;
        }
    }

    validatePhone(phone){
        return Validation.fieldType(phone, FieldTypes.PHONE_NUMBER).isValid
    }

    render() {
        const aComponent = this.generateMailTo(this.props.phone);
        return (
            <span>{aComponent}</span>
         );
    }
}
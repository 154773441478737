import * as React from 'react';

export class ClientTablePatient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client: this.props.client,
            patientsList: this.props.patientsList,
            mainbranch: this.props.mainbranch,
            subbranch: this.props.subbranch
        }
    }

    render() {
        let text = null;
        for(let i=0; i<this.props.patientsList.length; i++){
            if(this.props.patientsList[i].clientId===this.props.client.id){
                if(this.props.subbranch){
                    text = this.props.patientsList[i][this.props.mainbranch] ? this.props.patientsList[i][this.props.mainbranch][this.props.subbranch] : ''
                }else{
                    text = this.props.patientsList[i][this.props.mainbranch];
                }
            }
        }
        return (
            <td className={"table-cell-wrapper " + (text !== null ? "got-data" : 'no-data')}>{text}</td>
        );
    }
}
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../_redux/actions/index";
import NavBar from "../../_components/navbar/NavBar";
import FooterComponent from "../../_components/footer/Footer";
import {IconsMap} from "../../_helpers/icons_set";
import styled from 'styled-components'
import { onLogout } from '../../_redux/actions/auth';
import { removeBasicDataSave } from '../../_redux/actions/enums';
const DivWrapper = styled.div`padding-bottom: ${props => props.last?0:"2em"};`;
const ContentWrapper = styled.div`
  max-width: 800px;
  display: block;
  margin: 0 auto;
  width: 100%;
`;

class AusLogenPage extends React.PureComponent {
  componentDidMount() {
    this.props.logMeOut();
    this.props.removeBasicDataSave();
  }
  render() {
    const { translateKey } = this.props;
    return (
      <div className="loginContentWrapper">
        <NavBar />
        <div className="section-slider" style={{ backgroundImage: `url(${IconsMap.other.background_zone})`, backgroundPosition: "top" }}>
          <div className="container">
            <div className="zone-slider-text">
              {translateKey("zone_main_text")}
            </div>
          </div>
        </div>
        <div className="container zone-content-wrapper">
          <div className="zone-content">
            <ContentWrapper>
              <div className="zone-conent-section-title">
                <div className="title">
                  {translateKey("zone-ausloggen")}
                </div>
                <div className="subsection">
                  <DivWrapper>{translateKey("kunde_auslogen_text")}</DivWrapper>
                  <DivWrapper>{translateKey("kunde_auslogen_text_vielen")}{translateKey("kunde_auslogen_text_ihre")}</DivWrapper>
                  <DivWrapper last>{translateKey("kunde_auslogen_text_prom")}</DivWrapper>
                </div>
              </div>
            </ContentWrapper>
          </div>
        </div>
        <FooterComponent/>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  logMeOut: ()=> dispatch(onLogout()),
  removeBasicDataSave: ()=> dispatch(removeBasicDataSave())
});
export default withRouter(connect(null,mapDispatchToProps)(AusLogenPage));
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onLogout } from '../../_redux/actions/auth';
import { removeBasicDataSave } from '../../_redux/actions/enums';
import { baseData } from '../../_constance/base_data';

class LogOutPage extends React.Component {
  componentDidMount() {
    this.props.logMeOut();
    this.props.removeBasicDataSave();
    this.props.history.push(baseData.subFolders);
  }
  render = () => null
}
const mapDispatchToProps = dispatch => ({
  logMeOut: ()=> dispatch(onLogout()),
  removeBasicDataSave: ()=> dispatch(removeBasicDataSave())
});
export default withRouter(connect(null, mapDispatchToProps)(LogOutPage));
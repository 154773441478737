import React from 'react';

export default class SelectOptionsComponent extends React.Component {
    static defaultProps = {
        valuesList: []
    };

    setTypeChange = event => {
        const { target: { value } } = event;
        const { keyName, setTypeChangeToParent } = this.props;
        setTypeChangeToParent(keyName, value);
    };

    render() {
        const { valuesList, value, mainText, disabled} = this.props;
        return (
            <div className="form-group row flexAlignCenter no-margin-row">
                <label className="col-sm-4 col-form-label form-label-basic">{mainText}</label>
                <div className="col-sm-8">
                    <select disabled={disabled} className="custom-select col-sm-10 lead-input-wrapper" onChange={this.setTypeChange} value={value}>
                        <option hidden>{this.props.chooseText}</option>
                        {valuesList.map((element, index) => {
                            let convertedValue = value;
                            if(convertedValue){
                                convertedValue.toString().toLowerCase()
                            }
                            if(element.Value.toString().toLowerCase() === convertedValue){
                                return <option defaultValue key={element.Value} value={element.Value}>{element.Label}</option>
                            }else{
                                return <option  key={element.Value} value={element.Value}>{element.Label}</option>
                            }
                        })}
                    </select>
                </div>
            </div>
        );
    }
}
import * as React from 'react';
import { IconsMap } from "../../_helpers/index";

export class TaskStatusHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            client: this.props.element
        }
    }

    render() {
        let myIcon = '';
        if(this.props.element.taskType==="Task" ){
            if(this.props.element.priority===2){
                myIcon = IconsMap.rest.tick_red;
            }else{
                myIcon = IconsMap.rest.tick_black;
            }
        }else{
            if(this.props.element.taskType==="Call" ){
                if(this.props.element.priority===2){
                    myIcon = IconsMap.svg.call_red;
                }else{
                    myIcon = IconsMap.svg.call_black;
                }
            }else{
                if(this.props.element.priority===2){
                    myIcon = IconsMap.svg.clock_red;
                }else{
                    myIcon = IconsMap.svg.clock_black;
                }
            }
        }
        return (
            <img className={"taskIcon"+ (this.props.element.taskType==="Event"? " eventTypeIcon": "")} src={myIcon} alt={"Icon"}/>
        );
    }
}
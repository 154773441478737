
import {AUTH_SUCCESS, AUTH_FAIL, AUTH_LOGOUT, AUTH_START, REFRESHING_TOKEN, REFRESHED_TOKEN, UPDATE_ACTION_TIMESTAMP, UPDATE_PATIENT_ID } from "../actions/action-types"

const initialState = {
  appZoneId: null,
  appZoneToken: null,
  appZoneName: null,
  appZoneSurname: null,
  appZonePatient: null,
  appZoneClientId: null,
  secondPatient: null,
  appToken: null,
  appZoneRest: null,
  actionTimestamp: null,
  error: null,
  loading: false,
  isRefreshingToken: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        appZoneId: action.appZoneId,
        appZoneToken: action.zoneToken,
        appZoneName: action.appZoneName,
        appZoneSurname: action.appZoneSurname,
        appZonePatient: action.appZonePatient,
        appZoneClientId: action.appZoneClientId,
        secondPatient: action.secondPatient,
        appToken: action.appToken,
        appZoneRest: action.appZoneRest,
        appUserId: action.appUserId,
        error: null,
        loading: false,
        isRefreshingToken: false,
      };
    case AUTH_START:
      return {
        ...state,
        loading: true,
        error: null,
        isRefreshingToken: false
      };
    case AUTH_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        isRefreshingToken: false
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        appZoneToken: null,
        appZoneName: null,
        appZoneSurname: null,
        appZonePatient: null,
        appZoneClientId: null,
        appToken: null,
        appZoneRest: null,
        isRefreshingToken: false,
      }
    case REFRESHING_TOKEN:
      return {
        ...state,
        isRefreshingToken: true
      }
    case REFRESHED_TOKEN:
      return {
        ...state,
        appToken: action.appToken,
        error: null,
        isRefreshingToken: false
      }
    case UPDATE_ACTION_TIMESTAMP:
      return {
        ...state,
        actionTimestamp: action.actionTimestamp
      }
    case UPDATE_PATIENT_ID:
      return {
        ...state,
        appZonePatient: action.patientID
      }
    default:
      return state;
  }
};

export default reducer;
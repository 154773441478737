import ReqHelper from '../../_helpers/request_helper';
import axiosAuth from '../../_services/config/axios-auth';

export const getUserProducts = function () {
  return () => {
      return axiosAuth.get('leads/list/products/0/DE')
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

export const getRegionsFP = function (lang='DE') {
  return () => {
      return axiosAuth.get(`leads/list/FPregions/0/${lang}`)
      .then(res => {
          return Promise.resolve(res.data);
      })
      .catch(error => {
          if(error.code){
            return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
          }else{
            return Promise.reject(ReqHelper.responseErrorHelper(801));
          }
      });
  }
}

import React, { useState } from "react";
import styled from 'styled-components';
import { InputTypeTextComponent } from '../form-bb-components/input-type-text-component/input-type-text-component';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .input-wrapper {
    width: 50%;
    padding-right: 15px;
    &:nth-child(2) {
      padding-left: 15px;
      padding-right: 0;
    }

    @media (max-width: 576px) {
      width: 100%;
      padding: 0;

      &:nth-child(2) {
        padding: 0;
      }
    }

    @media (max-width: 992px) {
      .react-tel-input {
        .form-control {
          width: 100%;
        }
      }
    }
  }
  .invalid-feedback {
    display: block;
  }
`;

const MultiplePhonesComponent = ({ phones, showValidation, errorMessage }) => {
  const [unrequiredField, setUnrequiredField] = useState('');
  const [unrequiredFieldValue, setUnrequiredFieldValue] = useState('');
  const [showErrorMesage, setShowErrorMesage] = useState(false);

  const phonesError = phones.map(item => {
    if (item.field.value.length < 10) {
      return true;
    } else {
      return false;
    }
  });
  if (phonesError.every(el => (el === true))) {
    if(showErrorMesage === false) {
      setShowErrorMesage(true);
    }
    phones.map(item => {
      item.field.isRequired = false;
    });
  } else if (phonesError.every(el => (el === false))) {
    if(showErrorMesage === true) {
      showErrorMesage = false;
    }
    phones.map(item => {
      item.field.isRequired = false;
    });
  } else {
    const indexTrue = phonesError.indexOf(true);
    const indexFalse = phonesError.indexOf(false);
    phones[indexFalse].field.isRequired = true;
    if(showErrorMesage === true) {
      setShowErrorMesage(false);
    }
    if(phones[indexTrue].field.key !== unrequiredField) {
      setUnrequiredField(phones[indexTrue].field.key);
      setUnrequiredFieldValue(phones[indexTrue].field.value.substring(0,3));
    }
    if(phones[indexTrue].field.value !== unrequiredFieldValue && phones[indexTrue].field.value.length > 3){
      phones[indexTrue].field.isRequired = false;
    } else {
      phones[indexTrue].field.isRequired = false;
    }
  }

  return (
    <Wrapper>
    { phones.map((item, index) => {
      return <InputTypeTextComponent 
        key={index} 
        newUI={item.newUI} 
        showValidation={item.showValidation} 
        className={item.className}
        setTypeChangeToParent={(key, value) => item.setTypeChangeToParent(key, value)} 
        field={item.field} 
        text={item.text} 
        transKey={item.transKey} 
        keyName={item.keyName}
        hideErrorRequired={true}
      />
    })
    }
    {
      (showErrorMesage && showValidation) ? 
      <div className="invalid-feedback">
        { errorMessage }
      </div>
      : null
    }
    </Wrapper>
  )
}
export default MultiplePhonesComponent

import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translateKey } from "../../_redux/actions/index";
import { authZone } from "../../_redux/actions/auth";
import Validate from "../../_helpers/validation";
import { SimpleLoader } from "../../_components/index";
import NavBar from "../../_components/navbar/NavBar";
import FooterComponent from "../../_components/footer/Footer";
import {IconsMap} from "../../_helpers/icons_set";
import {Base64} from "../../_helpers/base64Coder";
import ReqHelper from "../../_helpers/request_helper"

class LoginPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      controls: {
        email: {
          placehoder: '',
          value:'',
          valid:false,
          validationRules: {
            isEmail: true
          }
        },
        password: {
          placehoder: '',
          value:'',
          valid:false,
          validationRules: {
            minLength: 6,
            maxLength: 50
          }
        }
      },
      submitted: false,
      errorsMessage: null,
      makingCall: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let urlQuery = new URLSearchParams(this.props.location.search);
    const getEmail = urlQuery.get('mail')
    if(getEmail){
      const decodeEmail = Base64.decode(getEmail);
      if(ReqHelper.isEmail(decodeEmail)){
        if (this._isMounted) {
          this.setState(prevState => {
            return {
              controls: {
                ...prevState.controls,
                email: {
                  ...prevState.controls.email,
                  value: decodeEmail,
                  valid: Validate(decodeEmail, prevState.controls.email.validationRules)
                }
              }
            };
          });
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (this._isMounted) {
      this.setState(prevState => {
        return {
          controls: {
            ...prevState.controls,
            [name]: {
              ...prevState.controls[name],
              value: value,
              valid: Validate(value, prevState.controls[name].validationRules)
            }
          }
        };
      });
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    if (this._isMounted) {
      this.setState({ submitted: true });
    }
    if (this.state.controls.email.valid === true && this.state.controls.password.valid === true) {
      if (this._isMounted) {
        this.setState({ makingCall: true, errorsMessage:null })
      }
      const { controls } = this.state;
      let credData = {
        login: controls.email.value,
        password: controls.password.value
        //rsapassword: ReqHelper.rsaPasswordMethod(controls.password.value)
      };

      this.props.authLogin(credData.login, credData.password)
        .catch((err)=>{
            if(err.code===801){
              if (this._isMounted) {
                this.setState({
                  errorsMessage: "error_login_failed",
                  makingCall:false
                })
              }
            }else{
              if (this._isMounted) {
                this.setState({
                  errorsMessage: err.message,
                  makingCall: false
                })
              }
            }
          }
        )
    }
  }

  render() {
    const { translateKey } = this.props;
    const { controls, submitted, errorsMessage, makingCall } = this.state;
    return (
      <div className="loginContentWrapper">
        <NavBar />
        <div className="section-slider" style={{ backgroundImage: `url(${IconsMap.other.background_zone})`, backgroundPosition: "top" }}>
          <div className="container">
            <div className="zone-slider-text">
              {translateKey("zone_main_text")}
            </div>
          </div>
        </div>
        <div className="container zone-content-wrapper">
          <div className="zone-content">
            <div className="zone-content-login">
              {/* <div className="zone-conent-section-title">
                <div className="subsection">
                  <div>
                    {translateKey("maintenance_work_message_first_line")}<br /><br />
                    {translateKey("maintenance_work_message_second_line")}<br /><br />
                    {translateKey("maintenance_work_message_third_line")}
                  </div>
                </div>
              </div> */}
              <div className="zone-conent-section-title">
                <div className="title">
                  {translateKey("zone-text-anmeldung")}
                </div>
                <div className="subsection">
                  <div>{translateKey("zone-text-willkomen")}</div>
                  <div>{translateKey("zone-text-bitte-melden")}</div>
                </div>
              </div>
              <form name="form" onSubmit={this.handleSubmit} className="form-login-zone-wrapper">
                <div className="input-group login-input-main-wrapper">
                  <div className="input-group-prepend">
                    <div className="input-group-text pre-input-width">{translateKey("zone-e-mail")}</div>
                  </div>
                  <input id="username-input-field" type="email" className={"form-control newUI" + (submitted && !controls.email.valid ? " is-invalid" : "")}
                    placeholder={''}
                    name="email"
                    value={controls.email.value}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                {submitted && !controls.email.valid && (
                  <div className="help-block text-danger">
                    {translateKey("login_user_please_enter")}
                  </div>
                )}
                <div className="input-group login-input-main-wrapper">
                  <div className="input-group-prepend">
                    <div className="input-group-text pre-input-width">{translateKey("login_password")}</div>
                  </div>
                  <input id="username-input-field" type="password" className={"form-control newUI  " + (submitted && !controls.password.valid ? " is-invalid" : "")}
                    placeholder={''}
                    name="password"
                    value={controls.password.value}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                {submitted && !controls.password.valid && (
                  <div className="help-block text-danger">
                    {translateKey("login_password_please_enter")}
                  </div>
                )}
                <div className="form-group login-input-main-wrapper">
                  <button 
                      type="button"
                      className="btn btn-primary cursor-pointer login-button-zone prev-next-active-button" 
                      onClick={this.handleSubmit}>
                        <div className="section-navigation-button-text">{translateKey("login_login").toUpperCase()}</div>
                        <i className="fas fa-chevron-right fa-1x section-navigation-icon"></i>
                  </button>
                </div>
                {submitted &&  errorsMessage && (
                  <div className="help-block text-danger text-center">{translateKey(errorsMessage)}</div>
                )}
                {makingCall && (
                  <SimpleLoader />
                )}
              </form>
            </div>
          </div>
        </div>
        <FooterComponent/>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  authLogin: (login, password) => dispatch(authZone(login, password))
});
export default withRouter(connect(null,mapDispatchToProps)(LoginPage));
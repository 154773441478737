import * as React from 'react';
import { MDBModal, MDBModalBody, MDBModalFooter} from 'mdbreact';
import InputTypeComponent from "../input-type-component/input-type-component";

export class MultiPatientModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.show,
            newPatientName: '',
            newPatientLastName: '',
            showValidationForName: false,
            showValidationForLastName: false,
        };
    }

    acceptWithValidation = () => {
        const { newPatientName, newPatientLastName } = this.state;
        
        if(newPatientName !== '' && newPatientLastName !== '') {
            this.props.accept(newPatientName, newPatientLastName);
        } 
        if (newPatientName === '') {
            this.setState({
                showValidationForName: true,
            });
        } else {
            this.setState({
                showValidationForName: false,
            });
        }
        if (newPatientLastName === '') {
            this.setState({
                showValidationForLastName: true,
            });
        } else {
            this.setState({
                showValidationForLastName: false,
            });
        }

    }

    setTypeChange = (key, value) => {
        this.setState({
            [key]: value,
        });
    }

    render() {
        const { cancel, translateKey, redirect, showRedirection, secondPatientName } = this.props;
        const { newPatientName, newPatientLastName, showValidation, showValidationForName, showValidationForLastName  } = this.state;
        return (  
            <MDBModal isOpen={this.props.show} toggle={this.reject} fullHeight size="lg">
                {
                    showRedirection ? 
                    <>
                        <MDBModalBody>
                        { translateKey('multiple_patients_popup_text_fill_data') } {secondPatientName} { translateKey('multiple_patients_popup_text_fill_data1') }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" className="btn btn-danger" onClick={cancel}>{translateKey('back')}</button>
                            <button type="button" className="btn btn-primary" onClick={() => redirect()}>{translateKey('next')}</button>
                        </MDBModalFooter>
                    </>
                    : <>
                        <MDBModalBody>
                            <p className="multipatient__text">
                                { translateKey('multiple_patients_popup_text') }
                            </p>
                            <div className="multipatient__inputs-holder">
                                <div className="inputs-holder__item">
                                    <InputTypeComponent 
                                        disabled={false} 
                                        keyName={"newPatientName"} 
                                        value={this.state.newPatientName} 
                                        type={'text'} 
                                        showValidation={showValidation} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        text={translateKey("first_name")}
                                    />
                                    { showValidationForName &&
                                        <div className="invalid-feedback active">
                                            <div>{ translateKey(`form-text-err_text_min_length`) }</div>
                                        </div>
                                    }
                                </div>
                                <div className="inputs-holder__item">
                                    <InputTypeComponent
                                        disabled={false} 
                                        keyName={"newPatientLastName"} 
                                        value={this.state.newPatientLastName} 
                                        type={'text'} 
                                        showValidation={showValidation} 
                                        setTypeChangeToParent={this.setTypeChange} 
                                        text={translateKey("last_name")}
                                    />
                                    { showValidationForLastName &&
                                        <div className="invalid-feedback active">
                                            <div>{ translateKey(`form-text-err_text_min_length`) }</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" className="btn btn-danger" onClick={cancel}>{translateKey('back')}</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.acceptWithValidation(newPatientName, newPatientLastName)}>{translateKey('next')}</button>
                        </MDBModalFooter>
                    </>
                }
            </MDBModal>
        )
    }
}
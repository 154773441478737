import * as React from 'react';

class SectionNavigationBarElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionNumber: props.sectionNumber
        };
    }

    changeMeSectionIfYouCan = () =>{
        const {sectionNumber} = this.props;
        this.props.changeSection(sectionNumber)
    }

    render() {
        const {isDisabled, showSectionValidation, activeSection, isActive, sectionNumber, sectionName, sectionMax, isSpecialOffer, elementNumber} = this.props;
        return (
            <div className={"section-navigation-wrapper"+ (isDisabled===true ? ' hideMe' : '')}>
                <div onClick={this.changeMeSectionIfYouCan} className={
                    `cursor-pointer section-navigation-tile 
                    ${showSectionValidation === true && activeSection>sectionNumber? 'validated': isActive===true? 'active': ''}
                    ${isSpecialOffer ? 'specialOffer': ''}
                    `
                }>
                    <div className="navigation-section-text">
                        <span className={`section-number-in-new-navigation-menu ${isActive===true ? ' section-navigation-text-bold' : ''}`}>
                            {isSpecialOffer?
                                <i className="far fa-eye fa-2x section-navigation-icon special-offer-section-icon"></i>
                                :
                                elementNumber+'.'
                            }
                        </span>
                        <span className={`section-text-in-new-navigation-menu 
                            ${isActive===true ? ' section-navigation-text-bold' : ''}
                            ${isSpecialOffer? ' section-special-offer-text' : ''}
                        `}>{sectionName}</span>
                    </div>
                </div>
                {sectionNumber !== sectionMax ?
                    <React.Fragment>
                        <i className="fas fa-chevron-right fa-2x section-navigation-icon right"></i>
                        <i className="fas fa-chevron-down fa-2x section-navigation-icon down"></i>
                    </React.Fragment>
                    :
                    <i className="fas fa-chevron-right fa-2x section-navigation-icon visible-none"></i>
                }
            </div>
        );
    }
}
export default SectionNavigationBarElement;
import * as React from 'react';
import Autosuggest from 'react-autosuggest';
const getSuggestionValue = suggestion => suggestion.value;
const renderSuggestion = suggestion => (<span>{suggestion.value} - {suggestion.city}</span>);
export class AutoSuggestComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            suggestions:this.props.suggestions
          };
    }

    onChange = (event, { newValue }) => {
        this.props.onChange(newValue);
        this.setState({
          value: newValue
        });
    };

    onSuggestionSelected = (event, { suggestion }) => {
        this.props.onChange(suggestion);
        this.setState({value: suggestion.value});
    };

    getSuggestions = value => {      
        if(value.length<4){
            return [];
        }else{
            let NewArray = [];
            for (var i = 0; i < this.props.suggestions.length; i++) {
                if(this.props.suggestions[i].value.indexOf(value) !== -1){
                    NewArray.push(this.props.suggestions[i]);
                }
            }
            return NewArray;
        }
    };
    
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({suggestions: this.getSuggestions(value)});
    };
    
    onSuggestionsClearRequested = () => {
        this.setState({suggestions: []});
    };

    render() {
        const {value} = this.props;
        const inputProps = {
          value: value?value:"",
          onChange: this.onChange
        };
        return (
            <Autosuggest
                disabled = {true}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={this.onSuggestionSelected}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}
export const SECTION_LIST = [
  { pageNumber:0,name: "rodo_section_1" },
  { pageNumber:1, name: "rodo_section_2" },
  { pageNumber:2, name: "angaben_zur_kontaktperson" },
  { pageNumber:3, name: "angaben_zur_kontaktperson_vor_ort" },
  { pageNumber:4, name: "angaben_zur_zu_betreuenden_person" },
  { pageNumber:5, name: "angaben_zur_pflegebediurftigkeit" },
  { pageNumber:6, name: "krankheitsbilder" },
  { pageNumber:7, name: "kommunikation" },
  { pageNumber:8, name: "orientierung" },
  { pageNumber:9, name: "sich_bewegen" },
  { pageNumber:10, name: "pflegedienst" },
  { pageNumber:11, name: "angaben_und_aufgaben_zur_betreuungskraft" },
  { pageNumber:12, name: "angaben_zur_tatigkeit_der_betreuungskraft" },
  { pageNumber:13, name: "beschreibung_der_unterkunft" },
  { pageNumber:14, name: "Erstangebot" }
]

export const getSectionNumberByName = (name) => {
  let elementNeeded = SECTION_LIST.find(element=>element.name===name)
  if(elementNeeded){
    return elementNeeded.pageNumber;
  }else{
    return 3;
  }
}

export const getSectionNameByNumber = (sectionNumber) => {
  let elementNeeded = SECTION_LIST.find(element=>element.pageNumber===sectionNumber)
  if(elementNeeded){
    return elementNeeded.name;
  }else{
    return 'Erstangebot';
  }
}
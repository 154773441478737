import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import SignatureCanvas from 'react-signature-canvas'

class RodoSectionSignature extends React.Component {
    sigPad = {}
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-0'),
            showCanvas: false,
            baseCanvas: null,
        };
    }
    
    componentWillUnmount() {
        this.setState({
            showCanvas: false
        })
    };

    clear = () => {
        this.sigPad.clear()
    }

    saveData=()=>{
        if(!this.props.disabled && !this.props.rodoDisabled){
            this.setState({
                showCanvas: false,
                baseCanvas: this.sigPad.getTrimmedCanvas().toDataURL('image/png')
            })
        }
    }

    showMeCanvas(){
        if(!this.props.disabled && !this.props.rodoDisabled){
            this.setState({showCanvas: true})
        }
    }

    cancelCanvas=()=>{
        if(!this.props.disabled && !this.props.rodoDisabled){
            this.setState({showCanvas: false})
        }
    }

    getMeContent(){
        if (this.state.showCanvas) {
            const { translateKey } = this.props;
            return (
                <React.Fragment>
                    <div className="rodo-class-signature-wrapper">
                        <SignatureCanvas 
                            penColor='black'
                            canvasProps={{width: 800, height: 400, className: 'sigCanvas'}} 
                            backgroundColor="white"
                            ref={(ref) => { this.sigPad = ref }}
                        />
                    </div>
                    <div className="rodo-class-signature-close-buttons">
                        <button 
                            type="button" 
                            className="btn btn-success cursor-pointer text-align-center"
                            onClick={()=>this.saveData()}
                        >
                            {translateKey("save")}
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-info cursor-pointer text-align-center"
                            onClick={()=> this.clear()}
                        >
                            {translateKey("clear")}
                        </button>
                        <button 
                            type="button" 
                            className="btn btn-primary cursor-pointer text-align-center"
                            onClick={()=>this.cancelCanvas()}
                        >
                            {translateKey("cancel")}
                        </button>
                    </div>
                </React.Fragment>
            )
        } else {
            const { disabled, subsectionName, translateKey, rodoDisabled } = this.props;
            const { baseCanvas } = this.state;
            return (
                <React.Fragment>
                    <div className="new-section-name-wrapper container">
                        {subsectionName}
                    </div>
                    <form className="new-section-content-wrapper container form_serializer">
                        <fieldset disabled={disabled || rodoDisabled}>
                            <div className="newUI-section-padding">
                                <div className="row">
                                    <div className="col-sm-12 rodo-description">
                                        <div className="rodo-signature-subtitle">
                                            {translateKey('form-rodo-signature-text-paragraph1-part2')}
                                        </div>
                                        <div className="flex-all-class">
                                            <div className={`rodo-signature-base-canvas ${disabled?'':'curosr-pointer'}`} onClick={()=>this.showMeCanvas()}>
                                                {baseCanvas && 
                                                    <img src={baseCanvas} alt="signature" className="signature-base-image"/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </React.Fragment>
            )
        }
    }

    render() {
        let content = this.getMeContent();
        return (
            <div className="form-section-main">
                {content}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(RodoSectionSignature));
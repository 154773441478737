import * as Relationship from "../_forms/BBForm/values/relationship";
import { IconsMap } from "../_helpers/index";

export function RodoHtmlHelper(form, translateKey ) {
    return `
        <div class="rodo-pdf-wrapper">
            ${generateFirstPage(form, translateKey)}
            ${generateSecondPage(form, translateKey)}
        </div>`;
}
 
function generateFirstPage(form, translateKey){
    const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
    let isRodoFirstChoice = Relationship.TYPE_0;
    if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
        isRodoFirstChoice = Relationship.TYPE_8
    }
    return `
        <div class="form-section-main">
            <div class="new-section-content-wrapper container form_serializer">
                <table>
                    <tr>
                        <th width="100%">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('form-rodo1-text-akceptieren')}
                            </div>
                        </th>
                    </tr>
                </table>

                <table style="width:700px; margin:0 auto;">
                    <tr>
                        <th width="50%">
                            <div class="rodo-content-wrapper-item ${isRodoFirstChoice===Relationship.TYPE_8?'active':''}">
                                <div class="icon">
                                    <img class="pflegegrad-icon" src="${isRodoFirstChoice===Relationship.TYPE_8?IconsMap.other.rodo_human_white:IconsMap.other.rodo_human_gray}" alt="Icon"/>
                                </div>
                                <div class="text">
                                    ${translateKey('form-rodo1-text-option1')}
                                </div>
                            </div>
                        </th>
                        <th width="50%">
                            <div class="rodo-content-wrapper-item ${isRodoFirstChoice===Relationship.TYPE_0?'active':''}">
                                <div class="icon">
                                    <img class="pflegegrad-icon" src="${isRodoFirstChoice===Relationship.TYPE_0?IconsMap.other.rodo_human_chair_white:IconsMap.other.rodo_human_chair_gray}" alt="Icon"/>
                                </div>
                                <div class="text">
                                    ${translateKey('form-rodo1-text-option2')}
                                </div>
                            </div>
                        </th>
                    </tr>
                </table>
                <table style="margin:0 auto;">
                    <tr>
                        <div class="columnfull rodo-description">
                            <div class="rodo-section">
                                <div>${translateKey('form-rodo1-text-paragraph1')}</div>
                                <div>- ${translateKey('form-rodo1-text-paragraph2')}</div>
                                <div>- ${translateKey('form-rodo1-text-paragraph3')}</div>
                            </div>
                            <ul class="rodo-itme-list">
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph4')}
                                    <a href="${translateKey('form-rodo1-text-page')}" target="_top">${' ' + translateKey('form-rodo1-text-page')}</a>
                                </li>
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph5')}
                                    <a href="mailto: ${translateKey('form-rodo1-text-email')}" target="_top">${' ' + translateKey('form-rodo1-text-email')}</a>
                                </li>
                                <li>${translateKey('form-rodo1-text-paragraph6')}</li>
                                <li>${translateKey('form-rodo1-text-paragraph7')}</li>
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph8')}
                                    <ul>
                                        <li>${translateKey('form-rodo1-text-paragraph8-option1')}</li>
                                        <li>${translateKey('form-rodo1-text-paragraph8-option2')}</li>
                                        <li>${translateKey('form-rodo1-text-paragraph8-option3')}</li>
                                    </ul>
                                </li>
                                <li>${translateKey('form-rodo1-text-paragraph9')}</li>
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph10')}
                                    <ul>
                                        <li>${translateKey('form-rodo1-text-paragraph10-option1')}</li>
                                        <li>${translateKey('form-rodo1-text-paragraph10-option2')}</li>
                                    </ul>
                                </li>
                                <li>
                                    ${translateKey('form-rodo1-text-paragraph11')}
                                    <ul>
                                        <li>${translateKey('form-rodo1-text-paragraph11-option1')}</li>
                                        <li>
                                            ${translateKey('form-rodo1-text-paragraph11-option2-part1')}
                                            <a href="mailto: ${translateKey('form-rodo1-text-email')}" target="_top">${' ' + translateKey('form-rodo1-text-email')} </a>
                                            ${translateKey('form-rodo1-text-paragraph11-option2-part2')}
                                            <a href="tel: ${translateKey('form-rodo1-text-phone')}" target="_top">${' ' + translateKey('form-rodo1-text-phone')} </a>
                                            ${translateKey('form-rodo1-text-paragraph11-option2-part3')}
                                        </li>
                                        <li>${translateKey('form-rodo1-text-paragraph11-option3')}</li>
                                    </ul>
                                </li>
                                <li>${translateKey('form-rodo1-text-paragraph12')} </li>
                            </ul>
                        </div>
                    </tr>
                </table>
            </div>
        </div>
    `;
}

function generateSecondPage(form, translateKey){
    const isDegreeRelationship = 'is_degree_relationship_with_patient_carer';
    const isDegreeRelationShipWithPatientCarer = form.getFieldByKey(isDegreeRelationship);
    let showLeft = false;
    if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
        showLeft = true;
    }
    //RODO1
    const einwilligungZurUnterbeitung = form.getFieldByKey("einwilligung_zur_unterbeitung");
    //RODO2
    const isZgDe2 = form.getFieldByKey("is_zg_de_2");
    const isZgDe3 = form.getFieldByKey("is_zg_de_2");
    const isZgDe4 = form.getFieldByKey("is_zg_de_4");
    const isZgDe5 = form.getFieldByKey("is_zg_de_5");
    const isZgDe6 = form.getFieldByKey("is_zg_de_6");
    const isZgDe7 = form.getFieldByKey("is_zg_de_7");
    const isZgDe8 = form.getFieldByKey("is_zg_de_8");

    let einwilligungInDieVerarbeitung = false;
    if(isZgDe2.value !==null && isZgDe2.value!==0){einwilligungInDieVerarbeitung = true;}

    let dieWerwendentWerden = false;
    if(isZgDe3.value !==null && isZgDe3.value!==0){dieWerwendentWerden = true;}

    let marketingEinwilligungenPostYes = false;
    if(isZgDe4.value !==null && isZgDe4.value!==0){marketingEinwilligungenPostYes = true;}

    let marketingEinwilligungenTelephoneYes = false;
    if(isZgDe5.value !==null && isZgDe5.value!==0){marketingEinwilligungenTelephoneYes = true;}

    let einwilligungenDieDatenTelephoneYes = false;
    if(isZgDe6.value !==null && isZgDe6.value!==0){einwilligungenDieDatenTelephoneYes = true;}

    let einwilligungenDieDatenPostYes = false;
    if(isZgDe7.value !==null && isZgDe7.value!==0){einwilligungenDieDatenPostYes = true;}

    let einwilligungenDieDatenEmailYes = false;
    if(isZgDe8.value !==null && isZgDe8.value!==0){einwilligungenDieDatenEmailYes = true;}

    const isPatientFirstName = form.getFieldByKey("is_patient_first_name");
    const isPatientLastName = form.getFieldByKey("is_patient_last_name");

    let fullNameDynamic = '';
    if(isPatientFirstName.value){
        fullNameDynamic+=isPatientFirstName.value;
        if(isPatientLastName.value){
            fullNameDynamic+= ' '+isPatientLastName.value;
        }
    }else{
        if(isPatientLastName.value){
            fullNameDynamic+= isPatientLastName.value;
        }
    }
    return `
        <div class="form-section-main page_break">
            <div class="new-section-content-wrapper container form_serializer">
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull">
                            <div class="new-section-name-wrapper container">
                                ${translateKey('form-rodo1-text-akceptieren')}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="newUI-section-padding">
                    <div class="row">
                        <div class="columnfull rodo-description">
                            ${showLeft?
                                `<div class="rodo-section">
                                    <div>
                                        ${translateKey('form-rodo2-text-paragraph1-part1')}
                                        <a href="mailto: ${translateKey('form-rodo1-text-email')}" target="_top">${' ' + translateKey('form-rodo1-text-email')} </a>
                                        ${translateKey('form-rodo2-text-paragraph1-part2')}
                                        <a href="tel: ${translateKey('form-rodo1-text-phone')}"" target="_top">${' ' + translateKey('form-rodo1-text-phone')} </a>
                                        ${translateKey('form-rodo2-text-paragraph1-part3')}
                                    </div>
                                </div>
                                <div class="rodo-section">
                                    <div class="title">
                                        ${translateKey('form-rodo2-text-paragraph2').toUpperCase()}
                                    </div>
                                    <div class="row justify-content-md-center">
                                        ${getMeCheckBox('col-sm-12 col-lg-12', einwilligungZurUnterbeitung.value, translateKey("form-rodo2-text-paragraph3"), 'einwilligung_zur_unterbeitung')}
                                    </div>
                                </div>`

                                :

                                `<div class="rodo-section">
                                    <div>
                                        ${translateKey('form-rodo3-text-paragraph1-part1')}
                                        <a href="mailto: ${translateKey('form-rodo1-text-email')}" target="_top">${' ' + translateKey('form-rodo1-text-email')} </a>
                                        ${translateKey('form-rodo3-text-paragraph1-part2')}
                                        <a href="tel: ${translateKey('form-rodo1-text-phone')}" target="_top">${' ' + translateKey('form-rodo1-text-phone')} </a>
                                        ${translateKey('form-rodo3-text-paragraph1-part3')}
                                    </div>
                                </div>

                                <div class="rodo-section">
                                    <div class="title">
                                        ${translateKey('form-rodo3-text-paragraph2').toUpperCase()}
                                    </div>
                                    <div class="row justify-content-md-center">
                                        ${getMeCheckBox('col-sm-12 col-lg-12', einwilligungInDieVerarbeitung, translateKey("form-rodo3-text-paragraph7"), 'einwilligung_in_die_verarbeitung')}
                                    </div>
                                    <div class'row justify-content-md-center no-pointer-events'>
                                        <div class="input-with-rodo-fullname-wrapper">
                                            <input type="text" class'form-control newUI input-with-rodo-fullname' value='${fullNameDynamic}'/>
                                        </div>
                                    </div>
                                    <div class="rodo-subsection-text-wrapper with-padd">
                                        ${translateKey('form-rodo3-text-paragraph4')}
                                    </div>
                                    <div class="rodo-subsection-text-wrapper">
                                        ${translateKey('form-rodo3-text-paragraph5')}
                                    </div>
                                </div>

                                <div class="rodo-section">
                                    <div class="title">
                                        ${translateKey('form-rodo3-text-paragraph6').toUpperCase()}
                                    </div>
                                    <div class="row justify-content-md-center">
                                        ${getMeCheckBox('col-sm-12 col-lg-12', dieWerwendentWerden, translateKey("form-rodo3-text-paragraph7"), 'die_verwendent_werden')}
                                    </div>
                                </div>`
                            }





                            <div class="rodo-section">
                                <div class="title">
                                    ${translateKey('form-rodo2-text-marketing-einwilligungen').toUpperCase()}
                                </div>
                                <div class="rodo-options-wrapper-subtitle">
                                    ${translateKey('form-rodo2-text-paragraph5')}
                                </div>

                                <table style="width:700px;margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-elektro-email')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', marketingEinwilligungenPostYes, translateKey("form-rodo2-text-paragraph5-option-yes"), 'marketing_einwilligungen_post_no')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !marketingEinwilligungenPostYes, translateKey("form-rodo2-text-paragraph5-option-no"), 'marketing_einwilligungen_post_no')}
                                        </th>
                                    </tr>
                                </table>
                                
                                <table style="width:700px;margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-phone')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', marketingEinwilligungenTelephoneYes, translateKey("form-rodo2-text-paragraph5-option-yes"), 'marketing_einwilligungen_telephone_yes')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !marketingEinwilligungenTelephoneYes, translateKey("form-rodo2-text-paragraph5-option-no"), 'marketing_einwilligungen_telephone_no')}
                                        </th>
                                    </tr>
                                </table>
                            </div>

                            <div class="rodo-section">
                                <div class="title">
                                    ${translateKey('form-rodo2-text-einwilligung').toUpperCase()}
                                </div>
                                <div class="rodo-options-wrapper-subtitle">
                                    ${translateKey('form-rodo2-text-paragraph7')}
                                </div>


                                <table style="width:700px;margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-email')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', einwilligungenDieDatenEmailYes, translateKey("form-rodo2-text-paragraph7-option-yes"), 'einwilligungen_die_daten_email_no')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !einwilligungenDieDatenEmailYes, translateKey("form-rodo2-text-paragraph7-option-no"), 'einwilligungen_die_daten_email_no')}
                                        </th>
                                    </tr>
                                </table>

                                <table style="width:700px;margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-phone')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', einwilligungenDieDatenTelephoneYes, translateKey("form-rodo2-text-paragraph7-option-yes"), 'einwilligungen_die_daten_telephone_yes')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !einwilligungenDieDatenTelephoneYes, translateKey("form-rodo2-text-paragraph7-option-no"), 'einwilligungen_die_daten_telephone_no')}
                                        </th>
                                    </tr>
                                </table>

                                <table style="width:700px; margin:0 auto;">
                                    <tr>
                                        <th width="30%">
                                            <span class="rodo-options-span-padding">${translateKey('form-rodo2-text-title-per-post')}</span>
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', einwilligungenDieDatenPostYes, translateKey("form-rodo2-text-paragraph7-option-yes"), 'einwilligungen_die_daten_post_yes')}
                                        </th>
                                        <th width="35%">
                                            ${getMeCheckBox('', !einwilligungenDieDatenPostYes, translateKey("form-rodo2-text-paragraph7-option-no"), 'einwilligungen_die_daten_post_no')}
                                        </th>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;
}


function getMeCheckBox(className, value, text, transKey){
    return `
        <div class="${className}">
            <div class="checkbox-button-group-wrapper">
                <div class="custom-control custom-checkbox custom-control-inline newUI-row">
                    <input class="form-check-input" ${value===true?"checked":''} type="checkbox" name="${transKey}" id="${transKey}"/>
                    ${text && 
                        `<label class'form-check-label newUI-text-color' htmlFor="${transKey}">
                            <div style="padding-left:30px;padding-top:10px">
                                ${text}
                            </div>
                        </label>`
                    }
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    `;
}
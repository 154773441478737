import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class LeadSourceStatusComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            source: this.props.source,
            ready:false
        };
    }

    getStatusText(source){
        const status = this.props.leadSource.filter(element => element.Value.toUpperCase()===source);
        if(status.length===0){
            return '';
        }else{
            return status[0].Label;
        }
    }

    render() {
        return (
            <span>{this.getStatusText(this.props.source)}</span>
        );
    }
}
const mapStateToProps = state => {return {
    leadSource: state.enums.leadSource,
    leadSourceStatus: state.enums.leadSourceStatus
}};
export default withRouter(connect(mapStateToProps, null)(LeadSourceStatusComponent));
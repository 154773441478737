import React from 'react';
import DatePickerComponent from "../../date-picker-component/DatePickerComponent";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";
import { Asterisk } from "../../../_helpers/field_asterix";

export default class WrappedDatePickerComponent extends React.Component {
    handleDueDayChange = event => {
        const { setTypeChangeToParent, keyName } = this.props;
        let timestamp = new Date(event).getTime();
        timestamp = Math.floor(timestamp / 1000);
        setTypeChangeToParent(keyName, timestamp);
    };

    render() {
        const {
          value,
          validationErrors,
          showValidation,
          keyName,
          text,
          withTime,
          withYear,
          disabled,
          isClearable,
          placeholderText,
          inline,
          className,
          newUI,
          labelClass,
          dataPickerClass,
          withMin,
          withMax,
          birthDay,
          field = {}
        } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;
        let openToDate = '';
        if(birthDay){
            if(!value){
                openToDate = new Date();
                openToDate.setUTCHours(0,0,0,0);
                openToDate.setFullYear( openToDate.getFullYear() - 60 );
                openToDate = openToDate.getTime();
            }
        }

        return (
            <div className={`${className} wrapped-date-picker-component ${showErrors ? 'is-invalid' : ''}`}>
                <label className={`form-label-basic ${newUI? 'newUI':''} ${labelClass?labelClass:''}`}>
                    <span className="quick-label-position-fix">
                        {text?text.toUpperCase():''} <Asterisk isRequired={field.isRequired}/>
                    </span>
                </label>
                <DatePickerComponent 
                    key={keyName} 
                    data-field-name={keyName}
                    className={`display-block ${newUI? 'newUI':''} ${showErrors ? 'is-invalid' : ''} ${dataPickerClass ? dataPickerClass : ''}`}
                    timestamp={value}
                    withTime={withTime}
                    withYear={withYear}
                    withMin={withMin}
                    withMax={withMax}
                    disabled={disabled}
                    onDateChange={this.handleDueDayChange}
                    inline={inline}
                    isClearable={isClearable}
                    placeholderText={placeholderText} 
                    openToDate={openToDate}
                />
                { showErrors && (
                    <ValidationErrorsList errors={validationErrors} keyName={keyName}/>
                )}
            </div>
        );
    }
}

import * as React from 'react';
import { MDBModal, MDBModalBody} from 'mdbreact';

export class AlertModalInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
        };
    }

    componentDidMount() {
        document.body.classList.remove('modal-open');
    }

    render() {
        const { show , type, message, centeredAlert, size, centeredVertical } = this.props;
        return (  
            <MDBModal isOpen={show} fullHeight size={size} toggle={()=> {}} centered={centeredVertical}>
                <MDBModalBody>
                    <div className={"alert alert-" + type +' '+(centeredAlert? "centeredAlert":"")} role="alert">
                        {message}
                    </div>
                </MDBModalBody>
            </MDBModal>
        )
    }
}
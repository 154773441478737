export const skipList = [
    'is_kein_pflegegrad',
    'rodo_first_choice',
    'einwilligung_zur_unterbeitung',
    'einwilligung_in_die_verarbeitung',
    'die_verwendent_werden',
    'einwilligung_in_die_verarbeitung_daten',
    'marketing_einwilligungen_post_yes',
    'marketing_einwilligungen_post_no',
    'marketing_einwilligungen_telephone_yes',
    'marketing_einwilligungen_telephone_no',
    'einwilligungen_die_daten_email_yes',
    'einwilligungen_die_daten_email_no',
    'einwilligungen_die_daten_telephone_yes',
    'einwilligungen_die_daten_telephone_no',
    'einwilligungen_die_daten_post_yes',
    'einwilligungen_die_daten_post_no',
    'marketing_zustimmungen',
    'rodo_signature',
    'franchise-partner',
    "is_remarks_sleeps_de_helper",
    "skip_save_is_diet_diabetes",
    "skip_save_string_tiles",
];


import axiosAuth from '../_services/config/axios-auth';

export const markAsViewed = function (type, id) {
    let data={
        "Id": id,
        "Viewed": true,
        "EntityName": type
    }
    return axiosAuth.post('activity/markAsViewed', data)
        .then(() => {
        })
        .catch(error => {
            console.log(error);
        });
}

export const getLeadsDataCall = function (lang) {
    return axiosAuth.get(`leads/list/0/${lang}`)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}

export const getLeadsStatusCall = function (lang) {
    return axiosAuth.get(`lists/leadStatus/${lang}`)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}

export const getDynamicFields = function (data) {
    return axiosAuth.post('dynamic/retrieve', data)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}

export const saveDynamicFields = function (data) {
    return axiosAuth.post('dynamic/send', data)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}


export const assignPatients = function (data) {
    return axiosAuth.post('contracts/assignPatients', data)
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
}
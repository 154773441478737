import * as React from 'react';
import ValidationErrorsList from '../../_components/validation-errors-list/validation-errors-list';
import * as Validation from '../../_forms/validation';
import * as FieldTypes from '../../_forms/fieldTypes';
import { IconsMap } from "../../_helpers/icons_set";
export default class InputTypeComponent extends React.Component {
    static defaultProps = {
        valueFormatter: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text,
        };
    }

    setTypeChange(key, value){
        this.props.setTypeChangeToParent(key, value);
    }

    render() {
        const { value, showValidation, keyName, valueFormatter, disabled, type } = this.props;
        const realValue = typeof valueFormatter === 'function' ? valueFormatter(value) : value===null?'':value;
        const validationErrors = Validation.fieldType(realValue, type).errors;
        let showErrors = showValidation && validationErrors.length>0 && realValue!==null && realValue!==""
        return (
                <div className={"form-group row flexAlignCenter no-margin-row "+this.props.className}>
                    <label className="col-sm-4 col-form-label form-label-basic">
                        {this.props.text}
                        {type===FieldTypes.PHONE_NUMBER &&
                            Validation.fieldType(realValue, FieldTypes.PHONE_NUMBER).isValid &&
                            <a className="mail-icon-in-details" href={"tel:"+realValue} target="_top">
                                <img src={IconsMap.svg.phone} alt="Phone"/>
                            </a>
                        }
                        {type===FieldTypes.EMAIL && Validation.fieldType(realValue, FieldTypes.EMAIL).isValid &&
                            <a className="mail-icon-in-details" href={"mailto:"+realValue} target="_top">
                                <img src={IconsMap.svg.email} alt="Email"/>
                            </a>
                        }
                    </label>
                    <div className="col-sm-8">
                        <input type="text" disabled={disabled} className={`form-control lead-input-wrapper ${showErrors ? 'is-invalid' : ''}`} onChange={e => this.setTypeChange(this.props.keyName, e.target.value)} value={realValue}/>
                        { showErrors && (
                            <ValidationErrorsList keyName={keyName} errors={validationErrors} />
                        )}
                    </div>
                </div>
        );
    }
}

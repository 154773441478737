export const IconsMap = {
    hdpi:{
      clients: require("../_assets/img/hdpi/clients.png"),
      white_logo: require("../_assets/img/hdpi/E01_logo.png"),
      information: require("../_assets/img/hdpi/information.png"),
      leads: require("../_assets/img/hdpi/leads.png"),
      mail_icon: require("../_assets/img/hdpi/Mail.png"),
      search_icon: require("../_assets/img/hdpi/search_icon.png"),
      tasks: require("../_assets/img/hdpi/tasks.png"),
      strange_line: require("../_assets/img/hdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/hdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/hdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/hdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/hdpi/right-arrow-wall.png"),     
      carer_no_image: require("../_assets/img/hdpi/test_carer_image.png")
    },
    mdpi:{
      clients: require("../_assets/img/mdpi/clients.png"),
      white_logo: require("../_assets/img/mdpi/E01_logo.png"),
      information: require("../_assets/img/mdpi/information.png"),
      leads: require("../_assets/img/mdpi/leads.png"),
      mail_icon: require("../_assets/img/mdpi/Mail.png"),
      search_icon: require("../_assets/img/mdpi/search_icon.png"),
      tasks: require("../_assets/img/mdpi/tasks.png"),
      strange_line: require("../_assets/img/mdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/mdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/mdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/mdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/mdpi/right-arrow-wall.png")
    },
    xhdpi:{
      clients: require("../_assets/img/xhdpi/clients.png"),
      white_logo: require("../_assets/img/xhdpi/E01_logo.png"),
      information: require("../_assets/img/xhdpi/information.png"),
      leads: require("../_assets/img/xhdpi/leads.png"),
      mail_icon: require("../_assets/img/xhdpi/Mail.png"),
      search_icon: require("../_assets/img/xhdpi/search_icon.png"),
      tasks: require("../_assets/img/xhdpi/tasks.png"),
      strange_line: require("../_assets/img/xhdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/xhdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/xhdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/xhdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/xhdpi/right-arrow-wall.png"),
      carer_no_image: require("../_assets/img/xhdpi/test_carer_image.png")
    },
    xxhdpi:{
      clients: require("../_assets/img/xxhdpi/clients.png"),
      white_logo: require("../_assets/img/xxhdpi/E01_logo.png"),
      information: require("../_assets/img/xxhdpi/information.png"),
      leads: require("../_assets/img/xxhdpi/leads.png"),
      mail_icon: require("../_assets/img/xxhdpi/Mail.png"),
      search_icon: require("../_assets/img/xxhdpi/search_icon.png"),
      tasks: require("../_assets/img/xxhdpi/tasks.png"),
      strange_line: require("../_assets/img/xxhdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/xxhdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/xxhdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/xxhdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/xxhdpi/right-arrow-wall.png")
    },
    xxxhdpi:{
      clients: require("../_assets/img/xxxhdpi/clients.png"),
      white_logo: require("../_assets/img/xxxhdpi/E01_logo.png"),
      information: require("../_assets/img/xxxhdpi/information.png"),
      leads: require("../_assets/img/xxxhdpi/leads.png"),
      mail_icon: require("../_assets/img/xxxhdpi/Mail.png"),
      search_icon: require("../_assets/img/xxxhdpi/search_icon.png"),
      tasks: require("../_assets/img/xxxhdpi/tasks.png"),
      strange_line: require("../_assets/img/xxxhdpi/Paski_u_dol_ekranu.png"),
      left_arrow: require("../_assets/img/xxxhdpi/left-arrow.png"),
      right_arrow: require("../_assets/img/xxxhdpi/right-arrow.png"),
      left_arrow_wall: require("../_assets/img/xxxhdpi/left-arrow-wall.png"),
      right_arrow_wall: require("../_assets/img/xxxhdpi/right-arrow-wall.png"),
      carer_no_image: require("../_assets/img/xxxhdpi/test_carer_image.png")
    },
    svg:{
      menu_leads: require("../_assets/img/svg/01-leads-on.svg"),
      menu_clients: require("../_assets/img/svg/02-clients-off.svg"),
      menu_multipliers: require("../_assets/img/svg/03-multi-off.svg"),
      menu_invoices: require("../_assets/img/svg/04-invoices-off.svg"),
      menu_tasks: require("../_assets/img/svg/05-tasks-off.svg"),
      menu_info: require("../_assets/img/svg/06-info-off.svg"),
      clock_blue: require("../_assets/img/svg/209-future.svg"),
      current_green: require("../_assets/img/svg/210-current.svg"),
      clock_orange: require("../_assets/img/svg/211-ended.svg"),
      phone: require("../_assets/img/svg/group.svg"),
      notes: require("../_assets/img/svg/notes.svg"),
      pasek_small: require("../_assets/img/svg/paski.svg"),
      pasek_big: require("../_assets/img/svg/paski_big.svg"),
      promo_logo: require("../_assets/img/svg/prom_logo.svg"),
      search_icon: require("../_assets/img/svg/search-icon.svg"),
      email: require("../_assets/img/svg/shape.svg"),
      call_black: require("../_assets/img/svg/e-6-call-black.svg"),
      call_red: require("../_assets/img/svg/e-6-call-red.svg"),
      clock_black: require("../_assets/img/svg/e-6-clock-black.svg"),
      clock_red: require("../_assets/img/svg/e-6-clock-red.svg"),
      red_status_icon: require("../_assets/img/svg/red-dot.svg"),
      orange_status_icon: require("../_assets/img/svg/orange-dot.svg"),
      blue_status_icon: require("../_assets/img/svg/blue-dot.svg"),
      green_status_icon: require("../_assets/img/svg/green-dot.svg"),
      white_status_icon: require("../_assets/img/svg/white-dot.svg"),
      future: require("../_assets/img/svg/future.svg"),
      current: require("../_assets/img/svg/current.svg"),
      ended: require("../_assets/img/svg/ended.svg"),
      tick_oval_green: require("../_assets/img/svg/oval.svg"),
      dashboard: require("../_assets/img/svg/dashboard_icon.svg"),
      medal: require("../_assets/img/svg/medal.svg"),
      movie: require("../_assets/img/svg/movie.svg"),
      anziehen: require("../_assets/img/svg/anziehen.svg"),
      baden: require("../_assets/img/svg/baden.svg"),
      certyfikat: require("../_assets/img/svg/certyfikat.svg"),
      einkaufen: require("../_assets/img/svg/einkaufen.svg"),
      freizeitgestaltung: require("../_assets/img/svg/freizeitgestaltung.svg"),
      gesellschaft: require("../_assets/img/svg/gesellschaft.svg"),
      hilfe_beim_transfer: require("../_assets/img/svg/hilfe_beim_transfer.svg"),
      kochen: require("../_assets/img/svg/kochen.svg"),
      sicherheit: require("../_assets/img/svg/sicherheit.svg"),
      wasche: require("../_assets/img/svg/wasche.svg"),
      ortlich: require("../_assets/img/svg/ortlich.svg"),
      personlich: require("../_assets/img/svg/personlich.svg"),
      zeitlich: require("../_assets/img/svg/zeitlich.svg"),
      brille: require("../_assets/img/svg/brille.svg"),
      horegat: require("../_assets/img/svg/horegat.svg"), 
      hygiene: {
        duschen: require("../_assets/img/svg/duschen.svg"),
        haarpflege: require("../_assets/img/svg/haarpflege.svg"),
        hautpflege: require("../_assets/img/svg/hautpflege.svg"),
        horegat: require("../_assets/img/svg/horegat.svg"),
        intimpflege: require("../_assets/img/svg/intimpflege.svg"),
        mundpflege: require("../_assets/img/svg/mundpflege.svg"),
        rasieren: require("../_assets/img/svg/rasieren.svg")
      },
      essenUndTrinken: {
        essen: require("../_assets/img/svg/essen.svg"),
        kauen: require("../_assets/img/svg/kauen.svg"),
        trinken: require("../_assets/img/svg/trinken.svg"),
      },
      sichKleiden: {
        ankleidenHose: require("../_assets/img/svg/ankleiden_hose.svg"),
        ankleidenOberteil: require("../_assets/img/svg/ankleiden_oberteil.svg"),
        ankleidenSchuhe: require("../_assets/img/svg/ankleiden_schuhe.svg"),
        ankleidenUnterhose: require("../_assets/img/svg/ankleiden_unterhose.svg"),

        auskleidenHose: require("../_assets/img/svg/auskleiden_hose.svg"),
        auskleidenOberteil: require("../_assets/img/svg/auskleiden_oberteil.svg"),
        auskleidenSchuhe: require("../_assets/img/svg/auskleiden_schuhe.svg"),
        auskleidenUnterhose: require("../_assets/img/svg/auskleiden_unterhose.svg"),
      },
      zusammenfassung: {
        car: require("../_assets/img/svg/zusammenfassung/car.svg"),
        car_white: require("../_assets/img/svg/zusammenfassung/car_white.svg"),
        chat: require("../_assets/img/svg/zusammenfassung/chat.svg"),
        chat_white: require("../_assets/img/svg/zusammenfassung/chat_white.svg"),
        clock: require("../_assets/img/svg/zusammenfassung/clock.svg"),
        clock_white: require("../_assets/img/svg/zusammenfassung/clock_white.svg"),
        demenz_black: require("../_assets/img/svg/zusammenfassung/demenz.svg"),
        demenz_white: require("../_assets/img/svg/zusammenfassung/demenz_white.svg"),
        fork: require("../_assets/img/svg/zusammenfassung/fork.svg"),
        fork_white: require("../_assets/img/svg/zusammenfassung/fork_white.svg"),
        inkontinenz: require("../_assets/img/svg/zusammenfassung/inkontinenz.svg"),
        inkontinenz_white: require("../_assets/img/svg/zusammenfassung/inkontinenz_white.svg"),
        skleroze: require("../_assets/img/svg/zusammenfassung/skleroze.svg"),
        skleroze_white: require("../_assets/img/svg/zusammenfassung/skleroze_white.svg"),
        sonda: require("../_assets/img/svg/zusammenfassung/sonda.svg"),
        sonda_white: require("../_assets/img/svg/zusammenfassung/sonda_white.svg"),
        stoma: require("../_assets/img/svg/zusammenfassung/stoma.svg"),
        stoma_white: require("../_assets/img/svg/zusammenfassung/stoma_white.svg"),
        transfer_black: require("../_assets/img/svg/zusammenfassung/transfer.svg"),
        transfer_white: require("../_assets/img/svg/zusammenfassung/transfer_white.svg"),
        urin: require("../_assets/img/svg/zusammenfassung/urin.svg"),
        urin_white: require("../_assets/img/svg/zusammenfassung/urin_white.svg"),
      }
    },
    rest:{
      white_logo : require("../_assets/img/e-01-logo@3x.png"),
      tick_black : require("../_assets/img/tick_black.png"),
      tick_red : require("../_assets/img/tick_red.png"),
      dashboard : require("../_assets/img/dashboard_icon.png"),
    },
    other:{
      gender_female : require("../_assets/img/other/betreuungskraft-1.png"),
      gender_female_white : require("../_assets/img/other/betreuungskraft-1_w.png"),
      gender_male : require("../_assets/img/other/betreuungskraft-2.png"),
      gender_male_white : require("../_assets/img/other/betreuungskraft-2_w.png"),
      gender_other : require("../_assets/img/other/betreuungskraft-3.png"),
      gender_other_white : require("../_assets/img/other/betreuungskraft-3_w.png"),
      watch_gray : require("../_assets/img/other/erstangebot-1.png"),
      watch_white : require("../_assets/img/other/erstangebot-1-white.png"),
      female_question_gray : require("../_assets/img/other/erstangebot-2.png"),
      female_question_white : require("../_assets/img/other/erstangebot-2-white.png"),
      female_drip_gray : require("../_assets/img/other/erstangebot-3.png"),
      female_drip_white : require("../_assets/img/other/erstangebot-3-white.png"),
      chat_gray : require("../_assets/img/other/erstangebot-4.png"),
      chat_white : require("../_assets/img/other/erstangebot-4-white.png"),
      swing_gray : require("../_assets/img/other/erstangebot-5.png"),
      swing_white : require("../_assets/img/other/erstangebot-5-white.png"),
      panties_gray : require("../_assets/img/other/erstangebot-6.png"),
      panties_white : require("../_assets/img/other/erstangebot-6-white.png"),
      stoma_gray : require("../_assets/img/other/erstangebot-7.png"),
      stoma_white : require("../_assets/img/other/erstangebot-7-white.png"),
      pompa_gray : require("../_assets/img/other/erstangebot-8.png"),
      pompa_white : require("../_assets/img/other/erstangebot-8-white.png"),
      diabetic_gray : require("../_assets/img/other/erstangebot-9.png"),
      diabetic_white : require("../_assets/img/other/erstangebot-9-white.png"),
      car_gray : require("../_assets/img/other/erstangebot-10.png"),
      car_white : require("../_assets/img/other/erstangebot-10-white.png"),
      brain_gray : require("../_assets/img/other/erstangebot-11.png"),
      brain_white : require("../_assets/img/other/erstangebot-11-white.png"),
      kommunikation_ear : require("../_assets/img/other/kommunikation-1.png"),
      kommunikation_eye : require("../_assets/img/other/kommunikation-2.png"),
      kommunikation_hearing : require("../_assets/img/other/kommunikation-3.png"),
      human_gray : require("../_assets/img/other/pg1-black.png"),
      human_white : require("../_assets/img/other/pg1-white.png"),
      human_walk_gray : require("../_assets/img/other/pg2-black.png"),
      human_walk_white : require("../_assets/img/other/pg2-white.png"),
      human_wheel_gray : require("../_assets/img/other/pg3-black.png"),
      human_wheel_white : require("../_assets/img/other/pg3-white.png"),
      human_bed_gray : require("../_assets/img/other/pg4-black.png"),
      human_bed_white : require("../_assets/img/other/pg4-white.png"),
      human_bed_2_gray : require("../_assets/img/other/pg5-black.png"),
      human_bed_2_white : require("../_assets/img/other/pg5-white.png"),
      rodo_human_chair_gray : require("../_assets/img/other/rodo-black.png"),
      rodo_human_chair_white : require("../_assets/img/other/rodo-white.png"),
      rodo_human_gray : require("../_assets/img/other/rodo-black-a.png"),
      rodo_human_white : require("../_assets/img/other/rodo-white-a.png"),
      bewegen_1 : require("../_assets/img/other/sich-bewegen-1.png"),
      bewegen_2 : require("../_assets/img/other/sich-bewegen-2.png"),
      bewegen_3 : require("../_assets/img/other/sich-bewegen-3.png"),
      bewegen_4 : require("../_assets/img/other/sich-bewegen-4.png"),
      background_zone: require("../_assets/img/other/bg.jpg"),
      prom_logo_footer: require("../_assets/img/other/logo_footer.png"),
      prom_logo_top: require("../_assets/img/other/logo_top.png"),
    }
  };
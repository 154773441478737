
import { REFRESH_PENDING, REFRESH_FINISHED, NEED_SAVE, DOES_NOT_NEED_SAVE } from "../actions/action-types";
const initialState = {
  refreshing: false,
  needsDataSave: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_PENDING:
        return {
          refreshing: true
        };
    case REFRESH_FINISHED:
        return {
          refreshing: false
        };
    case NEED_SAVE:
        return {
          needsDataSave: true
        };
    case DOES_NOT_NEED_SAVE:
        return {
          needsDataSave: false
        };
    default:
      return state;
  }
};

export default reducer;
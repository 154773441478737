import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";
import Switch from "react-switch";

class SwitchWithTextComponent extends React.Component {
    handleChange = (checked) => {
        const { keyName, setTypeChangeToParent, valueYes, valueNo, disabled } = this.props;
        if(!disabled){
            let value = valueYes;
            if(checked){
                value = valueNo;
            }
            setTypeChangeToParent(keyName, value);
        }
    }

    render() {
        const { valueYes, keyName, value, validationErrors, showValidation, className, translateKey, transKey, labelYes, labelNo, disabled } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;
        return (
            <div className={`${className} list-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                <div className="switch-button-group-wrapper" onChange={this.setTypeChange}>
                    <span className="switch-answer-wrapper">{labelYes?labelYes:translateKey("yes")}</span>
                    <Switch 
                        className={`switch-class-wrapper ${disabled?'no-pointer-events':''}`}
                        onColor="#ffffff" 
                        offColor="#ffffff" 
                        offHandleColor="#09629e"
                        onHandleColor="#60676e"
                        uncheckedIcon={false} 
                        checkedIcon={false} 
                        onChange={this.handleChange} 
                        checked={value !== valueYes} 
                        disabled={this.props.shouldBeEdited}
                    />
                    <span className="switch-answer-wrapper right">{labelNo?labelNo:translateKey("no")}</span>
                    <label className="form-label-basic newUI switch-with-text-class"><span className="quick-label-position-fix">{translateKey(transKey).toUpperCase()}</span></label>
                </div>
                { showErrors && (
                    <ValidationErrorsList errors={validationErrors} keyName={keyName} />
                )}
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default withRouter(connect(null, mapDispatchToProps)(SwitchWithTextComponent));
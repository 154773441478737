import React from 'react';

export default class SelectUniversalComponent extends React.Component {
    static defaultProps = {
        valuesList: [],
    };

    setTypeChange = event => {
        const { target: { value } } = event;
        const { keyName, setTypeChangeToParent } = this.props;
        setTypeChangeToParent(keyName, value);
    };

    render() {
        const { valuesList, keyName, value, optionValueKey, optionLabelKey } = this.props;
        return (
            <select className="custom-select col-sm-10 lead-input-wrapper" onChange={this.setTypeChange} value={value!==null?value:''}>
                <option hidden />
                { valuesList.map(possibleValue => {
                    const id = `${keyName}+${possibleValue[optionValueKey]}`;
                    return (
                        <option key={id} value={possibleValue[optionValueKey]}>{possibleValue[optionLabelKey]}</option>
                    );
                })}
            </select>
        );
    }
}
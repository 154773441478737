import axios from 'axios';
import ReqHelper from '../../_helpers/request_helper';
import {baseData} from '../../_constance/base_data';
//import CryptoJS from 'crypto-js';

const request = axios.create({
    baseURL: baseData.base_backend
});

request.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(ReqHelper.responseErrorHelper(error));
});

request.interceptors.request.use(config => {
    config.headers = { 
      //'X-Auth-Token': CryptoJS.AES.encrypt(baseData.meetingM, baseData.meetingS).toString(),
      'X-Auth-Token': baseData.meetingM,
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    return config;
  }, err => {
    return Promise.reject(ReqHelper.responseErrorHelper(err));
  });

export default request;
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, Prompt} from "react-router-dom";
import { setLeadsData, pendingLeadsData, removeLeadsData, translateKey, authSetActionTimestamp, removeLeadStatusData, getLeadStatusData, getPatientLead, authSaveAndUpdatePatient} from "../../_redux/actions/index";
import { LoaderTable, LoaderModal, AlertModalInfo, MultiPatientModal} from "../../_components/index";
import { baseData } from '../../_constance/base_data';
import { 
  SectionAngabenZurKontakt, 
  SectionAngabenZurOrt, 
  Section2, 
  Section3, 
  Section4, 
  Section5, 
  Section6, 
  Section8, 
  SectionPflegedienstSimple, 
  Section16, 
  Section17, 
  SectionBeschreibung,
  //SectionInitialOffer,
  SectionKundeFinished
} from "../../_components/form-bb-sections/index";
import { RodoSection1, RodoSection2 } from "../../_components/form-bb-rodo/index";
import SectionNavigationToolbarComponent from "../../_components/form-bb-components/section-navigation-toolbar/section-navigation-toolbar";
import { getUserProducts, getRegionsFP } from "../../_redux/actions/bb-form-services";
import BBFormService from '../../_services/bbform';
import * as Entity from '../../_forms/BBForm/values/entity';
import * as Relationship from '../../_forms/BBForm/values/relationship';
import * as FormTypeToShow from '../../_forms/BBForm/values/formTypeToShow';
import * as IsContactPersonDataSameAs from '../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../_forms/BBForm/values/sections';
import { Alert, FormLoadedState } from "../../_constance/classes/index";
import BBForm from '../../_forms/BBForm/BBForm';
import { FormConfig } from '../../_forms/BBForm/formConfig';
import NavBar from "../../_components/navbar/NavBar";
import {IconsMap} from "../../_helpers/icons_set";
import FooterComponent from "../../_components/footer/Footer";
import { userFinishedKunde, confirmSecondPatient } from "../../_redux/actions/auth";
import { getSectionNameByNumber, getSectionNumberByName } from "../../_constance/enums/sectionPageList";
import { RodoHtmlHelper } from '../../_helpers/rodo_html_helper';
import { getDynamicFields } from '../../_services/user_service';
const LAST_SECTION_KEY = 'is_last_section';
const SKIP_SAVE_IS_DIET_DIABETES = 'skip_save_is_diet_diabetes'; 
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 


class MainPatientWrapper extends React.Component {
  _isMounted = false;
  myTimout;
  constructor(props) {
    super(props);
    this.state = {
      editedForm: false,
      currentSection: 0,
      loader: null,
      modalLoader: true,
      savingData: false,
      errorComponent: Alert,
      LKId: 0,
      patientId: 0,
      products: [],
      regionsFP: [],
      formbbStatus: "0",
      type: FormTypeToShow.FORM,
      confirmConsents: false,
      confirmNewEnquiry: false,
      toPage: null,
      isConsentsEditable: true,
      isImportant: false,
      hideBar: false,
      breadCrumbs:[
        {
          link: "leads",
          key: "leads",
          name: null
        }
      ],
      showValidationErrors: false,
      form: new BBForm(),
      rerender: Date.now(),
      newOne: false,
      leadPatients: [],
      wholeResponse: null,
      sectionMenuOpen: false,
      analizeFinished: true,
      prevPageNumber: 0,
      patientFullName: '',
      rodoDisabled: false,
      secondPatientId: 0,
      secondPatientName: '',
      loadedSecondPatientData: false,
      showMultiPatientModal: true,
      showRedirection: false,
      multiplePatients: false,
    };
  }

  componentDidMount() {
    const { appZoneClientId, appZonePatient } = this.props;

    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
    let lkid = appZoneClientId?appZoneClientId:0;
    let patientid = appZonePatient?appZonePatient:0;

    this.setState({
      LKId: lkid,
      patientId: patientid,
    });

    this.props.getPatientLeadData(this.props.appLang);
    this.myTimeout = setTimeout(() => {
      this.prepareMeForm(patientid);
    }, 200);
  }

  componentDidUpdate() {
    this._promptUnsavedChange(this.state.editedForm)
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.myTimeout);
  };

  _promptUnsavedChange(isUnsaved = false) {
    window.onbeforeunload = isUnsaved;
  }

  changePage = async number => {
      const { form, currentSection, formbbStatus } = this.state;
      let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
      if(currentSection===FormConfig.finalPageNumber){
        if(!isFormDisabled){
          isFormDisabled = this.state.isConsentsEditable;
        }
      }
      if(!isFormDisabled){
        const section = form.getSectionByOrder(currentSection);
        let lastSectionValue = form.getFieldByKey(LAST_SECTION_KEY);
        let lastSectionNumber = getSectionNumberByName(lastSectionValue.value);
        if(lastSectionNumber<section.nextSection){
          lastSectionValue.value = getSectionNameByNumber(section.nextSection);
        }
        let beginSection = 0;
        const rodoAvailable = this.checkRodoAvaliableSection()
        if (!rodoAvailable) {
          beginSection = FormConfig.firstPageAfterRodo;
        }
        if (section.isValid) {
            await this.savePatient();
        }
        if (currentSection > number) {
            if (this._isMounted) {
              this.setState({
                  currentSection: number>=beginSection?number:beginSection,
                  savingData: false,
                  showValidationErrors: true,
                  confirmConsents:false,
                  isImportant: false,
                  prevPageNumber: number
              });
            }
            window.scrollTo(0, 0);
        } else {
            const { form } = this.state;
            let readyToGo = true;
            for (let i = beginSection; i < number; i++) {
                let tempSection = form.getSectionByOrder(i);
                if (!tempSection.isValid) {
                    readyToGo = false;
                    if (this._isMounted) {
                      this.setState({
                          showValidationErrors: true,
                          currentSection: i,
                          confirmConsents: false,
                          isImportant: false
                      });
                    }
                    window.scrollTo(0, 0);
                    break;
                }
            }
            if (readyToGo && number!==currentSection) {
                if (this._isMounted) {
                  this.setState({
                      currentSection: number>=beginSection?number:beginSection,
                      confirmConsents: false,
                      isImportant: false
                  });
                }
                window.scrollTo(0, 0);
            }
        }
    }else{
      if (this._isMounted) {
        this.setState({
          currentSection: number,
          savingData: false,
          showValidationErrors: true,
          confirmConsents:false,
          isImportant: false
        });
      }
      window.scrollTo(0, 0);
    }
  };

  previousPageClick = async () => {
      const { currentSection, confirmConsents, formbbStatus, form} = this.state;
      let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
      if(currentSection===FormConfig.finalPageNumber){
        if(!isFormDisabled){
          isFormDisabled = !this.state.isConsentsEditable;
        }
      }
      if(!isFormDisabled){
        const section = form.getSectionByOrder(currentSection);
        if(currentSection===FormConfig.finalPageNumber && confirmConsents===false){
          if(currentSection>FormConfig.initPage){
            if (this._isMounted) {
              this.setState({
                currentSection: section.prevSection,
                isImportant: false,
                prevPageNumber: currentSection
              })
            }
            window.scrollTo(0, 0);
          }
        }else{
          const [isSaved, LKId, patientIdFromSave] = await this.savePatient();
          if (isSaved) {
            const { patientId } = this.state;
            if(patientId==="0" || patientId===0){
              this.props.getPatientLeadData(this.props.appLang);
              const { history } = this.props;
              history.push(`${baseData.subFolders}bb-form-lead/${LKId}/${patientIdFromSave}`);
            }
          }
          if(currentSection>FormConfig.initPage){
            if (this._isMounted) {
              this.setState({
                currentSection: this.giveMeSpecialToGoSection(),
                LKId: LKId,
                patientId: patientIdFromSave,
                isImportant: false,
                prevPageNumber: currentSection
              });
            }
            window.scrollTo(0, 0);
          }
        }
      }else{
        if(currentSection>FormConfig.initPage){
          if (this._isMounted) {
            this.setState({
              currentSection: this.giveMeSpecialToGoSection(),
              isImportant: false,
              prevPageNumber: currentSection
            });
          }
          window.scrollTo(0, 0);
        }
      }
  };

  nextPageClick = async () => {
    const { form, currentSection, confirmConsents, formbbStatus } = this.state;
    let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if(currentSection===FormConfig.finalPageNumber){
      if(!isFormDisabled){
        isFormDisabled = !this.state.isConsentsEditable;
      }
    }
    if(!isFormDisabled){
      if(currentSection===FormConfig.finalPageNumber && confirmConsents===false){
        if (this._isMounted) {
          this.setState({
            confirmConsents: true,
            toPage: null,
            prevPageNumber: currentSection
          })
        }
      }else{
        const section = form.getSectionByOrder(currentSection);
        if(FormConfig.finalPageNumber>=section.nextSection){
          let lastSectionValue = form.getFieldByKey(LAST_SECTION_KEY);
          let lastSectionNumber = getSectionNumberByName(lastSectionValue.value);
          if(lastSectionNumber<section.nextSection){
            lastSectionValue.value = getSectionNameByNumber(section.nextSection);
          }
        }
        const [isSaved, LKId, patientIdFromSave] = await this.savePatient();
        if (isSaved) {
          const { patientId } = this.state;
          if(patientId==="0" || patientId===0){
            this.props.getPatientLeadData(this.props.appLang);
            const { history } = this.props;
            history.push(`${baseData.subFolders}bb-form-lead/${LKId}/${patientIdFromSave}`);
          }
          if (this._isMounted) {
            this.setState({
              currentSection: section.nextSection,
              LKId,
              patientId: patientIdFromSave,
              prevPageNumber: currentSection
            });
          }
          window.scrollTo(0, 0);
        }
      }
    }else{
      if(currentSection===FormConfig.finalPageNumber){
        if (this._isMounted) {
          this.setState({
            confirmConsents:false,
            isConsentsEditable: false,
            prevPageNumber: currentSection
          });
        }
      }else{
        if (this._isMounted) {
          const section = form.getSectionByOrder(currentSection);
          this.setState({
            currentSection: section.nextSection,
            prevPageNumber: currentSection
          });
        }
        window.scrollTo(0, 0);
      }

    }
  };

  disableSupportFields = () =>{
    const saveDiabetesField = this.state.form.getFieldByKey(SKIP_SAVE_IS_DIET_DIABETES);
    if(saveDiabetesField){saveDiabetesField.value = false}
    const saveDiabetesTileField = this.state.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
    if(saveDiabetesTileField){saveDiabetesTileField.value = false}
  }

  finishMe = async () => {
    const { appZoneToken, finishKunde, history, translateKey } = this.props;
    const { form } = this.state;
    const isDekubitus = form.getFieldByKey('is_bedsores_list');
    const isCancer = form.getFieldByKey('is_cancer_list');
    const isPalliative = form.getFieldByKey('is_palliative_care');
    const dataIllness = {
      'tumor': isDekubitus.value? true:false,
      'dekubitus': isCancer.value? true:false,
      'palliativ': isPalliative.value? true:false,
      'rodo_content': RodoHtmlHelper(form,translateKey)
    }
    finishKunde(appZoneToken, dataIllness)
      .then(()=>{
        history.push(`${baseData.subFolders}logoutToAusLogen`);
      })
      .catch((err)=>{
        console.log(err);
        this.showAlertMessage('danger', translateKey('basic_error_message'));
      }
    )    
  };

  giveMeSpecialToGoSection(){
    const { currentSection, form } = this.state;
    const section = form.getSectionByOrder(currentSection);
    if(section.isValid) {
      let toPageNumber = section.prevSection;
      if(toPageNumber === Sections.PATIENT){
        const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
        if(isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8){
          const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
          if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
            toPageNumber = Sections.CLIENT
          }else{
            toPageNumber =  Sections.CONTACT_PERSON
          }
        }
      }else{
        if(toPageNumber === Sections.CONTACT_PERSON){
          const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
          if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
            toPageNumber = Sections.CLIENT
          }
        }
      }
      return toPageNumber;
    } else {
      return currentSection
    }
  }

  clearAlertComponent = (withTimeOut=false) =>{
    let timeOutTime = 0;
    if(withTimeOut){
      timeOutTime = 2000;
    }
    setTimeout(() => {
      if (this._isMounted) {
        this.setState(prevState => {
          return {
              ...prevState,
              errorComponent: {
                ...prevState.errorComponent,
                show: false,
                type: "danger",
                message: null
              }
          }
        })
      }
    }, timeOutTime);
  }

  showAlertMessage = (type, message) =>{
    if (this._isMounted) {
      this.setState(prevState => {
        return {
            ...prevState,
            errorComponent: {
              ...prevState.errorComponent,
              show: true,
              type: type,
              message: message
            }
        }
      })
    }
    this.clearAlertComponent(true);
  }

  savePatient = async () => {
      const { form, currentSection, formbbStatus, isImportant, LKId, patientId } = this.state;
      const { updatePatientID, appZoneToken, appZoneId} = this.props;
      let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
      if(currentSection<FormConfig.firstPageAfterRodo){
        isFormDisabled = this.state.rodoDisabled;
      }
      if(!isFormDisabled){
        if (this._isMounted) {
          this.setState({ savingData: true });
        }
        const section = form.getSectionByOrder(currentSection);
        let allImportantFieldsFilled = true;
        if(isImportant===false && !section.allImportantsFilled){
          allImportantFieldsFilled = false;
        }
        if (section.isValid && allImportantFieldsFilled) {
            try {
                const response = await BBFormService.savePatient(form.getPayloadForSection(section.key, LKId, patientId));
                let lkid = LKId?LKId:0;
                let patientid = patientId?patientId:0;
                const { data } = response;
                data.forEach(element => {
                  if (element.entity === Entity.PATIENT) {
                      patientid = element.id;
                      if(patientId!==element.id){
                        updatePatientID(appZoneToken, appZoneId, element.id).catch((err)=> console.log(err));
                      }
                  } else {
                      if (element.entity === Entity.CONTACT) {
                          lkid = element.id;
                      }
                  }
                });
                if (this._isMounted) {
                  this.setState({
                      savingData: false,
                      showValidationErrors: false,
                      isImportant: false,
                  });
                }
                if(currentSection===FormConfig.firstPageAfterRodo){
                  const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
                  const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
                  if (isContactPersonDataSameAs.value === IsContactPersonDataSameAs.YES) {
                    /// pass fields from 1 to second section
                    form.getFieldByKey('is_contact_person_firstname').value = form.getFieldByKey('firstname').value;
                    form.getFieldByKey('is_contact_person_lastname').value = form.getFieldByKey('lastname').value;
                    form.getFieldByKey('is_contact_person_mobile_phone').value = form.getFieldByKey('is_mobile_phone').value;
                    form.getFieldByKey('is_contact_person_home_phone').value = form.getFieldByKey('is_home_phone').value;
                    form.getFieldByKey('is_relationship_with_patient_op').value = isDegreeRelationShipWithPatientCarer.value;
                    this.simpleSaveWithoutErrors(Sections.CONTACT_PERSON, patientid);
                  }
                  if(isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8){
                    /// pass fields from 1 to third section
                    const firstNameValue = form.getFieldByKey('firstname').value;
                    const lastNameValue = form.getFieldByKey('lastname').value;
                    form.getFieldByKey('is_patient_first_name').value = firstNameValue;
                    form.getFieldByKey('is_patient_last_name').value = lastNameValue;
                    form.getFieldByKey('is_first_name_and_last_name_of_patient').value = firstNameValue +" "+ lastNameValue;
                    form.getFieldByKey('is_service_address_city').value = form.getFieldByKey('is_registered_city').value;
                    form.getFieldByKey('is_service_address_house_number').value = form.getFieldByKey('is_registered_house_number').value;
                    form.getFieldByKey('is_patient_cellphone').value = form.getFieldByKey('is_mobile_phone').value;
                    form.getFieldByKey('is_service_address_postal_code').value = form.getFieldByKey('is_registered_zip_postal_code').value;
                    form.getFieldByKey('is_service_address_street_number').value = form.getFieldByKey('is_registered_street_number').value;
                    form.getFieldByKey('is_service_address_street').value = form.getFieldByKey('is_registered_street').value;
                    this.simpleSaveWithoutErrors(Sections.PATIENT, patientid);
                  }
                }
                this.disableSupportFields();
                return [true, lkid, patientid];
            } catch (e) {
                console.log(e);
                if (this._isMounted) {
                  this.setState({
                      savingData: false,
                      showValidationErrors: false,
                      isImportant: false
                  });
                }
                return [false];
            }
        } else {
            if (this._isMounted) {
              this.setState({
                  savingData: false,
                  showValidationErrors: true,
                  isImportant: true,
              });
              setTimeout(() => {
                const scrollToField = section.fields.find(element => !element.isValid);
                if(scrollToField){
                  const elmnt = document.getElementById(`${scrollToField.key}_error_id`);
                  if(elmnt){
                    elmnt.parentNode.scrollIntoView({ behavior: 'smooth'});
                  }
                }else{
                  const importantMessageList = document.getElementsByClassName('important-message-class');
                  if(importantMessageList.length>0){
                    importantMessageList[0].parentNode.scrollIntoView({ behavior: 'smooth'});
                  }
                }
              }, 50);
            }
            return [false];
        }
      }else{
        if (this._isMounted) {
          this.setState({
            savingData: false,
            showValidationErrors: false,
            isImportant: false
          });
        }
        this.disableSupportFields();
        return [true, LKId, patientId]
      }
  };

  simpleSaveWithoutErrors = async (sectionNumber, patientid) => {
    const { form, currentSection, formbbStatus, LKId, patientId } = this.state;
    let isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    if(currentSection===FormConfig.finalPageNumber){
      if(!isFormDisabled){
        isFormDisabled = this.state.isConsentsEditable;
      }
    }
    if(!isFormDisabled){
      const section = form.getSectionByOrder(sectionNumber);
      if (section.isValid) {
        let newPatient = patientId;
        if(patientid!==0 || patientid!=="0"){
          newPatient = patientid;
        }
        await BBFormService.savePatient(form.getPayloadForSection(section.key, LKId, newPatient));
        this.props.getPatientLeadData(this.props.appLang);
      }
    }
  };

  getMeInitLeadPatientsList(){
    const { appZonePatient, patientLeadData } = this.props;
    let pushedElements = [];
    let leadPatientsList = patientLeadData.filter(element => {
        if(element.leadId === appZonePatient){
            if(pushedElements.indexOf(element.id) > -1){
                return false;
            }else{
                pushedElements.push(element.id);
                return element
            }
        }else{
          return false;
        }
    })
    if (this._isMounted) {
      this.setState({leadPatients: leadPatientsList})
    }
    return pushedElements;
  }

  checkRodoAvaliableSection(){
    const { form } = this.state;
    const isZgDe2 = form.getFieldByKey("is_zg_de_2");
    const isZgDe3 = form.getFieldByKey("is_zg_de_3");
    const isZgDe4 = form.getFieldByKey("is_zg_de_4");
    const isZgDe5 = form.getFieldByKey("is_zg_de_5");
    const isZgDe6 = form.getFieldByKey("is_zg_de_6");
    const isZgDe7 = form.getFieldByKey("is_zg_de_7");
    const isZgDe8 = form.getFieldByKey("is_zg_de_8");
    if (
      isZgDe2.value !== null ||
      isZgDe3.value !== null ||
      isZgDe4.value !== null ||
      isZgDe5.value !== null ||
      isZgDe6.value !== null ||
      isZgDe7.value !== null ||
      isZgDe8.value !== null
    ) {
      return false;
    }
    return true;
  }

  formLoadedChangeState = (formObj = new FormLoadedState()) =>{
    if (this._isMounted) {
      this.setState({
        currentSection: formObj.currentSection,
        savingData: formObj.savingData,
        modalLoader: formObj.modalLoader,
        showValidationErrors: formObj.showValidationErrors,
        isImportant: formObj.isImportant,
        prevPageNumber: formObj.prevPageNumber,
        rodoDisabled: formObj.rodoDisabled
      })
    }
    window.scrollTo(0, 0);
  }

  prepareMeForm(patientId){
    const { form, showValidationErrors, currentSection, savingData, modalLoader, prevPageNumber, rodoDisabled, LKId } = this.state;
    this.setState({
      showValidationErrors: false,
      currentSection: 0,
      savingData: false,
      modalLoader: false,
    });
    BBFormService.retrievePatient(form.getRetiveDataALl(LKId, [patientId])).then(response => {
      const { data } = response;
      let consentsEditable = true;
      data.forEach(entity => form.setFields(entity.fields));
      let sectionConsents = form.getSectionByOrder(FormConfig.finalPageNumber);
      let test = sectionConsents.fields.filter(field => field.value !== null);
      if(test.length>0){consentsEditable=false}
      if (this._isMounted) {
        this.setState({isConsentsEditable: consentsEditable});
      }
      let readyToGo = true;
      let formState = new FormLoadedState(showValidationErrors, currentSection, savingData, modalLoader, prevPageNumber, rodoDisabled);
      if(patientId==="0" || patientId===0){
        formState = new FormLoadedState(false, 0, false, false, 0, false);
      }else{
        let lastSection = form.getFieldByKey(LAST_SECTION_KEY);
        if(lastSection.value!==null){
          if(lastSection.value < 3) {
            formState = new FormLoadedState(false, getSectionNumberByName(lastSection.value), false, false, 0, !this.checkRodoAvaliableSection());
          } else {
            formState = new FormLoadedState(false, getSectionNumberByName(lastSection.value), false, false, 0, false);
          }
        }else{
          let beginSection = 0;
          const rodoAvailable = this.checkRodoAvaliableSection();
          if (!rodoAvailable) {
            beginSection = FormConfig.firstPageAfterRodo;
            let lastSectionNumber = form.sections.length;
            for (let i = beginSection; i < lastSectionNumber; i++) {
              let section = form.getSectionByOrder(i);
              if (!section.isValid) {
                readyToGo = false;
                formState = new FormLoadedState(i !== 0, i, false, false, i, true);
                break;
              }
            }
            if (readyToGo) {
              formState = new FormLoadedState(true, FormConfig.finalPageNumber, false, false, FormConfig.finalPageNumber, true);
            }
          } else {
            formState = new FormLoadedState(true, 0, false, false, 0, false);
          }
        }
      }
      this.myTimeout = setTimeout(() => {
        this.formLoadedChangeState(formState);
      }, 3000);
    }).then(()=>{
      if (this._isMounted) {
        this.setState({rerender: Date.now()});
      }
    })
  }

  toggleAnalizeLoader = () => {
    this.setState(prevState => {
      return {
          ...prevState,
          analizeFinished: !prevState.analizeFinished
      }
    })
  }

  toggleOffAnalizer = () => {
    this.setState(prevState => {
      return {
          ...prevState,
          analizeFinished: true
      }
    })
  }

  getDataBasic(isFormDisabled){
    const { translateKey } = this.props;
    const { form, rerender, showValidationErrors, isImportant, currentSection, sectionMenuOpen, loader, rodoDisabled, analizeFinished, modalLoader, multiplePatients, secondPatientName} = this.state;
    let sectionData = (
      <LoaderTable />
    );

    if(!loader){
      switch (currentSection) {
        case 0:
          sectionData = (
            <div className="section-class-container">
              <RodoSection1
                rodoDisabled={rodoDisabled}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-rodo1-text-akceptieren")}
                sectionNumber={1}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                activeSectionNumber={currentSection}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
                formLoaded = {!modalLoader}
              ></RodoSection1>
            </div>
          );
          break;
        case 1:
          sectionData = (
            <div className="section-class-container">
              <RodoSection2
                rodoDisabled={rodoDisabled}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("form-rodo1-text-akceptieren")}
                sectionNumber={2}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                activeSectionNumber={currentSection}
                fullName = {this.state.patientFullName}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
                multiplePatients={ multiplePatients }
              ></RodoSection2>
            </div>
          );
          break;
        case 2:
          sectionData = (
            <div className="section-class-container">
              <SectionAngabenZurKontakt
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_client_data_p_0")}
                sectionNumber={3}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                activeSectionNumber={currentSection}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionAngabenZurKontakt>
            </div>
          );
          break;
        case 3:
          sectionData = (
            <div className="section-class-container">
              <SectionAngabenZurOrt
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_contact_data_p_1")}
                sectionNumber={4}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionAngabenZurOrt>
            </div>
          );
          break;
        case 4:
          sectionData = (
            <div className="section-class-container">
              <Section2
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_lead_data_p_2")}
                sectionNumber={5}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section2>
            </div>
          );
          break;
        case 5:
          sectionData = (
            <div className="section-class-container">
              <Section3 
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_pflegegrad_data_p_3")}
                sectionNumber={6}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section3>
            </div>
          );
          break;
        case 6:
          sectionData = (
            <div className="section-class-container" onClick={()=>this.shouldWeRerenderBasedOnImportant()}>
              <Section4
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_ills_data_p_4")}
                sectionNumber={7}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section4>
            </div>
          );
          break;
        case 7:
          sectionData = (
            <div className="section-class-container">
              <Section5
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_comnunication_data_p_5")}
                sectionNumber={8}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section5>
            </div>
          );
          break;
        case 8:
          sectionData = (
            <div className="section-class-container">
              <Section6
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_orientation_data_p_6")}
                sectionNumber={9}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section6>
            </div>
          );
          break;
        case 9:
          sectionData = (
            <div className="section-class-container">
              <Section8
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_movement_data_p_8")}
                sectionNumber={10}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section8>
            </div>
          );
          break;
        case 10:
          sectionData = (
            <div className="section-class-container" onClick={()=>this.shouldWeRerenderBasedOnImportant()}>
              <SectionPflegedienstSimple
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_pflegedienst_data_p_14")}
                sectionNumber={11}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionPflegedienstSimple>
            </div>
          );
          break;
        case 11:
          sectionData = (
            <div className="section-class-container" onKeyPress={()=>this.shouldWeRerenderBasedOnImportant()}>
              <Section16
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_expectations_data_p_16")}
                sectionNumber={12}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section16>
            </div>
          );
          break;
        case 12:
          sectionData = (
            <div className="section-class-container" onClick={()=>this.shouldWeRerenderBasedOnImportant()}>
              <Section17
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_actions_data_p_17")}
                sectionNumber={13}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></Section17>
            </div>
          );
          break;
        case 13:
          sectionData = (
            <div className="section-class-container">
              <SectionBeschreibung
                isImportant= {isImportant}
                showValidationErrors={showValidationErrors}
                subsectionName={translateKey("bb_place_data_p_18")}
                sectionNumber={14}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
                finishMe={this.finishMe}
              ></SectionBeschreibung>
            </div>
          );
          break;
        /* hidden due to FP-331
        case 14:
          sectionData = (
            <div className="section-class-container">
              <SectionInitialOffer
                form={form}
                rerender={rerender}
                sectionNumber={14}
                toggleAnalizeLoader={this.toggleAnalizeLoader}
                prevPage={this.state.prevPageNumber}
                toggleOffAnalizer={this.toggleOffAnalizer}
                disabled={isFormDisabled}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
              ></SectionInitialOffer>
            </div>
          );
          break;
        */
        case 14:
            sectionData = (
              <div className="section-class-container">
                <MultiPatientModal 
                  show={ this.state.showMultiPatientModal } 
                  accept={ this.acceptMultiplePatients } 
                  cancel={ this.cancelMultiplePatients } 
                  redirect={ this.redirectToSecondPatient }
                  translateKey={ item => translateKey(item) }
                  secondPatientName={ secondPatientName }
                  showRedirection={ this.state.showRedirection }
                />
                <SectionKundeFinished
                  subsectionName={translateKey("zone-text-ende-der-fragen")}
                  rerender={rerender}
                  analizeFinished={analizeFinished}
                  currentSection={currentSection}
                  previousPageClick={this.previousPageClick}
                  nextPageClick={this.nextPageClick}
                  finishMe={this.finishMe}
                ></SectionKundeFinished>
              </div>
            );
            break;
        default:
          sectionData = (
            <div className="section-class-container">
              <RodoSection1
                showValidationErrors={showValidationErrors}
                sectionNumber={1}
                form={form}
                rerender={rerender}
                disabled={isFormDisabled}
                activeSectionNumber={currentSection}
                analizeFinished={analizeFinished}
                currentSection={currentSection}
                previousPageClick={this.previousPageClick}
                nextPageClick={this.nextPageClick}
                formLoaded = {!modalLoader}
              ></RodoSection1>
            </div>
          );
      }
    }
    const content = (
      <React.Fragment>
        <SectionNavigationToolbarComponent 
          rerender={rerender} 
          showSectionValidation = {true} 
          changeSection={(number)=>this.changePage(number)} 
          toggleSectionMenu={this.toggleSectionMenu} 
          hideSectionMenu={this.hideSectionMenu}
          form={form} 
          currentSection={currentSection}
          sectionMax={FormConfig.finalPageNumber}
          sectionMenuOpen={sectionMenuOpen}
          isUnderAnalize = { this.state.analizeFinished }
          hidden={true}
        />
        {!sectionMenuOpen && sectionData }
      </React.Fragment>
    )
    return content;
  }

  shouldWeRerenderBasedOnImportant(){
    if (this._isMounted) {
      this.setState({ rerender: Date.now()});
    }
  }

  toggleSectionMenu = () =>{
    this.setState(prevState => {
      return {
          ...prevState,
          sectionMenuOpen: !prevState.sectionMenuOpen,
          prevPageNumber: prevState.currentSection===12?13:prevState.currentSection
      }
    })
  }

  hideSectionMenu = () =>{
    this.setState(prevState => {
        return {
            ...prevState,
            sectionMenuOpen: false,
            prevPageNumber: prevState.currentSection===12?13:prevState.currentSection
        }
      })
  }

  setSecondPatient = () => {
    const { patientLeadData } = this.props;
    const { patientId } = this.state;

    if(patientLeadData.length > 0) {
      const allPatientsforLead = patientLeadData.filter(element => ( element.leadId === this.state.LKId) );
      if (allPatientsforLead.length > 1) {
        const secondPatientId = allPatientsforLead[1].id !== patientId ? allPatientsforLead[1].id : allPatientsforLead[0].id;
        this.getSecondPatientName(secondPatientId);
        this.setState({
          showRedirection: true,
          secondPatientId: secondPatientId,
          loadedSecondPatientData: true,
        });
      } else {
        this.setState({
          loadedSecondPatientData: true,
        });
      }
    }
  }

  redirectToSecondPatient = () => {
    const { secondPatientId } = this.state;

    this.setState({
        patientId: secondPatientId,
        showMultiPatientModal: false,
        multiplePatients: true,
    });
    this.prepareMeForm(secondPatientId);
  }

  sendSecondPatient = (patientid) => {
    const { appZoneToken, sendSecondPatientToDB } = this.props;

    const dataToSend = {
      'secondPatientId': patientid,
    };

    sendSecondPatientToDB(appZoneToken, dataToSend)
      .then(()=>{
        console.log('success');
      })
      .catch((err)=>{
        console.log(err);
      }
    )
  }

  acceptMultiplePatients = async (firstName, lastName) => {
    const { appUserId } = this.props;
    const { LKId, patientId } = this.state;
    const dataToSend = [{
            "Id": "0",
            "Entity": "is_patient",
            "Fields": {
                "ownerid": appUserId,
                "is_contact_id": LKId,
                "is_last_section": "rodo_section_1",
                "is_first_name_and_last_name_of_patient": `${firstName} ${lastName}`
            }
        },
        {
            "Id": LKId,
            "Entity": "contact",
            "Fields": {
                "is_patient_last_name": lastName,
                "is_patient_first_name": firstName,
            }
        }];
    try{
      const response = await BBFormService.savePatient(dataToSend);
      let lkid = LKId?LKId:'0';
      let patientid = patientId?patientId:'0';
      const { data } = response;
      data.forEach(element => {
        if (element.entity === Entity.PATIENT) {
          patientid = element.id;
        } else {
          if (element.entity === Entity.CONTACT) {
            lkid = element.id;
          }
        }
      });

      this.setState({
        LKId: lkid,
        patientId: patientid,
        showMultiPatientModal: false,
        multiplePatients: true,
      });
      this.prepareMeForm(patientid);
      this.sendSecondPatient(patientid);
    } catch (e) {
      console.log(e);
    }
  }

  cancelMultiplePatients = () => { 
    this.setState({
      showMultiPatientModal: false,
    });
  }

  getSecondPatientName = (id) => {
    let dataToSend = [
      {
          "Id": id,
          "Entity": Entity.PATIENT,
          "Fields": [
            'is_first_name_and_last_name_of_patient',
          ]
      }
    ];

    getDynamicFields(dataToSend).then(res => {
      this.setState({
          secondPatientName: res[0].fields.is_first_name_and_last_name_of_patient
      })
    })
    .catch((error) => {
        console.log(error);
    });
  }

  render() {
    const { formbbStatus, errorComponent, editedForm, form, loadedSecondPatientData } = this.state;
    const { translateKey, patientLeadData } = this.props;
    const isFormDisabled = FormConfig.notEditableLeadStatuses.indexOf(formbbStatus.toString()) !== -1;
    const patientName = form.getFieldByKey('is_first_name_and_last_name_of_patient').currentValue;
    if(patientLeadData.length > 0 && !loadedSecondPatientData){
      this.setSecondPatient();
    }
    return (
      <div onClick={() => {this.props.tookAction()}}>
        <NavBar patientName={patientName} />
        <div className="section-slider" style={{ backgroundImage: `url(${IconsMap.other.background_zone})`, backgroundPosition: "top" }}>
          <div className="container">
            <div className="zone-slider-text">
              {translateKey("zone_main_text")}
            </div>
          </div>
        </div>
        <div className="container zone-content-wrapper no_padding">
          <div className="zone-content-form-wrapper">
            <LoaderModal show={this.state.savingData || this.state.modalLoader} text={this.state.savingData?translateKey("saving_with_dots"):''}/>
            <div className="zone-form-wrapper">
              {this.getDataBasic(isFormDisabled)}
              <AlertModalInfo size="lg" centeredAlert={true} show={errorComponent.show} key="save-success-fail-message" type={errorComponent.type} message={errorComponent.message}/>
            </div>
          </div>
        </div>
        <Prompt
            when={editedForm}
            message={translateKey("unsaved_data_lost")}
        />
        <FooterComponent/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    leadsData: state.database.leads,
    leadsStatus: state.enums.leadStatus,
    leadsStatusStatus: state.enums.leadStatusStatus,
    patientLeadData: state.database.patientLead,
    appZoneId: state.auth.appZoneId,
    appZoneClientId: state.auth.appZoneClientId,
    appZoneToken: state.auth.appZoneToken,
    appZoneName: state.auth.appZoneName,
    secondPatitent: state.auth.secondPatient,
    appZoneSurname: state.auth.appZoneSurname,
    appZonePatient: state.auth.appZonePatient,
    appUserId: state.auth.appUserId,
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  tookAction: () => dispatch(authSetActionTimestamp()),
  setLeadsData: (data) => dispatch(setLeadsData(data)),
  getPatientLeadData: (lang) => dispatch(getPatientLead(lang)),
  pendingRequestLeads: () => dispatch(pendingLeadsData()),
  clearDataLeads: () => dispatch(removeLeadsData()),
  getProducts: () => dispatch(getUserProducts()),
  getRegions: () => dispatch(getRegionsFP()),
  getStatusLead: (lang) => dispatch(getLeadStatusData(lang)),
  removeLeadsStatus: () => dispatch(removeLeadStatusData()),
  finishKunde: (token, data) => dispatch(userFinishedKunde(token, data)),
  sendSecondPatientToDB: (token, data) => dispatch(confirmSecondPatient(token, data)),
  updatePatientID: (token, user_id, patient_id) => dispatch(authSaveAndUpdatePatient(token, user_id, patient_id))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPatientWrapper));
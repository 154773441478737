import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";
import { IconsMap } from "../../../_helpers/index";
import GenderElement from "./genderElement";
import * as SkillRange from "../../../_forms/BBForm/values/skillRange";
import { Asterisk } from "../../../_helpers/field_asterix";
const IS_WEIGHT = "is_weight";
const IS_CANDIDATE_GENDER = "is_candidate_gender";
const keyName=  'is_candidate_gender';

class ListPflegegradComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rerender: Date.now()
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const { section } = this.props;
        const patientWeight = section.form.getFieldByKey(IS_WEIGHT);
        if (parseInt(patientWeight.value) >= 80) {
            this.props.setTypeChangeToParent(IS_CANDIDATE_GENDER, SkillRange.UNLIMITED);
            this.setState({rerender: Date.now()})
        }
    }

    setTypeChange = (value) => {
        this.props.setTypeChangeToParent(keyName, value);
    };

    render() {
        const { section, showValidation, className, translateKey, newUI } = this.props;
        const isCandidateGender = section.getFieldByKey(keyName);
        const isCandidateGenderValue = isCandidateGender.value.toString();
        const showErrors = showValidation && isCandidateGender.validationErrors.length > 0;
        return (
          <div className={`${className} list-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
            <label className={`form-label-basic ${newUI? 'newUI':''}`}>
                <span className={`quick-label-position-fix }`}>
                    {translateKey('form-text-section-17-01').toUpperCase()} <Asterisk isRequired={isCandidateGender.isRequired}/>
                </span>
            </label>
            <div className={`gender-wrapper`}>
                <GenderElement
                    isActive={isCandidateGenderValue===SkillRange.PARTIAL}
                    icon={isCandidateGenderValue===SkillRange.PARTIAL?IconsMap.other.gender_female_white:IconsMap.other.gender_female}
                    value={SkillRange.PARTIAL}
                    changeMeValue={this.setTypeChange}
                    text={translateKey('form-text-frau')}
                />

                <GenderElement
                    isActive={isCandidateGenderValue===SkillRange.UNLIMITED}
                    icon={isCandidateGenderValue===SkillRange.UNLIMITED?IconsMap.other.gender_male_white:IconsMap.other.gender_male}
                    value={SkillRange.UNLIMITED}
                    changeMeValue={this.setTypeChange}
                    text={translateKey('form-text-mann')}
                />

                <GenderElement
                    isActive={isCandidateGenderValue===SkillRange.LIMITED}
                    icon={isCandidateGenderValue===SkillRange.LIMITED?IconsMap.other.gender_other_white:IconsMap.other.gender_other}
                    value={SkillRange.LIMITED}
                    changeMeValue={this.setTypeChange}
                    text={translateKey('form-text-irrelevant')}
                />
            </div>
            {showErrors && (
              <ValidationErrorsList
                errors={isCandidateGender.validationErrors}
                keyName={keyName}
              />
            )}
          </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default withRouter(connect(null, mapDispatchToProps)(ListPflegegradComponent));
import axios from 'axios';
import ReqHelper from '../../_helpers/request_helper';
import {baseData} from '../../_constance/base_data';

const request = axios.create({
    baseURL: baseData.base_backend
});

request.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(ReqHelper.responseErrorHelper(error));
});


export default request;
import { baseData } from '../_constance/index'
class ReqHelper{
    static responseErrorHelper(error){
        let errorObj = { code: error, message: 'basic_error_message'};
        switch (error) {
            case 400: errorObj.message = 'api_call_error_400'; break;
            case 401: errorObj.message = 'api_call_error_401'; break;
            case 403: errorObj.message = 'api_call_error_403'; break;
            case 404: errorObj.message = 'api_call_error_404'; break;
            case 405: errorObj.message = 'api_call_error_405'; break;
            case 500: errorObj.message = 'api_call_error_500'; break;
            case 801: errorObj.message = 'basic_error_message'; break;
            case 999: errorObj.message = 'error_connection_failed'; break;
            default: //console.log(errorObj);
        }
        return errorObj;
    }
    static rsaPasswordMethod(pass){
        var encrypt = new window.JSEncrypt();
        encrypt.setPublicKey(baseData.public_key);
        var encrypted = encrypt.encrypt(pass);
        return encrypted
    }
    static getMeFilersObj(filersData){
        let selectedObj = [];
        for(let i=0; i<filersData.length; i++){
            let FilerFiled = {
                name: filersData[i].filterName,
                key: filersData[i].filterKey?filersData[i].filterKey : null,
                type: filersData[i].filterType?filersData[i].filterType : null,
                values: [],
            }
            for(let j=0; j<filersData[i].filterOptions.length; j++){
                if(filersData[i].filterOptions[j].active===true){
                    FilerFiled.values.push(filersData[i].filterOptions[j]);
                }
            }
            if(FilerFiled.values.length>0){
                selectedObj.push(FilerFiled);
            }
        }
        return selectedObj;
    }
    static dateConversion(timestamp, withMiliseconds=true){
        if(timestamp){
            if(timestamp!==null){
                let today = new Date(timestamp*1000);
                if(!withMiliseconds){
                    today = new Date(timestamp);
                }
                let dd = today.getDate();
                let mm = today.getMonth() + 1;
                let yyyy = today.getFullYear();
                if (dd < 10) {
                dd = '0' + dd;
                } 
                if (mm < 10) {
                mm = '0' + mm;
                } 
                let converted = dd + '.' + mm + '.' + yyyy;
                return converted;
            }else{
                return null
            }
        }else{
            return null
        }
    }
    static dateConversionTime(timestamp, withMiliseconds=true){
        if(timestamp){
            if(timestamp!==null){
                let dateM = new Date(timestamp*1000);
                if(!withMiliseconds){
                    dateM = new Date(timestamp);
                }
                let hours = dateM.getHours();
                let minutes = dateM.getMinutes();
                if (hours < 10) {
                    hours = '0' + hours;
                } 
                minutes = minutes < 10 ? '0'+minutes : minutes;
                let strTime = hours + ':' + minutes
                return strTime;
            }else{
                return null
            }
        }else{
            return null
        }
    }
    static invoiceLinesSortByGroup(data){
        let result = [];
        result["live"] = [];
        result["discounts"] = [];
        result["other_services"] = [];
        for(let i=0; i<data.length; i++){
            let group = ReqHelper.invoiceLinesGroup(data[i].Type);
            result[group].push(data[i]);
        }
        result.forEach(function(elements){
            elements.sort((a, b) => b.StartDate - a.StartDate)
        });
        return result;
    }
    static isEmail(email){
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
    static invoiceLinesGroup(type){
        switch(type){
            case "Linie: Vertrag":
            case "Linie: Pausen":
                return "live";
            case "Linie: Feiertage":
            case "Linie: Zulage":
                return "other_services";
            case "Linie: Abzuge":
                return "discounts";	
            default: return "live"
        }
    }
    static valueExistsInObj(obj, value){
        if(obj!==null){
            if(typeof obj === 'object'){
                for(var key in obj) {
                    if(typeof obj[key] === 'object'){
                        let exists = ReqHelper.valueExistsInObj(obj[key], value);
                        if(exists){
                            return true;
                        }
                    }else{
                        if(obj[key]){
                            if(obj[key].toString().toLowerCase().indexOf(value.toString().toLowerCase())!==-1) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }
    static valueExistsInObjBasedOnSorting(obj, value, sorting, lang){
        for(let i=0; i< sorting.length; i++){
            if(sorting[i].blocked === false && sorting[i].enabled === true && ReqHelper.sameLanguageRestriction(sorting[i].languageRestrictions, lang)){
                let finalValue = obj[sorting[i].column];
                if(sorting[i].column.indexOf('.')!==-1){
                    let dotedString = sorting[i].column.split(".");
                    finalValue = ReqHelper.stringBuilderByDot(obj, dotedString);
                }
                if(finalValue){
                    if(finalValue.toString().toLowerCase().indexOf(value.toString().toLowerCase())!==-1) {
                        return true;
                    }
                }
            }
        }
        return false
    }
    static stringBuilderByDot(obj, dotedString){
        if(dotedString){
            if(dotedString.length>1){
                let answer = obj;
                for(let i=0; i<dotedString.length; i++){
                    if(answer[dotedString[i]]){
                        answer = answer[dotedString[i]];
                    }else{
                        answer = '';
                        break;
                    }
                }
                return answer;
            }else{
                return obj[dotedString]
            }
        }else{
            return null;
        }
    }
    static sameLanguageRestriction(languageRestrictions, lang){
        if(languageRestrictions!==null){
            if(lang === languageRestrictions){
                return true;
            }else{
                return false;
            }
        }else{
            return true;
        }
    }
    static numberWithSpaces(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    }
    static isNumber(value){
        return typeof value === 'number' && 
              isFinite(value) && 
              Math.floor(value) === value;
    }
    static isInteger(value) {
        return +value===parseInt(value);
    }
    static getMeLeadStatusText(status, statusList){
        if(statusList){
            let statusObj = statusList.filter(element=>element.Value===status.toString())
            if(statusObj.length!==0){
                return statusObj[0].Label;
            }else{
                return '';
            }
        }else{
            return '';
        }
    }
    static getMeLeadTextStatus(text, statusList){
        if(statusList){
            let statusObj = statusList.filter(element=>element.Label===text)
            if(statusObj.length!==0){
                return statusObj[0].Value;
            }else{
                return '';
            }
        }else{
            return '';
        }
    }
    static flattenFields = (fieldsList) => {
        return fieldsList.reduce((previous, field) => Object.assign(previous, {
            [field.key]: field.value,
        }), {})
    }
    static flattenFieldsArray = (fieldsList) => {
        return fieldsList.map(field => field.key);
    }
    static checkNotNullAndNotEmptyString = (field) => {
        if(field){
            if(field!==null){
                if(field.trim()!==''){
                    return true;
                }
            }
        }
        return false
    }
    static valueFormatter = (value) => {
        if (value.indexOf('.') > -1){
            let stringSplit = String(value).split('.');
            if(stringSplit.length>1){
                if(stringSplit[0].trim()!==''){
                    return stringSplit[0]+'.'+stringSplit[1].substring(0, 2);
                }else{
                    return 0;
                }
            }else{
                return 0;
            }
        }else{
            if (value.indexOf(',') > -1){
                let stringSplit = String(value).split(',');
                if(stringSplit.length>1){
                    if(stringSplit[0].trim()!==''){
                        return stringSplit[0]+','+stringSplit[1].substring(0, 2);
                    }else{
                        return 0;
                    }
                }else{
                    return 0;
                }
            }else{
                return value;
            }
        }
    }
    static simpleValueFormatter = (value) => {
        if (value.indexOf('.') > -1){
            return String(value).split('.')[0];
        }else{
            if (value.indexOf(',') > -1){
                return String(value).split(',')[0];
            }else{
                return value;
            }
        }
    }
    static sortMeData = (data, sorting) => {
        let sortMeBy;
        for(let i=0; i<sorting.length; i++){
            if(sorting[i].active===true){
                sortMeBy = sorting[i];
            }
        }
        if(sortMeBy){
            if(sortMeBy.format === "text"){
                data.sort((a,b)=>{
                    let nameA = a[sortMeBy.column];
                    let nameB=b[sortMeBy.column];
                    if(sortMeBy.column.indexOf('.')!==-1){
                        let dotedString = sortMeBy.column.split(".");
                        nameA = ReqHelper.stringBuilderByDot(a, dotedString);
                        nameB= ReqHelper.stringBuilderByDot(b, dotedString);
                    }
                    if(!ReqHelper.isNumber(parseInt(a[sortMeBy.column]))){
                        if(nameA){
                            nameA=nameA.toString().toLowerCase()
                        }
                        if(nameB){
                            nameB=nameB.toString().toLowerCase()
                        }
                    }else{
                        nameB = nameB?nameB.toString():nameB;
                        nameA = nameA?nameA.toString():nameA;
                    }
                    if(sortMeBy.type!=="ASC"){
                        if (nameA === null || nameA === undefined ) {return -1;}
                        else if (nameB === null || nameB === undefined ) {return 1;}
                        else if (nameA === nameB) {return 0;}
                        if (nameA.localeCompare(nameB)===-1){ return -1; }
                        else{ return 1; }
                    }else{
                        if (nameA === null || nameA === undefined ) { return 1;}
                        else if (nameB === null || nameB === undefined ) { return -1;}
                        else if (nameA === nameB) { return 0;}
                        if (nameB.localeCompare(nameA)===-1){ return -1;}
                        else{ return 1; }
                    }
                })
            }else{
                if(sortMeBy.format === "date"){
                    data.sort((a,b)=>{
                        let nameA = a[sortMeBy.column]*1000;
                        let nameB=b[sortMeBy.column]*1000;
                        if(sortMeBy.column.indexOf('.')!==-1){
                            let dotedString = sortMeBy.column.split(".");
                            nameA = ReqHelper.stringBuilderByDot(a, dotedString)*1000;
                            nameB= ReqHelper.stringBuilderByDot(b, dotedString)*1000;
                        }
                        if(sortMeBy.type!=="ASC"){
                            if (nameA === null || nameA === undefined ) {return -1;}
                            else if (nameB === null || nameB === undefined ) {return 1;}
                            else if (nameA === nameB) {return 0;}
                            if (nameA < nameB){ return -1; }
                            else{ return 1; }
                        }else{
                            if (nameA === null || nameA === undefined ) {return 1;}
                            else if (nameB === null || nameB === undefined ) {return -1;}
                            else if (nameA === nameB) {return 0;}
                            if (nameA > nameB){return -1;}
                            else{ return 1; }
                        }
                    })
                }
            }
        }
        return data;
    }
    static dateBetweenDates = (date, from, to) => {
        if((date <= to && date >= from)){
            return true
        }else{
            return false;
        }
    }
    static prepareMeLoginName = (name) => {
        if(name !== undefined && name !==null && name.length>0){
            let newString = name[0].toUpperCase() + name.slice(1);
            newString = newString.replace(/-/g, " ");
            return newString;
        }else{
            return '---';
        }
    }
    static notNullAndUndefinied = (value) => {
        if(value !== null && value !== undefined){
            return true;
        }else{
            return false;
        }
    }
    static prepareMeNewColumnToSave = (name, data, justColumns=false) => {
        let columns = data.map((field) => {
            return {
                "enabled": field.enabled,
                "name": field.keyName
            }
        });
        if(justColumns){
            return columns
        }else{
            return {
                name: name,
                columns: columns
            }
        }
    }
    static percentage = (partialValue, totalValue)=>{
        return ((100 * partialValue) / totalValue).toFixed(0);;
    }
    static simple_password_generator = (len=6) => {
        const lenPass = len;
        let stringSmall = "abcdefghijklmnopqrstuvwxyz";
        let stringBig = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let numeric = '0123456789';
        let password = "";
        let character = "";
        while( password.length<lenPass ) {
            let entity1 = Math.ceil(stringSmall.length * Math.random()*Math.random());
            let entity2 = Math.ceil(stringBig.length * Math.random()*Math.random());
            let entity3 = Math.ceil(numeric.length * Math.random()*Math.random());
            character += stringSmall.charAt( entity1 );
            character += stringBig.charAt( entity2 );
            character += numeric.charAt( entity3 );
            password = character;
        }
        password=password.split('').sort(function(){return 0.5-Math.random()}).join('');
        return password.substr(0,len);
    }
    static simpleSortByKey = (array, key) =>{
        return array.sort((a, b)=>{
            let x = a[key];
            let y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    static detectMob = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
    
        return toMatch.some((toMatchItem) => {
          if(navigator.userAgent.match(toMatchItem)){
            return true
          }else{
            if(ReqHelper.isIpad()){
              return true
            }
          }
          return false;
        });
    }
    
    static isIpad() {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('iPad') > -1) {
            return true;
        }
        if (ua.indexOf('Macintosh') > -1) {
            try {
                document.createEvent("TouchEvent");
                return true;
            } catch (e) {}
        }
        return false;
    }

    static prepareMeListFromString (list, separator=';'){
        if (list && list !== null) {
          if (list.length > 0) {
            let arrList = list.split(separator);
            return arrList;
          }
        }
        return [];
    }
}

export default ReqHelper;
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { IconsMap } from "../../../_helpers/index";
import * as Relationship from "../../../_forms/BBForm/values/relationship";
import produce from "immer";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
const isRodoFirstChoiceKey = "rodo_first_choice";
const isDegreeRelationship = 'is_degree_relationship_with_patient_carer';

class RodoSection1 extends React.Component {
    _isMounted = false;
    timeOutHelper;
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-rodo-init')
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.waitForDataToBeReady()
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.myTimeout);
    };

    waitForDataToBeReady = () =>{
        const { firstInit, toggleFirstInit, formLoaded } = this.props;
        if(formLoaded && firstInit){
            toggleFirstInit();
            this.getInitialData();
        }else{
            this.timeOutHelper = setTimeout(() => {
                this.waitForDataToBeReady();
            }, 100);
        }
    }

    getInitialData (){
        const { form } = this.props;
        const isDegreeRelationShipWithPatientCarer = form.getFieldByKey(isDegreeRelationship);
        let valueOfField =  null;
        if (isDegreeRelationShipWithPatientCarer.value === Relationship.TYPE_8 ) {
            valueOfField = Relationship.TYPE_8;
        }else{
            if(isDegreeRelationShipWithPatientCarer.value!==null && isDegreeRelationShipWithPatientCarer.value!==''){
                valueOfField = Relationship.TYPE_0;
            }
        }
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(isRodoFirstChoiceKey);
            if (field) {
                field.value = valueOfField;
            }
        });
        if (this._isMounted) {
            this.setState(nextState);
        }
    }

    setTypeChange(value){
        if(!this.props.disabled && !this.props.rodoDisabled){
            const { form } = this.props;
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(isRodoFirstChoiceKey);
                if (field) {
                    field.value = value;
                }
            });
            if(value===Relationship.TYPE_8){
                form.setFieldsByKeys([isDegreeRelationship], Relationship.TYPE_8)
            }else{
                form.setFieldsByKeys([isDegreeRelationship], null)
            }
            this.setState(nextState);
        }
    };

    setFranchiseChange = (key, value) => {
        if(!this.props.disabled){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });
            this.setState(nextState);
        }
    };


    render() {
        const {
          disabled,
          subsectionName,
          translateKey,
          rodoDisabled,
          showValidationErrors,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
        } = this.props;
        const { section } = this.state;
        const isRodoFirstChoice = section.getFieldByKey("rodo_first_choice");
        const disabledField = disabled || rodoDisabled;
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper container">
                    {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabledField}>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <div className={`rodo-content-wrapper-item ${isRodoFirstChoice.value===Relationship.TYPE_8?'active':''} ${disabledField?'':'cursor-pointer'}`} onClick={()=>this.setTypeChange(Relationship.TYPE_8)}>
                                        <div className="icon">
                                            <img className="pflegegrad-icon" src={isRodoFirstChoice.value===Relationship.TYPE_8?IconsMap.other.rodo_human_white:IconsMap.other.rodo_human_gray} alt="Icon"/>
                                        </div>
                                        <div className="text">
                                            {translateKey('form-rodo1-text-option1')}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <div className={`rodo-content-wrapper-item ${isRodoFirstChoice.value===Relationship.TYPE_0?'active':''} ${disabledField?'':'cursor-pointer'}`} onClick={()=>this.setTypeChange(Relationship.TYPE_0)}>
                                        <div className="icon">
                                            <img className="pflegegrad-icon" src={isRodoFirstChoice.value===Relationship.TYPE_0?IconsMap.other.rodo_human_chair_white:IconsMap.other.rodo_human_chair_gray} alt="Icon"/>
                                        </div>
                                        <div className="text">
                                            {translateKey('form-rodo1-text-option2')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showValidationErrors && (isRodoFirstChoice.value===null || isRodoFirstChoice.value==='') &&
                                <div className="row" id="rodo_first_choice_error_id">
                                    <div className="col-sm-12 is-invalid">
                                        <div className="invalid-feedback">
                                            <div>{translateKey(`form-text-err_required`)}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <div className="col-sm-12 rodo-description">
                                    <div className="rodo-section">
                                        <div>{translateKey('form-rodo1-text-paragraph1')}</div>
                                        <div>- {translateKey('form-rodo1-text-paragraph2')}</div>
                                        <div>- {translateKey('form-rodo1-text-paragraph3')}</div>
                                    </div>
                                    <ul className="rodo-itme-list">
                                        <li>
                                            {translateKey('form-rodo1-text-paragraph4')}
                                            <a href={translateKey('form-rodo1-text-page')} target="_top">{' ' + translateKey('form-rodo1-text-page')}</a>
                                        </li>

                                        <li>
                                            {translateKey('form-rodo1-text-paragraph5')}
                                            <a href={`mailto: ${translateKey('form-rodo1-text-email')}`} target="_top">{' ' + translateKey('form-rodo1-text-email')}</a>
                                        </li>

                                        <li>
                                            {translateKey('form-rodo1-text-paragraph6')}
                                        </li>

                                        <li>
                                            {translateKey('form-rodo1-text-paragraph7')}
                                        </li>

                                        <li>
                                            {translateKey('form-rodo1-text-paragraph8')}
                                            <ul>
                                                <li>{translateKey('form-rodo1-text-paragraph8-option1')}</li>
                                                <li>{translateKey('form-rodo1-text-paragraph8-option2')}</li>
                                                <li>{translateKey('form-rodo1-text-paragraph8-option3')}</li>
                                            </ul>
                                        </li>

                                        <li>
                                            {translateKey('form-rodo1-text-paragraph9')}
                                        </li>

                                        <li>
                                            {translateKey('form-rodo1-text-paragraph10')}
                                            <ul>
                                                <li>{translateKey('form-rodo1-text-paragraph10-option1')}</li>
                                                <li>{translateKey('form-rodo1-text-paragraph10-option2')}</li>
                                            </ul>
                                        </li>

                                        <li>
                                            {translateKey('form-rodo1-text-paragraph11')}
                                            <ul>
                                                <li>{translateKey('form-rodo1-text-paragraph11-option1')}</li>
                                                <li>
                                                    {translateKey('form-rodo1-text-paragraph11-option2-part1')}
                                                    <a href={`mailto: ${translateKey('form-rodo1-text-email')}`} target="_top">{' ' + translateKey('form-rodo1-text-email')} </a>
                                                    {translateKey('form-rodo1-text-paragraph11-option2-part2')}
                                                    <a href={`tel: ${translateKey('form-rodo1-text-phone')}`} target="_top">{' ' + translateKey('form-rodo1-text-phone')} </a>
                                                    {translateKey('form-rodo1-text-paragraph11-option2-part3')}
                                                </li>
                                                <li>{translateKey('form-rodo1-text-paragraph11-option3')}</li>
                                            </ul>
                                        </li>

                                        <li>
                                            {translateKey('form-rodo1-text-paragraph12')}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(RodoSection1));
import React from 'react';
import { connect } from 'react-redux';

import { translateKey } from "../../_redux/actions";

const ValidationErrorsList = (props) => {
    const { errors, keyName, translateKey } = props;
    return (
        <div className="invalid-feedback" id={`${keyName}_error_id`}>
            { errors.map(error => (
                <div key={`${keyName}-error-${error}`}>{translateKey(`form-text-${error}`)}</div>
            ))}
        </div>
    );
};

ValidationErrorsList.defaultProps = {
    errors: [],
    keyName: '',
};

const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default connect(null, mapDispatchToProps)(ValidationErrorsList);

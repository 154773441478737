import * as React from 'react';

class GenderElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    changeValue = (value) =>{
        this.props.changeMeValue(value);
    }

    render() {
        const { isActive, icon, text, value } = this.props
        return (
            <div className={`gender-element-class ${isActive?'active':''}`} onClick={()=>this.changeValue(value)}>
                <img className="gender-icon-newUI" src={icon} alt="Icon"/>
                <div className="gender-text-newUI">{text}</div>
            </div>
        );
    }
}
export default GenderElement;
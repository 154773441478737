import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  translateKey,
  getMarketingData,
  getRejectReasonData,
  setMultipliersData,
  pendingMultipliersData,
  removeMultipliersData
} from "../../_redux/actions/index";
import DatePickerComponent from "../../_components/date-picker-component/DatePickerComponent";
import SelectOptionsComponent from "../../_components/select-options-component/SelectOptionsComponent";
import { Rating } from "../../_constance/enums/ratingLead"
import { MultiplierTable } from "../../_constance/classes/index";
import * as LanguageSelect from "../../_constance/values/languageSelect";
import * as LeadStatusExceptions from "../../_constance/values/LeadStatusExceptions";
import {Toggle} from "react-toggle-component"
import { refreshTokenTime, baseData } from '../../_constance/base_data';
import { shouldRefresh } from "../../_helpers/index";
import axiosAuth from "../../_services/config/axios-auth";
class LeadDetailsBasicComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            edited: false
        };
    }

    componentDidMount() {
      document.body.classList.remove('modal-open');
      window.scrollTo(0, 0);
      this.getMultiDataBasic();
    }

    getMultipliersList = () => {
      this.props.pendingRequest();
      axiosAuth.get("multipliers/list/0")
        .then(res => { if (res.status === 200) { return res; } else { return Promise.reject(res.status); } })
        .then(json => { return json.data })
        .then(res => {
          let mapedRes = res.map((element)=>{return new MultiplierTable(element);});
          this.props.setData(mapedRes);
        })
        .catch((error) => {
          this.props.clearData();
        });
    }
  
    getMultiDataBasic() {
      const lastUpdate = this.props.multipliersTimeStamp;
      const actualTime = new Date().getTime();
      if (shouldRefresh(actualTime, lastUpdate, refreshTokenTime.refreshBasicTime)) {
        this.getMultipliersList();
      }
    }

    handleChangeSelect = (key, value) =>{
      this.props.element[key] = value;
      this.setState(prevState => {
          let elem = {...prevState.element}
          elem[key] = value;
          return {
              ...prevState,
              element: elem
          }
      });
      this.props.editedData();
    }

    handleChangeMulti = (value) =>{
      this.props.editedMulti(value);
    }

    handleDateChange(key, event) {
      let timestamp = new Date(event).getTime();
      timestamp = timestamp/1000;
      this.props.element[key] = timestamp;
      this.setState(prevState => {
        let elem = {...prevState.element}
        elem[key] = timestamp;
        return {
          ...prevState,
          element: elem
        }
      });
      this.props.editedData();
    }

    handleChangeValueKeyNotArrow(key, value){
      this.props.element[key] = value;
      this.setState(prevState => {
        let elem = {...prevState.element}
        elem[key] = value;
        return {
          ...prevState,
          element: elem
        }
      });
      this.props.editedData();
    }
    
    createStatusSelect(){
        const {translateKey, disabled} = this.props;
        return (
            <React.Fragment>
              <select disabled={disabled} className={"custom-select col-sm-10 lead-input-wrapper "+(this.props.element.status.length<=2 && this.props.showValidation?"is-invalid": '')} onChange={(e)=>this.handleChangeSelect('status', e.target.value)} value={this.props.element.status}>
                <option hidden>{translateKey("choose_select")}</option>
                {this.props.leadsStatus.map((value) => {
                    if(this.props.appLang===LanguageSelect.DE){
                      let existsInException = LeadStatusExceptions.DE_LIST.filter(element=>element===value.Value)
                      if(value.Value === this.props.element.status){
                        return <option defaultValue key={value.Value} value={value.Value} hidden={existsInException.length===0}>{value.Label}</option>
                      }else{
                        return <option key={value.Value} value={value.Value} hidden={existsInException.length===0}>{value.Label}</option>
                      }
                    }else{
                      let existsInException = LeadStatusExceptions.EN_LIST.filter(element=>element===value.Value)
                      if(value.Value === this.props.element.status){
                        return <option defaultValue key={value.Value} value={value.Value} hidden={existsInException.length===0}>{value.Label}</option>
                      }else{
                        return <option key={value.Value} value={value.Value} hidden={existsInException.length===0}>{value.Label}</option>
                      }
                    }
                })}
              </select>
              <div className="invalid-feedback">{translateKey("form_contains_errors")}</div>
            </React.Fragment>
        )
    } 

    createMultiSelect(){
      const {translateKey, disabled} = this.props;
      return (
        <select disabled={disabled} className={"custom-select col-sm-10 lead-input-wrapper "} onChange={(e)=>this.handleChangeMulti(e.target.value)} value={this.props.multiId}>
          <option hidden>{translateKey("choose_select")}</option>
          {this.props.multipliersData.sort((a, b) => a.multiplier.localeCompare(b.multiplier)).map((value) => {
              return <option defaultValue={this.props.multiId===value.id} key={value.id} value={value.id}>{value.multiplier}</option>
          })}
        </select>
      )
    }
  
    prepareMeMarketingBasedOnLanguage(){
      const { marketingsList, appLang } = this.props;
      return marketingsList.filter(element=> {
        if(appLang==="EN"){
          return element.Market==="UK" || element.Market===appLang
        }else{
          return element.Market===appLang
        }
      })
    }

    toggleThisElement = () =>{
      this.props.element.leadExclusiveForPm24 = !this.props.element.leadExclusiveForPm24
      this.setState(prevState => {
        return {
          ...prevState,
          element: {
            ...prevState.element,
            leadExclusiveForPm24: !this.props.element.leadExclusiveForPm24
          }
        }
      });
      this.props.editedData();
    }

    getMeComment(){
      let comment = this.props.dynamics.is_lead_description;
      let result = comment.split("##");
      if(result.length===0){
        return '';
      }else{
        return result[result.length-1];
      }
    }

    render() {
      const {translateKey, appLang, leadsSourceStatus, marketingsList, rejectReasonsList, element, disabled,dynamics } = this.props;
        return (
            <div className="lead-first-row">
              <div className="col-sm-12 col-lg-5">
                <fieldset disabled={this.props.disabled}>
                  <div className="form-group row flexAlignCenter no-margin-row">
                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_entry")}</label>
                    <div className="col-sm-8 lead-input-wrapper">
                      <DatePickerComponent readOnly = {disabled} key="date_of_entry_01" timestamp={element.dateOfEntry!==null?element.dateOfEntry:''} withTime={false} onDateChange={(e)=>this.handleDateChange("dateOfEntry", e)}/>
                    </div>
                  </div>
                  {this.props.leadsStatus.length>0 && 
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className="col-sm-4 col-form-label form-label-basic">{translateKey("status")}</label>
                      <div className="col-sm-8">
                        {this.createStatusSelect()}
                      </div>
                    </div>
                  }
                  {leadsSourceStatus.length>0 &&
                    <SelectOptionsComponent 
                      disabled={disabled} 
                      chooseText={translateKey("choose_select")} 
                      setTypeChangeToParent={this.handleChangeSelect} 
                      valuesList = {leadsSourceStatus} 
                      keyName="source" 
                      value={element.source!==null?element.source.toUpperCase():''} 
                      mainText={translateKey("source_of_acquisition")}
                    />
                  }            
                  {element.source!==null && element.source === baseData.multiToLeadSelection &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className="col-sm-4 col-form-label form-label-basic">{translateKey("multiplier")}</label>
                      <div className="col-sm-8">
                        {this.createMultiSelect()}
                      </div>
                    </div>
                  }    
                  {appLang === LanguageSelect.EN && Rating.length>0 &&
                    <SelectOptionsComponent disabled={disabled} chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleChangeSelect} valuesList = {Rating} keyName="rating" value={element.rating!==null?element.rating:''} mainText={translateKey("rating")}/>
                  }       
                  {appLang === LanguageSelect.EN && marketingsList.length>0 &&
                    <SelectOptionsComponent disabled={disabled} chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleChangeSelect} valuesList = {this.prepareMeMarketingBasedOnLanguage()} keyName="marketingId" value={element.marketingId!==null?element.marketingId.toUpperCase():''} mainText={translateKey("marketing_type")}/>
                  }  
                  {rejectReasonsList.length>0 &&
                    <SelectOptionsComponent disabled={disabled} chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleChangeSelect} valuesList = {rejectReasonsList} keyName="reasonForRejection" value={element.reasonForRejection!==null?element.reasonForRejection:''} mainText={translateKey("rejection_reason")}/>
                  }  
                  {appLang === LanguageSelect.DE &&
                    <React.Fragment>
                      <div className="form-group row flexAlignCenter no-margin-row">
                          <label className="col-sm-4 col-form-label form-label-basic">{translateKey("rejection_details")}</label>
                          <div className="col-sm-8">
                            <input disabled = {disabled} type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("reasonsForRejectionDetails", e.target.value)} value={element.reasonsForRejectionDetails?element.reasonsForRejectionDetails:''}/>
                          </div>
                      </div>
                      <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("lead_exclusive")}</label>
                        <div className="col-sm-8 text-center">
                          <Toggle disabled={disabled} name={"toggle-lead-exclusive"} checked={element.leadExclusiveForPm24} onToggle={this.toggleThisElement}/>
                        </div>
                      </div>
                    </React.Fragment>
                  }  
                  {appLang === LanguageSelect.EN &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                      <label className="col-sm-4 col-form-label form-label-basic">{translateKey("date_of_first_interview")}</label>
                      <div className="col-sm-8 lead-input-wrapper">
                        <DatePickerComponent readOnly = {disabled} key="date_of_entry_01" timestamp={element.dateFirstInterview!==null?element.dateFirstInterview:''} withTime={false} onDateChange={(e)=>this.handleDateChange('dateFirstInterview', e)}/>
                      </div>
                    </div>
                  }
                  {dynamics.is_lead_description &&
                    <div className="form-group row flexAlignCenter no-margin-row">
                        <label className="col-sm-4 col-form-label form-label-basic">{translateKey("comments_from_internet_form")}</label>
                        <div className="col-sm-8">
                          <textarea rows="4" disabled = {true} type="text" className="form-control lead-input-wrapper" value={this.getMeComment()} style={{resize: 'none'}}/>
                        </div>
                    </div>
                  }
              </fieldset>
            </div>
          </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        appLang: state.lang.appLanguage,
        leadsStatus: state.enums.leadStatus,
        leadsSourceStatus: state.enums.leadSource,
        marketingsList: state.enums.marketingsList,
        marketingsListStatus: state.enums.marketingsListStatus,
        rejectReasonsList: state.enums.rejectReasonsList,
        rejectReasonsListStatus: state.enums.rejectReasonsListStatus,
        multipliersData: state.database.multipliers,
        multipliersTimeStamp: state.database.multipliersTimeStamp,
    };
  }
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)),
  getMarketingData: () => dispatch(getMarketingData()),
  getRejectReasonData: (lang) => dispatch(getRejectReasonData(lang)),
  setData: (data) => dispatch(setMultipliersData(data)),
  pendingRequest: () => dispatch(pendingMultipliersData()),
  clearData: () => dispatch(removeMultipliersData()),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeadDetailsBasicComponent));
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import { InputTypeTextComponent } from '../../form-bb-components/input-type-text-component/input-type-text-component';
import { CheckBoxSmallMultipleComponent } from '../../form-bb-components/checkbox-small-multiple-component/checkbox-small-multiple-component';
import { CheckBoxComponent } from '../../form-bb-components/checkbox-component/checkbox-component';
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
// tiles id
import { Tiles } from "../../../_constance/classes/dndPriorityOffer/TilesIds";
import ReqHelper from "../../../_helpers/request_helper";
import * as SkillRange from '../../../_forms/BBForm/values/skillRange';
import produce from "immer";

const IS_INITIAL_STAGES_KEY = 'is_initial_stages_of_dementia_list';
const IS_DEMENTIA_LIST_KEY = 'is_dementia_list';

const IS_DIABETES_KEY = 'is_diabetes';
const IS_DIABETES_MELLITUS_KEY = 'is_diabetes_mellitus_list';

const IS_INCONTINENCE_LIST_KEY = 'is_incontinence_list';
const IS_MULTIPLE_SCLEROSIS_THE_LIST_KEY = 'is_multiple_sclerosis_the_list';

const IS_STOMA_LIST_KEY  = 'is_stoma_list';

// condition fields
const IS_URINATION_CONTROL    = 'is_urination_control';
const IS_CONTROL_OF_DEFECATION  = 'is_control_of_defecation';
const IS_DIET_DIABETES  = "is_diet_diabetes";

const SKIP_SAVE_IS_DIET_DIABETES = 'skip_save_is_diet_diabetes'; 
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 

// filed to store tiles data
const KEY_NAME_TILES = 'is_most_important_taking_care_patient';

class Section4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-4'),
            showValidation: props.showValidationErrors,
            isImportant: props.isImportant
        };
    }

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({
                showValidation: showValidationErrors,
            });
        }
    }


    setTileFieldValue = (action, string) =>{
        const { form } = this.props;
        const tilesString = form.getFieldByKey(KEY_NAME_TILES);
        const list = ReqHelper.prepareMeListFromString(tilesString.value, ';');
        if(ReqHelper.notNullAndUndefinied(tilesString.value) && action===true){
            if(list.indexOf(string)<0){
                tilesString.value += ';'+string;
            }
        }else{
            if(action){
                tilesString.value += ';'+string;
            }else{
                if(list.indexOf(string)>-1){
                    tilesString.value += ';'+string;
                    list.splice(list.indexOf(string),1);
                    tilesString.value = list.join(';')
                }
            }
        }
    }

    saveDiabetesAndTiles = (onlyTiles=false) =>{
        if(!onlyTiles){
            const saveDiabetesField = this.props.form.getFieldByKey(SKIP_SAVE_IS_DIET_DIABETES);
            if(saveDiabetesField){saveDiabetesField.value = true}
        }
        const saveDiabetesTileField = this.props.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
        if(saveDiabetesTileField){saveDiabetesTileField.value = true}
    }

    setTypeChange = (key, value) => {
        const { disabled, form } = this.props;
        if(!disabled){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
                const conditionFieldDiabetesDiet = form.getFieldByKey(IS_DIET_DIABETES);
                switch(key){
                    case IS_INITIAL_STAGES_KEY:
                        if(value){
                            this.setTileFieldValue(true, Tiles.TILESECONDBRAIN);
                        }else{
                            const conditionField = section.getFieldByKey(IS_DEMENTIA_LIST_KEY);
                            if(!conditionField.value){
                                this.setTileFieldValue(false, Tiles.TILESECONDBRAIN);
                            }
                        }
                        this.saveDiabetesAndTiles(true);
                        break;
                    case IS_DEMENTIA_LIST_KEY: 
                        if(value){
                            this.setTileFieldValue(true, Tiles.TILESECONDBRAIN);
                        }else{
                            const conditionField = section.getFieldByKey(IS_INITIAL_STAGES_KEY);
                            if(!conditionField.value){
                                this.setTileFieldValue(false, Tiles.TILESECONDBRAIN);
                            }
                        }
                        this.saveDiabetesAndTiles(true);
                        break;
                    case IS_DIABETES_KEY: 
                        if(value){
                            this.setTileFieldValue(true, Tiles.TILEEIGHTMEAL);
                            if(conditionFieldDiabetesDiet){
                                conditionFieldDiabetesDiet.value=true;
                            }
                            this.saveDiabetesAndTiles();
                        }else{
                            const conditionField = section.getFieldByKey(IS_DIABETES_MELLITUS_KEY);
                            if(!conditionField.value && !conditionFieldDiabetesDiet.value){
                                this.setTileFieldValue(false, Tiles.TILEEIGHTMEAL);
                                this.saveDiabetesAndTiles();
                            }
                        }
                        break;
                    case IS_DIABETES_MELLITUS_KEY:
                        if(value){
                            this.setTileFieldValue(true, Tiles.TILEEIGHTMEAL);
                            if(conditionFieldDiabetesDiet){
                                conditionFieldDiabetesDiet.value=true;
                            }
                            this.saveDiabetesAndTiles();
                        }else{
                            const conditionField = section.getFieldByKey(IS_DIABETES_KEY);
                            if(!conditionField.value && !conditionFieldDiabetesDiet.value){
                                this.setTileFieldValue(false, Tiles.TILEEIGHTMEAL);
                                this.saveDiabetesAndTiles();
                            }
                        }
                        break;
                    case IS_INCONTINENCE_LIST_KEY:
                        const conditionFieldUri = form.getFieldByKey(IS_URINATION_CONTROL);
                        const conditionFieldDef = form.getFieldByKey(IS_CONTROL_OF_DEFECATION);
                        if(value){
                            if(conditionFieldUri.value !== SkillRange.UNLIMITED && conditionFieldDef.value !== SkillRange.UNLIMITED){
                                this.setTileFieldValue(value, Tiles.TILESIXPANTIES);
                            }
                        }else{
                            if(conditionFieldUri.value !== SkillRange.LIMITED && conditionFieldDef.value !== SkillRange.LIMITED){
                                this.setTileFieldValue(value, Tiles.TILESIXPANTIES);
                            }
                        }
                        this.saveDiabetesAndTiles(true);
                        break;
                    case IS_MULTIPLE_SCLEROSIS_THE_LIST_KEY: 
                        this.setTileFieldValue(value, Tiles.TILETHIRDQUESTION);
                        this.saveDiabetesAndTiles(true);
                        break;
                    case IS_STOMA_LIST_KEY: 
                        this.setTileFieldValue(value, Tiles.TILESEVENSTOMA);
                        this.saveDiabetesAndTiles(true);
                        break;
                    default:
                }
            });
            this.setState(nextState);
        }
    };

    setTypeChangeTumorOrDekubitus = (key, value) => {
        if(!this.props.disabled){
            const nextState = produce(this.state, draftState => {
                const { section } = draftState;
                const field = section.getFieldByKey(key);
                if (field) {
                    field.value = value;
                }
            });
            this.setState(nextState);
            if(value===true){
                setTimeout(() => {
                    const tumorOrDekubitus = document.getElementsByClassName('tumor-or-dekubitus-info');
                    if(tumorOrDekubitus.length>0){
                        tumorOrDekubitus[0].parentNode.scrollIntoView({ behavior: 'smooth'});
                    }
                }, 50);
            }
        }
    };

    render() {
        const {
          disabled,
          isImportant,
          translateKey,
          subsectionName,
          sectionNumber,
          showValidationErrors,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe,
        } = this.props;
        const { section } = this.state;

        //first row fields
        const isAllergiesList = section.getFieldByKey('is_allergies_list')
        const isDifficultyIWDBA = section.getFieldByKey('is_difficulty_in_walking_determined_by_age')
        const isAlzheimersDTL = section.getFieldByKey('is_alzheimers_disease_the_list')
        const isAsthmaList = section.getFieldByKey('is_asthma_list')
        const isChronicDL = section.getFieldByKey('is_chronic_diarrhea_list')
        const isBedsoresL = section.getFieldByKey('is_bedsores_list')
        //second row fields
        const isInitialSODL = section.getFieldByKey('is_initial_stages_of_dementia_list')
        const isDementiaL = section.getFieldByKey('is_dementia_list')
        const isAtopicD = section.getFieldByKey('is_atopic_dermatitis')
        const isDiabets = section.getFieldByKey('is_diabetes')
        const isDiabetsML = section.getFieldByKey('is_diabetes_mellitus_list')
        const isMyocardialIL = section.getFieldByKey('is_myocardial_infarction_list')
        //third row fields
        const isHeartFL = section.getFieldByKey('is_heart_failure_list')
        const isArrhythmiasL = section.getFieldByKey('is_arrhythmias_list')
        const isHyperL = section.getFieldByKey('is_hypertension_list')
        const isIncontinenceL = section.getFieldByKey('is_incontinence_list')
        const isMultipleCTL = section.getFieldByKey('is_multiple_sclerosis_the_list')
        const isOsteoporosisL = section.getFieldByKey('is_osteoporosis_list')
        //fourth row fields
        const isParkinsonDL = section.getFieldByKey('is_parkinsons_disease_list')
        const isRheumaticDL = section.getFieldByKey('is_rheumatic_disease_list')
        const isLSPL = section.getFieldByKey('is_left_sided_paresis_list')
        const isRSPL = section.getFieldByKey('is_right_sided_paresis_list')
        const isStomaL = section.getFieldByKey('is_stoma_list')
        const isCancerL = section.getFieldByKey('is_cancer_list')
        //REST
        const isOtherDD= section.getFieldByKey('is_other_description_de');
        const isPatientSmokes = section.getFieldByKey('is_patient_smokes');
        const isNewUI = true;
        const classForCheckBox = "custom-control-inline illness-wrapper newUI-radio-check-box-class cursor-pointer static-height";
        const classForCheckBoxSimple = "newUI-radio-check-box-class";
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}>
                        <div className="newUI-section-padding">
                            <label className="form-label-basic newUI"><span className="quick-label-position-fix">{translateKey("form-text-choroben").toUpperCase()}</span></label>
                            <div className="checkbox-button-group-wrapper row">
                                <div className="col-sm-12 col-lg-3">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isAllergiesList.checkImportant ?'input_important_class':''}`}  fieldName={"allergies"} setTypeChangeToParent={this.setTypeChange} field={isAllergiesList} text={translateKey("allergies")} transKey="allergies" keyName="is_allergies_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isDifficultyIWDBA.checkImportant ?'input_important_class':''}`}  fieldName={"gehschwache"} setTypeChangeToParent={this.setTypeChange} field={isDifficultyIWDBA} text={translateKey("form-text-gehschwache")} transKey="form-text-gehschwache" keyName="is_difficulty_in_walking_determined_by_age"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isAlzheimersDTL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-alzheimer"} setTypeChangeToParent={this.setTypeChange} field={isAlzheimersDTL} text={translateKey("form-text-alzheimer")} transKey="form-text-alzheimer" keyName="is_alzheimers_disease_the_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isAsthmaList.checkImportant ?'input_important_class':''}`} fieldName={"form-text-asthma"} setTypeChangeToParent={this.setTypeChange} field={isAsthmaList} text={translateKey("form-text-asthma")} transKey="form-text-asthma" keyName="is_asthma_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isChronicDL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-chronische"} setTypeChangeToParent={this.setTypeChange} field={isChronicDL} text={translateKey("form-text-chronische")} transKey="form-text-chronische" keyName="is_chronic_diarrhea_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isBedsoresL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-dekubitus"} setTypeChangeToParent={this.setTypeChangeTumorOrDekubitus} field={isBedsoresL} text={translateKey("form-text-dekubitus")} transKey="form-text-dekubitus" keyName="is_bedsores_list"/>
                                </div>

                                <div className="col-sm-12 col-lg-3">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isInitialSODL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-demenz-a"} setTypeChangeToParent={this.setTypeChange} field={isInitialSODL} text={translateKey("form-text-demenz-a")} transKey="form-text-demenz-a" keyName="is_initial_stages_of_dementia_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isDementiaL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-demenz-f"} setTypeChangeToParent={this.setTypeChange} field={isDementiaL} text={translateKey("form-text-demenz-f")} transKey="form-text-demenz-f" keyName="is_dementia_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isAtopicD.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-dermatitis"} setTypeChangeToParent={this.setTypeChange} field={isAtopicD} text={translateKey("form-text-dermatitis")} transKey="form-text-dermatitis" keyName="is_atopic_dermatitis"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isDiabets.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-diabetes"} setTypeChangeToParent={this.setTypeChange} field={isDiabets} text={translateKey("form-text-diabetes")} transKey="form-text-diabetes" keyName="is_diabetes"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isDiabetsML.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-diabetes-insulin"} setTypeChangeToParent={this.setTypeChange} field={isDiabetsML} text={translateKey("form-text-diabetes-insulin")} transKey="form-text-diabetes-insulin" keyName="is_diabetes_mellitus_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isMyocardialIL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-herzinfarkt"} setTypeChangeToParent={this.setTypeChange} field={isMyocardialIL} text={translateKey("form-text-herzinfarkt")} transKey="form-text-herzinfarkt" keyName="is_myocardial_infarction_list"/>
                                </div>

                                <div className="col-sm-12 col-lg-3">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isHeartFL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-herzinsuffizienz"} setTypeChangeToParent={this.setTypeChange} field={isHeartFL} text={translateKey("form-text-herzinsuffizienz")} transKey="form-text-herzinsuffizienz" keyName="is_heart_failure_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isArrhythmiasL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-herzrhythmusstörung"} setTypeChangeToParent={this.setTypeChange} field={isArrhythmiasL} text={translateKey("form-text-herzrhythmusstörung")} transKey="form-text-herzrhythmusstörung" keyName="is_arrhythmias_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isHyperL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-hypertonie"} setTypeChangeToParent={this.setTypeChange} field={isHyperL} text={translateKey("form-text-hypertonie")} transKey="form-text-hypertonie" keyName="is_hypertension_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isIncontinenceL.checkImportant ?'input_important_class':''}`}  fieldName={"bb_defecation_data_p_9"} setTypeChangeToParent={this.setTypeChange} field={isIncontinenceL} text={translateKey("bb_defecation_data_p_9")} transKey="bb_defecation_data_p_9" keyName="is_incontinence_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isMultipleCTL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-multiple-sklerose"} setTypeChangeToParent={this.setTypeChange} field={isMultipleCTL} text={translateKey("form-text-multiple-sklerose")} transKey="form-text-multiple-sklerose" keyName="is_multiple_sclerosis_the_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isOsteoporosisL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-osteoporose"} setTypeChangeToParent={this.setTypeChange} field={isOsteoporosisL} text={translateKey("form-text-osteoporose")} transKey="form-text-osteoporose" keyName="is_osteoporosis_list"/>
                                </div>

                                <div className="col-sm-12 col-lg-3">
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isParkinsonDL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-parkinson"} setTypeChangeToParent={this.setTypeChange} field={isParkinsonDL} text={translateKey("form-text-parkinson")} transKey="form-text-parkinson" keyName="is_parkinsons_disease_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isRheumaticDL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-rheuma"} setTypeChangeToParent={this.setTypeChange} field={isRheumaticDL} text={translateKey("form-text-rheuma")} transKey="form-text-rheuma" keyName="is_rheumatic_disease_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isLSPL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-schlaganfall-li"} setTypeChangeToParent={this.setTypeChange} field={isLSPL} text={translateKey("form-text-schlaganfall-li")} transKey="form-text-schlaganfall-li" keyName="is_left_sided_paresis_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isRSPL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-schlaganfall-re"} setTypeChangeToParent={this.setTypeChange} field={isRSPL} text={translateKey("form-text-schlaganfall-re")} transKey="form-text-schlaganfall-re" keyName="is_right_sided_paresis_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isStomaL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-stoma"} setTypeChangeToParent={this.setTypeChange} field={isStomaL} text={translateKey("form-text-stoma")} transKey="form-text-stoma" keyName="is_stoma_list"/>
                                    <CheckBoxSmallMultipleComponent newUI={isNewUI} className={`${classForCheckBox} ${isImportant && isCancerL.checkImportant ?'input_important_class':''}`}  fieldName={"form-text-tumor"} setTypeChangeToParent={this.setTypeChangeTumorOrDekubitus} field={isCancerL} text={translateKey("form-text-tumor")} transKey="form-text-tumor" keyName="is_cancer_list"/>
                                </div>
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <CheckBoxComponent checkClassWrapper={classForCheckBoxSimple}  newUI={isNewUI} className="col-sm-12 col-lg-6" showValidation={showValidationErrors} setTypeChangeToParent={this.setTypeChange} value={isPatientSmokes.value} validationErrors={isPatientSmokes.validationErrors} text={translateKey("form-text-betreuende")} transKey="form-text-betreuende" keyName="is_patient_smokes"/>
                            </div>
                        </div>
                        <div className="newUI-section-padding">
                            <div className="row">
                                <InputTypeTextComponent newUI={isNewUI} showValidation={showValidationErrors} isImportant={isImportant} className="col-sm-12 col-lg-6 form-margin-bottom-fix" setTypeChangeToParent={this.setTypeChange} field={isOtherDD} text={translateKey("form-text-sonstige")} transKey="form-text-sonstige" keyName="is_other_description_de"/>
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                />
            </div>
        ); 
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section4)); 
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import * as LanguageSelect from "../../_constance/values/languageSelect";

export class TaskStatusLang extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: this.props.element
        }
    }

    getMeStatusLang(name){
        const {translateKey, appLang} = this.props;
        if(appLang === LanguageSelect.EN){
            return name
        }else{
            switch(name){
                case "Open" : return translateKey('open');
                case "Closed" : return translateKey('task_closed');
                case "Cancelled" : return translateKey('cancelled');
                case "Completed" : return translateKey('completed');
                case "Scheduled" : return translateKey('scheluded_appointments');
                default: return name;
            }
        }

    }

    render() {
        return (
            <React.Fragment>
                {this.getMeStatusLang(this.props.element)}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {return {appLang: state.lang.appLanguage,};}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskStatusLang));
import * as React from 'react';

export class TableDateFormat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.timestamp
        };
    }

    dateConversion(timestamp){
        if(timestamp){
            if(timestamp!==null){
                let today = new Date(timestamp*1000);
                let dd = today.getDate();
                let mm = today.getMonth() + 1;
                let yyyy = today.getFullYear();
                if (dd < 10) {
                dd = '0' + dd;
                } 
                if (mm < 10) {
                mm = '0' + mm;
                } 
                let converted = dd + '.' + mm + '.' + yyyy;
                return converted;
            }else{
                return "---";
            }
        }else{
            return "---";
        }
    }

    render() {
        if(this.props.formClass){
            return (
                <span className="form-control no_border no_editable">{this.dateConversion(this.props.timestamp)}</span>
            );
        }else{
            return (
                    <span>{this.dateConversion(this.props.timestamp)}</span>
            );
        }
    }
}
export const Rating = [
    {
        Label: 'Unknown',
        Value: ''
    },
    {
        Label: 'Ice (Care > 26 weeks)',
        Value: "717700000"
    },
    {
        Label: 'Cold (Care < 26 weeks)',
        Value: "717700001"
    },
    {
        Label: 'Warm (Care < 12 weeks)',
        Value: "717700002"
    },
    {
        Label: 'Hot (Care < 4 weeks)',
        Value: "717700003"
    }
]
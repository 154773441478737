import * as React from 'react';

class PflegegradElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    changeValue = (value) =>{
        this.props.changeMeValue(value);
    }

    render() {
        const { isActive, icon, pCost, special, translateKey, name, value, iconActive, isBawaria, bCost} = this.props;
        const showDetails = false;
        return (
            <div className={`pflegegrad-element-class ${special?'special':''} ${isActive?'active':''}`} onClick={()=>this.changeValue(value)}>
                {!special?
                    <React.Fragment>
                        <img className="pflegegrad-icon" src={isActive?iconActive:icon} alt="Icon"/>
                        <div className="pflegegrad-name">{translateKey(name)}</div>

                        {showDetails &&
                            <React.Fragment>
                                <div className="pflegegrad-cost-element">
                                    <div className="pflegegrad-cost-element-title">{translateKey("pflegegrad")}</div>
                                    <div className="pflegegrad-cost-element-value">{pCost}</div>
                                </div>
                                {isBawaria &&
                                    <div className="pflegegrad-cost-element">
                                        <div className="pflegegrad-cost-element-title">{translateKey("form-text-landesgeld-bayern")}</div>
                                        <div className="pflegegrad-cost-element-value">{bCost}</div>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>
                :
                    <div className="pflegegrad-name special">{translateKey(name)}</div>
                }
            </div>
        );
    }
}
export default PflegegradElement;
export const CareOtherRequirementsEnums = {
    EN: [
        {
            Name: 'Allergies',
            Checked: false
        },
        {
            Name: 'Alzheimer\'s',
            Checked: false
        },
        {
            Name: 'Heart rhythm disorder',
            Checked: false
        },
        {
            Name: 'Asthma',
            Checked: false
        },
        {
            Name: 'Dermatis',
            Checked: false
        },
        {
            Name: 'Bedsores',
            Checked: false
        },
        {
            Name: 'Tumor',
            Checked: false
        },
        {
            Name: 'Chronic diarrhea',
            Checked: false
        },
        {
            Name: 'Dementia',
            Checked: false
        },
        {
            Name: 'Depression',
            Checked: false
        },
        {
            Name: 'Diabetes',
            Checked: false
        },
        {
            Name: 'Insulin dependent diabetes',
            Checked: false
        },
        {
            Name: 'Problem with moving',
            Checked: false
        },
        {
            Name: 'Cardiac insufficiency',
            Checked: false
        },
        {
            Name: 'Hypertension',
            Checked: false
        },
        {
            Name: 'Urinary incontinence',
            Checked: false
        },
        {
            Name: 'Beginning of dementia',
            Checked: false
        },
        {
            Name: 'Stroke (left-side)',
            Checked: false
        },
        {
            Name: 'Multiple sclerosis',
            Checked: false
        },
        {
            Name: 'Heart Attack',
            Checked: false
        },
        {
            Name: 'Osteoporosis',
            Checked: false
        },
        {
            Name: 'Parkinson',
            Checked: false
        },
        {
            Name: 'Rheumatism',
            Checked: false
        },
        {
            Name: 'Stroke (right-side)',
            Checked: false
        },
        {
            Name: 'Stoma',
            Checked: false
        }
    ],
    DE: [
        {
            Name: 'Allergien',
            Checked: false
        },
        {
            Name: 'Alzheimer',
            Checked: false
        },
        {
            Name: 'Herzrhythmusstörung',
            Checked: false
        },
        {
            Name: 'Asthma',
            Checked: false
        },
        {
            Name: 'Dermatis',
            Checked: false
        },
        {
            Name: 'Dekubitus',
            Checked: false
        },
        {
            Name: 'Tumor',
            Checked: false
        },
        {
            Name: 'Chronische Durchfälle',
            Checked: false
        },
        {
            Name: 'Demenz',
            Checked: false
        },
        {
            Name: 'Depression',
            Checked: false
        },
        {
            Name: 'Zuckerkrankheit',
            Checked: false
        },
        {
            Name: 'Diabetes (insulinpflichtig)',
            Checked: false
        },
        {
            Name: 'altersbedingte Gehschwäche',
            Checked: false
        },
        {
            Name: 'Herzinsuffizienz',
            Checked: false
        },
        {
            Name: 'Bluthochdruck',
            Checked: false
        },
        {
            Name: 'Urininkontinenz ',
            Checked: false
        },
        {
            Name: 'Demenz (Anfangsstadium)',
            Checked: false
        },
        {
            Name: 'Schlaganfall (links)',
            Checked: false
        },
        {
            Name: 'Multiple Sklerose',
            Checked: false
        },
        {
            Name: 'Herzinfarkt',
            Checked: false
        },
        {
            Name: 'Osteoporose',
            Checked: false
        },
        {
            Name: 'Parkinson',
            Checked: false
        },
        {
            Name: 'Rheuma',
            Checked: false
        },
        {
            Name: 'Schlaganfall (rechts)',
            Checked: false
        },
        {
            Name: 'Stoma',
            Checked: false
        }
    ]
};
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import { IconsMap } from '../../_helpers/icons_set'
import { baseData, ENVIRONMENT } from '../../_constance/base_data';
import * as Env from '../../_constance/env';

class FooterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edited: false
        };
    }

    openInNewTab(url) {
        if(!this.props.disabled){
            var win = window.open(url, '_blank');
            win.focus();
        }
    }

    render() {
        const { translateKey } = this.props;
        return (
           <div className="container footer-container">
               <div className="row">
                    <div className="col-sm-12 col-md-5">
                        <img className="prom-logo-bottom" src={IconsMap.other.prom_logo_footer} alt="Prom Logo Footer"/>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://promedica-alltagsbetreuung.de/")}>
                                    {translateKey('zone-text-footer-alltagsbetreuung')}
                                </div>
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://promedica-alltagsbetreuung.de/leistungen/")}>
                                    {translateKey('zone-text-footer-leistungen')}
                                </div>
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://promedica-alltagsbetreuung.de/betreuungskosten/")}>
                                    {translateKey('zone-text-footer-betreuungskosten')}
                                </div>
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://promedica-alltagsbetreuung.de/haufige-fragen/")}>
                                    {translateKey('zone-text-footer-haufige-fragen')}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://promedica-alltagsbetreuung.de/uber-uns/")}>
                                    {translateKey('zone-text-footer-uber-uns')}
                                </div>
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://promedica-alltagsbetreuung.de/wp-content/uploads/2019/08/Betreuungsfragebogen.pdf")}>
                                    {translateKey('zone-text-footer-betreuungsfragebogen')}
                                </div>
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://www.promedica24.de/")}>
                                    {translateKey('zone-text-footer-bewerbung')}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://promedica-alltagsbetreuung.de/impressum/")}>
                                    {translateKey('zone-text-footer-impressum')}
                                </div>
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://promedica-alltagsbetreuung.de/datenschutzerklaerung/")}>
                                    {translateKey('zone-text-footer-datenschutzerklarung')}
                                </div>
                                <div className="zone-footer-link cursor-pointer" onClick={()=> this.openInNewTab("https://promedica-alltagsbetreuung.de/kontakt/")}>
                                    {translateKey('zone-text-footer-kontakt')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="copyright-footer-zone">
                            {translateKey('zone-text-footer-copyright')} 
                            {ENVIRONMENT !== Env.PROD &&
                                ' '+baseData.releaseDate
                            }
                        </div>
                    </div>
                </div>
           </div>
        );
    }
}
const mapStateToProps = state => {return {login: state.auth.appLogin}}  
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterComponent));
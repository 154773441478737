import DnDPriorityOffer from './DnDPriorityOffer';
import DnDPriorityOfferElement from './DnDPriorityOfferElement';
import { IconsMap } from "../../../_helpers/icons_set";

export default class PriorityOffer extends DnDPriorityOffer {
    constructor() {
        super({
            fields: [
                new DnDPriorityOfferElement({
                    id: "Szybkie rozpoczęcie opieki",
                    id_deutch: "Beginnen Sie schnell mit der Pflege",
                    posibileKeys: ['Szybkie rozpoczęcie opieki', 'Beginnen Sie schnell mit der Pflege'],
                    name: "from-text-important-starten",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.clock,
                    iconActive: IconsMap.svg.zusammenfassung.clock_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Opieka nad osobą z demencja",
                    id_deutch: "Pflege einer Person mit Demenz",
                    posibileKeys: ['Opieka nad osobą z demencja', 'Pflege einer Person mit Demenz'],
                    name: "from-text-important-demenz",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.demenz_black,
                    iconActive: IconsMap.svg.zusammenfassung.demenz_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Opieka nad osobą ze stwardnieniem rozsianym",
                    id_deutch: "Pflege einer Person mit einer PEG-Sonde",
                    posibileKeys: ['Opieka nad osobą z sondą PEG', 'Opieka nad osobą ze stwardnieniem rozsianym', 'Pflege einer Person mit einer PEG-Sonde'],
                    name: "from-text-important-sonde",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.skleroze,
                    iconActive: IconsMap.svg.zusammenfassung.skleroze_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Dobre umiejętności językowe opiekunki",
                    id_deutch: "Gute Sprachkenntnisse des Babysitters",
                    posibileKeys: ['Dobre umiejętności językowe opiekunki', 'Gute Sprachkenntnisse des Babysitters'],
                    name: "from-text-important-sprachkenntnisse",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.chat,
                    iconActive:  IconsMap.svg.zusammenfassung.chat_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Opieka z pełnym transferem",
                    id_deutch: "Pflege bei voller Übertragung",
                    posibileKeys: ['Opieka z pełnym transferem', 'Pflege bei voller Übertragung'],
                    name: "from-text-important-transferbed",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.transfer_black,
                    iconActive: IconsMap.svg.zusammenfassung.transfer_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Opieka nad osobą z problemami kontynencji",
                    id_deutch: "Betreuung einer Person mit kontinentalen Problemen",
                    posibileKeys: ['Opieka nad osobą z problemami kontynencji', 'Betreuung einer Person mit kontinentalen Problemen'],
                    name: "from-text-important-inkontinenzversorgung",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.inkontinenz,
                    iconActive: IconsMap.svg.zusammenfassung.inkontinenz_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Wymiany worka stomijnego",
                    id_deutch: "Ersatz des Stomabeutels",
                    posibileKeys: ['Wymiany worka stomijnego', 'Ersatz des Stomabeutels'],
                    name: "from-text-important-stomabeutel",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.stoma,
                    iconActive: IconsMap.svg.zusammenfassung.stoma_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Przygotowywanie posiłków dla diabetyków",
                    id_deutch: "Mahlzeiten für Diabetiker zubereiten",
                    posibileKeys: ['Przygotowywanie posiłków dla diabetyków', 'Mahlzeiten für Diabetiker zubereiten'],
                    name: "from-text-important-diabetiker",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.fork,
                    iconActive: IconsMap.svg.zusammenfassung.fork_white,
                    show:true
                }),
                new DnDPriorityOfferElement({
                    id: "Sonda PEG",
                    id_deutch: "PEG-Sonde",
                    posibileKeys: ['Sonda PEG', 'PEG-Sonde'],
                    name: "form-text-pegsonde",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.sonda,
                    iconActive: IconsMap.svg.zusammenfassung.sonda_white,
                    show:false
                }),
                new DnDPriorityOfferElement({
                    id: "Wymiany worka na mocz",
                    id_deutch: "Wechseln Sie den Urinbeutel",
                    posibileKeys: ['Wymiany worka na mocz', 'Wechseln Sie den Urinbeutel'],
                    name: "form-text-urinbeautel",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.urin,
                    iconActive: IconsMap.svg.zusammenfassung.urin_white,
                    show:false
                }),
                new DnDPriorityOfferElement({
                    id: "Prawo jazdy",
                    id_deutch: "Fahrerlaubnis",
                    posibileKeys: ['Prawo jazdy', 'Fahrerlaubnis'],
                    name: "from-text-important-fuhrerschein",
                    active: false,
                    action:true,
                    icon: IconsMap.svg.zusammenfassung.car,
                    iconActive: IconsMap.svg.zusammenfassung.car_white,
                    show:true
                }),
            ]
        })
    }
}
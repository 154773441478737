import {
  SET_LEADS_DATABASE, REMOVE_LEAD_DATA ,REMOVE_LEADS_DATABASE, SET_CLIENTS_DATABASE, REMOVE_CLIENTS_DATABASE, SET_MULTIPLIERS_DATABASE, REMOVE_MULTIPLIERS_DATABASE, SET_INVOICES_DATABASE,
  REMOVE_INVOICES_DATABASE, SET_TASKS_DATABASE, UPDATE_TASKS_DATABASE, REMOVE_TASKS_DATABASE, PENDING_CLIENTS_DATABASE, PENDING_INVOICES_DATABASE, PENDING_LEADS_DATABASE,
  PENDING_MULTIPLIERS_DATABASE, PENDING_TASKS_DATABASE, CLEAR_ALL_DATABASE, SET_PATIENTCLIENT_DATABASE, PENDING_PATIENTCLIENT_DATABASE,REMOVE_PATIENTCLIENT_DATABASE,
  SET_PATIENTLEAD_DATABASE, PENDING_PATIENTLEAD_DATABASE,REMOVE_PATIENTLEAD_DATABASE,UPDATE_LEAD_DATA,FINISHED_TASKS_DATABASE, ADD_LEAD_DATA, UPDATE_MULTIPLIER_DATA, ADD_MULTIPLIER_DATA,
  ADD_TASK_DATA, UPDATE_TASK_DATA, ACCEPT_INVOICES_DATABASE, UPDATE_PATIENT_DATA, ADD_PATIENT_DATA
} from "../actions/action-types";
import axiosAuth from '../../_services/config/axios-auth';
import { LeadTable, ClientTable, MultiplierTable, InvoiceTable, TaskTable } from "../../_constance/classes/index";

export const loadBasicDataToRedux = (lang) => {
  return dispatch => {
    //dispatch(getLeadData(lang));
    dispatch(getPatientLead(lang));
    dispatch(getPatientClient(lang));
    //dispatch(getClients());
    dispatch(getMultipliers());
    //dispatch(getInvoices());
    //dispatch(getTasks());
  };
}

export const getLeadData = (lang) => {
  return dispatch => {
    dispatch(pendingLeadsData());
    axiosAuth.get("leads/list/0/" + lang)
      .then(res => {
        let mapedRes = res.data.map((element)=>{return new LeadTable(element);});
        dispatch(setLeadsData(mapedRes));
      });
  }
}

export const getPatientClient = (lang) => {
  return dispatch => {
    dispatch(pendingPatientClientData());
    axiosAuth.get("patients/list/CLIENT/0/" + lang)
      .then(res => {
        dispatch(setPatientClientData(res.data));
      });
  }
}

export const getPatientLead = (lang) => {
  return dispatch => {
    dispatch(pendingPatientLeadData());
    axiosAuth.get("patients/list/LEAD/0/" + lang)
      .then(res => {
        dispatch(setPatientLeadData(res.data));
      });
  }
}

export const getClients = () => {
  return dispatch => {
    dispatch(pendingClientsData());
    axiosAuth
      .get("clients/list/0")
      .then(res => {
        let mapedRes = res.data.map((element)=>{return new ClientTable(element);});
        dispatch(setClientsData(mapedRes));
      })
  }
}

export const getMultipliers = () => {
  return dispatch => {
    dispatch(pendingMultipliersData());
    axiosAuth.get("multipliers/list/0")
      .then(res => {
        let mapedRes = res.data.map((element)=>{return new MultiplierTable(element);});
        dispatch(setMultipliersData(mapedRes));
      })
  }
}

export const getInvoices = () => {
  return dispatch => {
    dispatch(pendingInvoicesData());
    axiosAuth
      .get("invoices/list/0")
      .then(res => {
        let mapedRes = res.data.map((element)=>{return new InvoiceTable(element);});
        dispatch(setInvoicesData(mapedRes));
      })
  }
}

export const getTasks = () => {
  return dispatch => {
    dispatch(pendingTasksData());
    axiosAuth
      .get("activityWeb/task/list/0")
      .then(res => {
        let mapedRes = res.data.map((element)=>{return new TaskTable(element, "Task");});
        dispatch(updateTasksData(mapedRes));
        axiosAuth
          .get("activityWeb/call/list/0")
          .then(res => {
            let mapedRes = res.data.map((element)=>{return new TaskTable(element, "Call");});
            dispatch(updateTasksData(mapedRes));
            axiosAuth
              .get("activityWeb/event/list/0")
              .then(res => {
                let mapedRes = res.data.map((element)=>{return new TaskTable(element, "Event");});
                dispatch(updateTasksData(mapedRes));
                dispatch(finishedTaskData());
              })
              .catch((error)=>{
                console.log(error);
                dispatch(finishedTaskData());
              })
          })
      })
      .catch(error=>{
        console.log(error);
        axiosAuth
          .get("activityWeb/call/list/0")
          .then(res => {
            let mapedRes = res.data.map((element)=>{return new TaskTable(element, "call");});
            dispatch(updateTasksData(mapedRes));
            axiosAuth
              .get("activityWeb/event/list/0")
              .then(res => {
                let mapedRes = res.data.map((element)=>{return new TaskTable(element, "event");});
                dispatch(updateTasksData(mapedRes));
                dispatch(finishedTaskData());
              })
              .catch((error)=>{
                console.log(error);
                dispatch(finishedTaskData());
              })
          })
          .catch((error)=>{
            console.log(error);
            axiosAuth
              .get("activityWeb/event/list/0")
              .then(res => {
                let mapedRes = res.data.map((element)=>{return new TaskTable(element, "event");});
                dispatch(updateTasksData(mapedRes));
                dispatch(finishedTaskData());
              })
              .catch((error)=>{
                console.log(error);
                dispatch(finishedTaskData());
            })
          })
      })
  }
}


export const setLeadsData = (baseData) => {
  return {
    type: SET_LEADS_DATABASE,
    data: baseData
  };
};
export const pendingLeadsData = () => {
  return {
    type: PENDING_LEADS_DATABASE
  };
};
export const removeLeadsData = () => {
  return {
    type: REMOVE_LEADS_DATABASE
  };
};

export const removeOneLeadData = (i) => {
  return {
    type: REMOVE_LEAD_DATA,
    leadId: i
  };
};

export const updateLeadData = (i, newData) => {
  return {
    type: UPDATE_LEAD_DATA,
    data: newData,
    leadId: i
  };
};

export const addLeadData = (i, newData) => {
  return {
    type: ADD_LEAD_DATA,
    data: newData,
    leadId: i
  };
};


export const setPatientClientData = (baseData) => {
  return {
    type: SET_PATIENTCLIENT_DATABASE,
    data: baseData
  };
};
export const pendingPatientClientData = () => {
  return {
    type: PENDING_PATIENTCLIENT_DATABASE
  };
};
export const removePatientClientData = () => {
  return {
    type: REMOVE_PATIENTCLIENT_DATABASE
  };
};


export const setPatientLeadData = (baseData) => {
  return {
    type: SET_PATIENTLEAD_DATABASE,
    data: baseData
  };
};
export const pendingPatientLeadData = () => {
  return {
    type: PENDING_PATIENTLEAD_DATABASE
  };
};
export const removePatientLeadData = () => {
  return {
    type: REMOVE_PATIENTLEAD_DATABASE
  };
};
export const updatePatientData = (i, newData) => {
  return {
    type: UPDATE_PATIENT_DATA,
    data: newData,
    id: i
  };
};
export const addPatientData = (i, newData) => {
  return {
    type: ADD_PATIENT_DATA,
    data: newData,
    id: i
  };
};


export const setClientsData = (baseData) => {
  return {
    type: SET_CLIENTS_DATABASE,
    data: baseData
  };
};
export const pendingClientsData = () => {
  return {
    type: PENDING_CLIENTS_DATABASE
  };
};
export const removeClientsData = () => {
  return {
    type: REMOVE_CLIENTS_DATABASE
  };
};


export const setMultipliersData = (baseData) => {
  return {
    type: SET_MULTIPLIERS_DATABASE,
    data: baseData
  };
};
export const pendingMultipliersData = () => {
  return {
    type: PENDING_MULTIPLIERS_DATABASE
  };
};
export const removeMultipliersData = () => {
  return {
    type: REMOVE_MULTIPLIERS_DATABASE
  };
};
export const updateMultiplierData= (i, newData) => {
  return {
    type: UPDATE_MULTIPLIER_DATA,
    data: newData,
    id: i
  };
};
export const addNewMultiplierData = (i, newData) => {
  return {
    type: ADD_MULTIPLIER_DATA,
    data: newData,
    id: i
  };
};



export const setInvoicesData = (baseData) => {
  return {
    type: SET_INVOICES_DATABASE,
    data: baseData
  };
};
export const pendingInvoicesData = () => {
  return {
    type: PENDING_INVOICES_DATABASE
  };
};
export const removeInvoicesData = () => {
  return {
    type: REMOVE_INVOICES_DATABASE
  };
};
export const changeInvoiceStatusToRedux = (invoice, i, status, comment) => {
  return {
    type: ACCEPT_INVOICES_DATABASE,
    data: invoice,
    id: i,
    status: status,
    comment: comment
  };
};



export const setTasksData = (baseData) => {
  return {
    type: SET_TASKS_DATABASE,
    data: baseData
  };
};
export const pendingTasksData = () => {
  return {
    type: PENDING_TASKS_DATABASE
  };
};
export const updateTasksData = (addData) => {
  return {
    type: UPDATE_TASKS_DATABASE,
    data: addData
  };
};
export const removeTasksData = () => {
  return {
    type: REMOVE_TASKS_DATABASE
  };
};
export const updateTaskData= (i, newData) => {
  return {
    type: UPDATE_TASK_DATA,
    data: newData,
    id: i
  };
};
export const addNewTaskData = (i, newData) => {
  return {
    type: ADD_TASK_DATA,
    data: newData,
    id: i
  };
};


export const finishedTaskData = () => {
  return {
    type: FINISHED_TASKS_DATABASE
  };
};

export const removeDatabase = () => {
  return {
    type: CLEAR_ALL_DATABASE
  };
}
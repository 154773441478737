import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import {RouteButtonComponent} from "../../_components/index"
import {AutoSuggestComponent} from "../../_components/autosuggest-component/AutoSuggestComponent";
import SelectUniversalComponent from "../../_components/select-universal-component/SelectUniversalComponent";
import * as Gender from "../../_constance/enums/Gender";
import * as MultiplierStatus from "../../_constance/enums/MultiplierStatus";
import * as MultiplierQuality from "../../_constance/enums/MultiplierQuality";
import * as LangSelect from "../../_constance/values/languageSelect";
import SelectOptionsComponent from "../../_components/select-options-component/SelectOptionsComponent";
import {Toggle} from "react-toggle-component"
import InputTypeComponent from "../../_components/input-type-component/input-type-component";
import * as FieldTypes from '../../_forms/fieldTypes';

class MultiplierComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            edited: this.props.edited
        };
    }

    handleChangeValueKey = (key, value) =>{
        this.props.element[key] = value;
        this.setState(prevState => {
          let elem = {...prevState.element}
          elem[key] = value;
          return {
            ...prevState,
            element: elem
        }
        });
        this.props.editedData();
    }

    handleChangeValueKeyNotArrow(key, value){
        this.props.element[key] = value;
        this.setState(prevState => {
          let elem = {...prevState.element}
          elem[key] = value;
          return {
            ...prevState,
            element: elem
        }
        });
        this.props.editedData();
    }

    handleChangeValueAddressKeyNotArrow(key, value){
        this.props.element.address[key] = value;
        this.setState(prevState => {
            let elem = {...prevState.element.address}
            elem[key] = value;
            return {
                ...prevState,
                element: {
                        ...prevState.element,
                        address: elem
                }
            }
        });
        this.props.editedData();
    }

    handlechangePostalCode = event => {
        if(typeof event === 'object'){
            this.props.element.address.postalCode = event.value;
            this.props.element.address.city = event.city;
            this.setState(prevState => {
                return {
                    ...prevState,
                    element: {
                        ...prevState.element,
                        address: {
                            ...prevState.element.address,
                            postalCode: event.value,
                            city: event.city,
                        }
                    }
                }
            });
        }else{
            this.props.element.address.postalCode = event;
            this.setState(prevState => {
                return {
                    ...prevState,
                    element: {
                        ...prevState.element,
                        address: {
                            ...prevState.element.address,
                            postalCode: event,
                        }
                    }
                }
            });
        }
        this.props.editedData();
    }

    prepareMeSelect = () =>{
        if(this.props.cities.length>0){
            return this.props.cities.map(x => ({
                value: x.PostalCode!==null?x.PostalCode:'', 
                city:  x.City!==null?x.City:'',
                label:  x.City!==null?x.City:''
            }));
        }else{
            return [];
        }
    }

    toggleThisElement = () =>{
        this.props.element.careAmbasador = !this.props.element.careAmbasador
        this.setState(prevState => {
          return {
            ...prevState,
            element: {
              ...prevState.element,
              careAmbasador: !this.props.element.careAmbasador
            }
          }
        });
        this.props.editedData();
    }

    prepareMeMarketingBasedOnLanguage(){
        const { marketingsList, appLang } = this.props;
        return marketingsList.filter(element=> {
          if(appLang==="EN"){
            return element.Market==="UK" || element.Market===appLang
          }else{
            return element.Market===appLang
          }
        })
    }

    render() {
        const preparedSelectGroup = this.prepareMeSelect();
        const { translateKey, element, appLang } = this.props;
        return (
            <div className="lead-client-row">
                <div className="lead-details-client-info multiplier-padding-bottom">
                    <div className={"col-sm-12 "+(this.props.new?"col-lg-6":"col-lg-5")}>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("last_name")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("lastName", e.target.value)} value={element.lastName!==null?element.lastName:''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("first_name")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("firstName", e.target.value)} value={element.firstName!==null?element.firstName:''}/>
                            </div>
                        </div>
                        {appLang===LangSelect.DE &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("middle_name")}</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("middleName", e.target.value)} value={element.middleName!==null?element.middleName:''}/>
                                </div>
                            </div>
                        }
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("gender")}</label>
                            <div className="col-sm-8">
                                <SelectUniversalComponent
                                    keyName="gender"
                                    valuesList={[
                                        { value: Gender.MALE, label: this.props.translateKey("male") },
                                        { value: Gender.FEMALE, label: this.props.translateKey("female") }
                                    ]}
                                    value={element.gender!==null?element.gender:''}
                                    optionValueKey="value"
                                    optionLabelKey="label"
                                    setTypeChangeToParent={this.handleChangeValueKey}
                                />
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("profession")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("profession", e.target.value)} value={element.profession!==null?element.profession:''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("company")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("company", e.target.value)} value={element.company!==null?element.company:''}/>
                            </div>
                        </div>
                        <InputTypeComponent disabled={false} keyName={"email"} value={element.email} type={FieldTypes.EMAIL} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("email")}/>
                        <InputTypeComponent disabled={false} keyName={"emailAddress2"} value={element.emailAddress2} type={FieldTypes.EMAIL} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("email_2")}/>
                        <InputTypeComponent disabled={false} keyName={"phone"} value={element.phone} type={FieldTypes.PHONE_NUMBER} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("home_phone")}/>
                        <InputTypeComponent disabled={false} keyName={"cellPhone"} value={element.cellPhone} type={FieldTypes.PHONE_NUMBER} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("mobile_phone")}/>
                        {appLang === LangSelect.DE &&
                            <React.Fragment>
                                <InputTypeComponent disabled={false} keyName={"businessPhone"} value={element.businessPhone} type={FieldTypes.PHONE_NUMBER} showValidation={this.props.edited?true: false} setTypeChangeToParent={this.handleChangeValueKey} text={translateKey("business_phone")}/>
                                <div className="form-group row flexAlignCenter no-margin-row">
                                    <label className="col-sm-4 col-form-label form-label-basic">{translateKey("phone_other_remarks")}</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("phoneOtherRemarks", e.target.value)} value={element.phoneOtherRemarks!==null?element.phoneOtherRemarks:''}/>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className={"col-sm-12 "+(this.props.new?"col-lg-6":"col-lg-5")}>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("street")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("street", e.target.value)} value={element.address.street?element.address.street:''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("building_number")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("buildingNumber", e.target.value)} value={element.address?element.address.buildingNumber!==null?element.address.buildingNumber:'':''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("apartment_number")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("apartmentNumber", e.target.value)} value={element.address?element.address.apartmentNumber!==null?element.address.apartmentNumber:'':''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("postal_code")}</label>
                            <div className="col-sm-8">
                                {preparedSelectGroup.length>0 &&
                                    <AutoSuggestComponent onChange={this.handlechangePostalCode} value={element.address?element.address.postalCode!==null?element.address.postalCode:'':''} suggestions={preparedSelectGroup}/>
                                }
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("city")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("city", e.target.value)} value={element.address?element.address.city!==null?element.address.city:'':''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("location")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper"  onChange={e => this.handleChangeValueAddressKeyNotArrow("location", e.target.value)} value={element.address?element.address.location!==null?element.address.location:'':''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("county")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("county", e.target.value)} value={element.address?element.address.county!==null?element.address.county:'':''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("community")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("community", e.target.value)}  value={element.address?element.address.community!==null?element.address.community:'':''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("province")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("stateprovince", e.target.value)}  value={element.address?element.address.stateprovince!==null?element.address.stateprovince:'':''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("geo_region")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueAddressKeyNotArrow("countryregion", e.target.value)} value={element.address?element.address.countryregion!==null?element.address.countryregion:'':''}/>
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("status")}</label>
                            <div className="col-sm-8">
                                <SelectUniversalComponent
                                    keyName="statusMultiplier"
                                    valuesList={[
                                        { value: MultiplierStatus.POTENTIAL, label: translateKey("multiplier_status_potential") },
                                        { value: MultiplierStatus.ACTIVE, label: translateKey("multiplier_status_active") },
                                        { value: MultiplierStatus.NOT_ACTIVE, label: translateKey("multiplier_status_inactive") },
                                    ]}
                                    value={element.statusMultiplier!==null?element.statusMultiplier:''}
                                    optionValueKey="value"
                                    optionLabelKey="label"
                                    setTypeChangeToParent={this.handleChangeValueKey}
                                />
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("multiplier_quality")}</label>
                            <div className="col-sm-8">
                                <SelectUniversalComponent
                                    keyName="multiplierQuality"
                                    valuesList={[
                                        { value: MultiplierQuality.HIGH, label: translateKey("dashboard_high") },
                                        { value: MultiplierQuality.MEDIUM, label: translateKey("multiplier_quality_medium") },
                                        { value: MultiplierQuality.LOW, label: translateKey("dashboard_low") },
                                    ]}
                                    value={element.multiplierQuality!==null?element.multiplierQuality:''}
                                    optionValueKey="value"
                                    optionLabelKey="label"
                                    setTypeChangeToParent={this.handleChangeValueKey}
                                />
                            </div>
                        </div>
                        <div className="form-group row flexAlignCenter no-margin-row">
                            <label className="col-sm-4 col-form-label form-label-basic">{translateKey("salutation_letter")}</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("salutationLetter", e.target.value,)} value={element.salutationLetter?element.salutationLetter:''}/>
                            </div>
                        </div>
                        {appLang===LangSelect.DE &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("salutation_address")}</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control lead-input-wrapper" onChange={e => this.handleChangeValueKeyNotArrow("salutationAddress", e.target.value)} value={element.salutationAddress?element.salutationAddress:''}/>
                                </div>
                            </div>
                        } 
                        {appLang === LangSelect.EN && this.props.marketingsList.length>0 &&
                            <SelectOptionsComponent chooseText={translateKey("choose_select")} setTypeChangeToParent={this.handleChangeValueKey} valuesList = {this.prepareMeMarketingBasedOnLanguage()} keyName="marketingId" value={element.marketingId?element.marketingId:''} mainText={translateKey("marketing_type")}/>
                        }  
                        {appLang === LangSelect.EN &&
                            <div className="form-group row flexAlignCenter no-margin-row">
                                <label className="col-sm-4 col-form-label form-label-basic">{translateKey("care_ambassador")}</label>
                                <div className="col-sm-8 text-center">
                                    <Toggle name={"toggle-lead-exclusive"} checked={element.careAmbasador} onToggle={this.toggleThisElement}/>
                                </div>
                            </div>
                        }
                    </div>
                    {!this.props.new &&
                        <div className="col-sm-2 text-right lead-route-container">
                            <RouteButtonComponent element={element.address} text={translateKey("route")}/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {return {
    appLang: state.lang.appLanguage,
    cities: state.enums.citiesList,
    citiesStatus: state.enums.citiesListStatus,
    marketingsList: state.enums.marketingsList,
    marketingsListStatus: state.enums.marketingsListStatus
}}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MultiplierComponent));
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import SectionNavigationBar from "../../../_components/form-bb-components/sectionNavigationBar/SectionNavigationBar";
import * as Relationship from '../../../_forms/BBForm/values/relationship';
import * as IsContactPersonDataSameAs from '../../../_forms/BBForm/values/isContactPersonDataSameAs';
import * as Sections from '../../../_forms/BBForm/values/sections';

class SectionNavigationToolbarComponent extends React.Component {
    _isMounted = false;
    leadsTimeout;
    taskTimeout;
    constructor(props) {
        super(props);
        this.state = {
            form: props.form,
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    };

    changeSectionIfUCan = (number) =>{
        if(this.props.isUnderAnalize){
            if(number>=0 && number <= this.props.sectionMax){
                const { form } = this.props;
                const isContactPersonDataSameAs = form.getFieldByKey('is_contact_person_data_same_as');
                const isDegreeRelationShipWithPatientCarer = form.getFieldByKey('is_degree_relationship_with_patient_carer');
                if(number===Sections.CONTACT_PERSON){
                    if (isContactPersonDataSameAs.value !== IsContactPersonDataSameAs.YES) {
                        this.props.changeSection(number)
                        this.props.hideSectionMenu();
                    }
                }else{
                    if(number===Sections.PATIENT){
                        if(isDegreeRelationShipWithPatientCarer.value !== Relationship.TYPE_8){
                            this.props.changeSection(number)
                            this.props.hideSectionMenu();
                        }
                    }else{
                        this.props.changeSection(number)
                        this.props.hideSectionMenu();
                    }
                }
            }
        }
    }

    toogleIfUCan = () => {
        if(this.props.isUnderAnalize){
            this.props.toggleSectionMenu();
        }
    }

    render() {
        const { form, currentSection, sectionMax, sectionMenuOpen, translateKey, rerender, showSectionValidation, isUnderAnalize, hidden} = this.props;
        return (
            <React.Fragment>
               <div className={`main-menu-toolabr ${hidden?"hideMe":''}`}>
                    <div className={`main-menu-list ${!isUnderAnalize? 'no-pointer-events': ''}`}>
                        <i className="fas fa-chevron-left fa-2x navigation_icon_color prev-navi" onClick={()=>this.changeSectionIfUCan(currentSection-1)}></i>
                        <div className="page-list-container">
                            <div className="form-navi-section-wrapper">{translateKey(form.sections[currentSection].sectionName)}</div>
                            <div className="page-number-wrapper">
                                <span className="navigation-actual-page-number">
                                    {form.sections[currentSection].number}
                                </span>
                                <span className="navigation-rest-page-number">/{sectionMax}</span>
                            </div>
                        </div>
                        <i className={`fas fa-caret-down fa-2x navigation_icon_color carpet-navi ${sectionMenuOpen? 'active':''}`} onClick={this.toogleIfUCan}></i>
                        <i className="fas fa-chevron-right fa-2x navigation_icon_color next-navi" onClick={()=>this.changeSectionIfUCan(currentSection+1)}></i>
                    </div>
                </div>
                {sectionMenuOpen && (
                        <div className="section-menu-navigation-section">
                            <SectionNavigationBar showSectionValidation={showSectionValidation} rerender={rerender} sectionMax={sectionMax} changeSection={(number)=>this.changeSectionIfUCan(number)} sectionList={form} activeSection={currentSection}/>
                        </div>
                    )
                }
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(SectionNavigationToolbarComponent));
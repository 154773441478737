import * as React from 'react';
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";

export class CheckBoxComponent extends React.Component {
    static defaultProps = {
        validationErrors: [],
        className: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            text: props.text,
            isIm: props.isImportant
        };
    }

    setTypeChange = (e) => {
        if(!this.props.disabled){
            this.props.setTypeChangeToParent(this.props.keyName, e.target.checked);
        }
    };

    setCheckboxValue = (value) => {
        if(!this.props.disabled){
            this.props.setTypeChangeToParent(this.props.keyName, value);
        }
    };


    render() {
        const {
            value,
            validationErrors,
            showValidation,
            keyName,
            className,
            transKey,
            isImportant,
            text,
            newUI,
            checkClassWrapper,
            disabled,
            labelClass,
            icon, 
            svgIcon,
            popover,
            popoverText
        } = this.props;
        const showErrors = validationErrors.length > 0 && showValidation;
        return (
            <div className={className}>
                <div className={`checkbox-button-group-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                    {icon &&
                        <div className="list-icon-class-wrapper">
                        <img src={icon} alt="" className={`list-icon-class ${svgIcon && 'list-icon-class-svg'}`}/>
                        </div>
                    }
                    <div 
                        className={
                        `custom-control custom-checkbox custom-control-inline 
                        ${disabled ? '' : 'cursor-pointer'} 
                        ${checkClassWrapper ? checkClassWrapper : ''}
                        ${popover ? 'alignStart' : ''}
                        `}
                    >
                        <i className={`fas fa-2x fa-check check-fa-checkbox-icon ${value?'check-fa-active':'check-fa-not-active'}`} onClick={()=>this.setCheckboxValue(!value)}></i>
                        <input className="form-check-input" hidden onChange={this.setTypeChange} checked={value} data-field-name={keyName} type="checkbox" name={transKey} id={transKey} value={value} />
                        {text && 
                            <label className={`form-check-label ${newUI ? 'newUI-text-color' : ''} ${disabled ? '' : 'cursor-pointer'} ${isImportant ? 'input_important_class' : ''} ${labelClass ? labelClass : ''}`} htmlFor={transKey}>
                                <div className={`${newUI? '':'quick-label-position-fix'}`}>
                                    {text}
                                    { popover &&
                                        <div className="checkbox-info-subtitle">
                                        <i className="fas fa-question-circle fa-1x orange-icon default-btn-hover"/>
                                        <span>{popoverText}</span>
                                        </div>
                                    }
                                </div>
                            </label>
                        }
                    </div>
                    { showErrors && (
                        <ValidationErrorsList errors={validationErrors} keyName={keyName} />
                    )}
                </div>
            </div>
        );
    }
}

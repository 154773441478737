import axiosZoneBackend from './axios-zone-backend';
import axios from 'axios';
import { baseData } from '../../_constance/base_data';
import { REFRESHED_TOKEN, REFRESHING_TOKEN, AUTH_LOGOUT, CLEAR_ALL_DATABASE } from '../../_redux/actions/action-types';
import ReqHelper from "../../_helpers/request_helper"
import store from '../../_redux/store';

const request = axios.create({
  baseURL: baseData.base_api
});

request.interceptors.request.use(config => {
  const token = sessionStorage.getItem('ap:appToken');
  config.headers = { 'X-Auth-Token': token };
  const currentTime = new Date().getTime();
  const actionTime = sessionStorage.getItem('ap:appActionTime');
  if (actionTime < currentTime) {
    logout();
    return Promise.reject("session expired");
  } else {
    return config;
  }
}, err => {
  return Promise.reject(err);
});

let isRefreshing = false;

let subscribers = [];

request.interceptors.response.use(config => {
  return config;
}, error => {
  const { config, response: { status } } = error;
  const originalRequest = config;

  const appZoneToken = sessionStorage.getItem('ap:appZoneToken');

  if (status === 401 && appZoneToken) {
    if (!isRefreshing) {
      isRefreshing = true;
      store.dispatch({type: REFRESHING_TOKEN});
      axiosZoneBackend.post('ZoneApi/refreshCrmToken', null, {
        headers: {
          'X-Auth-Token': appZoneToken,
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(result => {
        console.log(result);
        sessionStorage.setItem("ap:appToken", result.data.data);
        store.dispatch({
          type: REFRESHED_TOKEN,
          appToken: result.data.data
        });
        onRrefreshed(result.data.data);
        isRefreshing = false;
      }).catch(err => {
        console.log('Błąd odświeżania');
        logout();
        isRefreshing = false;
      });
      subscribers = [];
    }
    const requestSubscribers = new Promise(resolve => {
      subscribeTokenRefresh(token => {
        originalRequest.headers = { ...originalRequest.headers, 'X-Auth-Token': token }
        resolve(axios(originalRequest));
      });
    });
    return requestSubscribers;
  } else if (status === 401 && !isRefreshing) {
    console.log('401 i brak możliwości refresh');
    logout();
    return Promise.reject(ReqHelper.responseErrorHelper(error));
  } else {
    console.log(error);
    console.log('każdy inny przypadek');
    return Promise.reject(status);
  }
});


function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRrefreshed(token) {
  subscribers.map(cb => cb(token));
}


function logout() {
  sessionStorage.removeItem('ap:appZoneToken');
  sessionStorage.removeItem('ap:appZoneName');
  sessionStorage.removeItem('ap:appZoneSurname');
  sessionStorage.removeItem('ap:appZonePatient');
  sessionStorage.removeItem('ap:appZoneClientId');
  sessionStorage.removeItem("ap:appToken")
  sessionStorage.removeItem("ap:appZoneRest");
  store.dispatch({ type: AUTH_LOGOUT });
  store.dispatch({type: CLEAR_ALL_DATABASE});
}

export default request;
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {  translateKey } from "../../_redux/actions/index";
import { LoaderModal } from "../../_components/index";
import NavBar from "../../_components/navbar/NavBar";
import {IconsMap} from "../../_helpers/icons_set";
import FooterComponent from "../../_components/footer/Footer";
import ReqHelper from "../../_helpers/request_helper";

class ContactComponent extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loader: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    document.body.classList.remove('modal-open');
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
  };

  _promptUnsavedChange(isUnsaved = false) {
    window.onbeforeunload = isUnsaved;
  }

  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  render() {
    const { translateKey, appZoneRest } = this.props;
    let fpData = this.IsJsonString(appZoneRest)?JSON.parse(appZoneRest):'';
    let fpEmailAddress = fpData.fpFirstMail;
    if(!fpEmailAddress){
      if(!ReqHelper.isEmail(fpEmailAddress)){
        if(fpData.fpSecondMail){
          if(!ReqHelper.isEmail(fpData.fpSecondMail)){
            fpEmailAddress = fpData.fpSecondMail;
          }
        }
      }
    }
    return (
      <div>
        <NavBar />
        <div className="section-slider" style={{ backgroundImage: `url(${IconsMap.other.background_zone})`, backgroundPosition: "top" }}>
          <div className="container">
            <div className="zone-slider-text">
              {translateKey("zone_main_text")}
            </div>
          </div>
        </div>
        <div className="container zone-content-wrapper no_padding">
          <div className="zone-content-form-wrapper">
            <LoaderModal show={this.state.modalLoader}/>
            <div className="zone-form-wrapper">
              <div className="zone-content zone-content-login">
                <div className="zone-conent-section-title">
                  <div className="title">
                    {translateKey("zone-kontakt-zum-vormund")}
                  </div>
                </div>
              </div>
                
              <div className="container contact-data-wrapper">
                <div className="row">
                  <div className="col-sm-6 contact-container-wrapper">
                    <div className="user-image-wrapper">
                      <i className="far fa-2x fa-user"></i>
                    </div>
                  </div>
                  <div className="col-sm-6 contact-info-wrapper">

                    <div className="flexAlignCenter no-margin-row">
                      <div className="zone-conent-section-title">
                        <div className="contact-title">
                          {fpData.fpName}
                        </div>
                      </div>
                    </div>

                    <div className="flexAlignCenter no-margin-row">
                      <div className="fp-contact-wrapper">
                        <a href={"mailto:"+fpData.fpFirstMail} target="_top">
                          <img src={IconsMap.svg.email} alt="Email" className="user-name-icon"/>
                        </a>
                        {fpEmailAddress}
                      </div>
                    </div>
                    <div className="flexAlignCenter no-margin-row">
                      <div className="fp-contact-wrapper">
                        <a href={"tel:"+fpData.fpPhone}>
                          <img src={IconsMap.svg.phone} alt="Phone" className="user-name-icon"/>
                        </a>
                        { fpData.fpPhone }
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLang: state.lang.appLanguage,
    appZoneRest: state.auth.appZoneRest,
  };
};
const mapDispatchToProps = dispatch => ({
  translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactComponent));
export const Tiles = {
    TILEFIRSTCLOCK: "Szybkie rozpoczęcie opieki",
    TILESECONDBRAIN: "Opieka nad osobą z demencja",
    TILETHIRDQUESTION: "Opieka nad osobą z sondą PEG",
    TILEFOURTHLANG: "Dobre umiejętności językowe opiekunki",
    TILEFIFTHBED: "Opieka z pełnym transferem",
    TILESIXPANTIES: "Opieka nad osobą z problemami kontynencji",
    TILESEVENSTOMA: "Wymiany worka stomijnego",
    TILEEIGHTMEAL: "Przygotowywanie posiłków dla diabetyków",
    TILENINESONDA: "Sonda PEG",
    TILETENBAG: "Wymiany worka na mocz",
    TILEELEVENCAR: "Prawo jazdy"
}
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";

class TaskPriorityConverter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            priority: this.props.priority
        }
    }

    getMePriorityText(value){
        const status = this.props.taskPriority.filter(element => element.Value.toString()===value.toString());
        if(status.length===0){
            return '';
        }else{
            return status[0].Label;
        }
    }

    render() {
        return (
            <span>{this.getMePriorityText(this.props.priority)}</span>
        );
    }
}

const mapStateToProps = state => {return {taskPriority: state.enums.taskPriority};}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskPriorityConverter));
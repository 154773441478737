import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import WrappedDatePickerComponent from "../../form-bb-components/wrapped-date-picker-component/wrapped-date-picker-component";
import ListGenderComponent from "../../form-bb-components/list-gender-component/list-gender-component";
import SectionNavigationButtonsComponent from "../../../_components/form-bb-components/section-navigation-buttons-component/section-navigation-buttons-component";
import ReqHelper from "../../../_helpers/request_helper";
import { Tiles } from "../../../_constance/classes/dndPriorityOffer/TilesIds";
import produce from "immer";
import * as SkillRange from "../../../_forms/BBForm/values/skillRange";

// key needed to retrieve information about patient weight
const IS_WEIGHT = "is_weight";

// key needed to retrieve information about candidate gender
const IS_CANDIDATE_GENDER = "is_candidate_gender";
const IS_START_DATE_CONTACT = "is_start_date_contact";
const IS_END_DATE_CONTACT = "is_end_date_contact";

// filed to store tiles data
const KEY_NAME_TILES = 'is_most_important_taking_care_patient';
const SKIP_SAVE_STRING_TILES = 'skip_save_string_tiles'; 

class Section16 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: props.data,
            allrequired: false,
            section: props.form.getSectionByKey('section-16'),
            showValidation: props.showValidationErrors,
            patientWeightInfo: false,
            rerender: Date.now()
        };
    }

    componentDidMount() {this._isMounted = true;}
    
    componentWillUnmount() {this._isMounted = false;}

    componentDidUpdate(prevProps) {
        const { showValidationErrors } = this.props;
        if (prevProps.showValidationErrors !== showValidationErrors) {
            this.setState({showValidation: showValidationErrors});
        }
    }

    saveDiabetesAndTiles = () =>{
        const saveDiabetesTileField = this.props.form.getFieldByKey(SKIP_SAVE_STRING_TILES);
        if(saveDiabetesTileField){saveDiabetesTileField.value = true}
    }

    setTileFieldValue = (action, string) =>{
        const { form } = this.props;
        const tilesString = form.getFieldByKey(KEY_NAME_TILES);
        const list = ReqHelper.prepareMeListFromString(tilesString.value, ';');
        if(ReqHelper.notNullAndUndefinied(tilesString.value) && action===true){
            if(list.indexOf(string)<0){
                tilesString.value += ';'+string;
            }
        }else{
            if(action){
                tilesString.value += ';'+string;
            }else{
                if(list.indexOf(string)>-1){
                    tilesString.value += ';'+string;
                    list.splice(list.indexOf(string),1);
                    tilesString.value = list.join(';')
                }
            }
        }
    }

    setTypeChange = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {
                field.value = value;
            }
        });
        this.setState(nextState);

        const patientWeight = this.props.form.getFieldByKey(IS_WEIGHT);
    
        if (key === IS_CANDIDATE_GENDER && value === SkillRange.PARTIAL && parseInt(patientWeight.value) >= 80) {
          this.setState({
            patientWeightInfo: true
          })
        } else {
          this.setState({
            patientWeightInfo: false
          })
        }
    };

    setTypeChangeDates = (key, value) => {
        const nextState = produce(this.state, draftState => {
            const { section } = draftState;
            const field = section.getFieldByKey(key);
            if (field) {field.value = value;}
            let endDate = new Date(new Date(value * 1000).setFullYear(new Date(value * 1000).getFullYear() + 1));
            endDate.setDate(endDate.getDate()-2);
            const endDateField = section.getFieldByKey(IS_END_DATE_CONTACT);
            if (endDateField) {endDateField.value = Math.floor(endDate.getTime() / 1000);}
            let dateAfterTwoWeeks = new Date();
            dateAfterTwoWeeks.setDate(dateAfterTwoWeeks.getDate() + 14);
            if(new Date(value * 1000)<dateAfterTwoWeeks){
                this.setTileFieldValue(true, Tiles.TILEFIRSTCLOCK);
                this.saveDiabetesAndTiles();
            }else{
                this.setTileFieldValue(false, Tiles.TILEFIRSTCLOCK);
                this.saveDiabetesAndTiles();
            }
        });
        this.setState(nextState);
    }

    getMeMaxDate = ()=> {
        const { section } = this.state;
        const isStartDateContact = section.getFieldByKey(IS_START_DATE_CONTACT);
        let endDate = new Date(new Date(isStartDateContact.value * 1000).setFullYear(new Date(isStartDateContact.value * 1000).getFullYear() + 1));
        endDate.setDate(endDate.getDate()-2);
        return endDate;
    }

    render() {
        const {
          disabled,
          translateKey,
          subsectionName,
          sectionNumber,
          analizeFinished,
          currentSection,
          rerender,
          previousPageClick,
          nextPageClick,
          finishMe
        } = this.props;
        const { section, showValidationErrors, patientWeightInfo } = this.state;
        const isStartDateContact = section.getFieldByKey(IS_START_DATE_CONTACT);
        return (
            <div className="form-section-main">
                <div className="new-section-name-wrapper">
                    {sectionNumber}. {subsectionName}
                </div>
                <form className="new-section-content-wrapper container form_serializer">
                    <fieldset disabled={disabled} className={`${disabled?'no-pointer-events':''}`}> 

                        { patientWeightInfo &&
                            <div className="newUI-section-padding">
                                <div className="tumor-or-dekubitus-info-wrapper"> 
                                {translateKey("form-weight-modal-text")}
                                </div>
                            </div>
                        }  

                        <div className="newUI-section-padding"> 
                            <div className="row">
                                <ListGenderComponent
                                    section={section}
                                    className="col-sm-12"
                                    showValidation={showValidationErrors}
                                    setTypeChangeToParent={this.setTypeChange}
                                    newUI={true}
                                    rerender={rerender}
                                />
                            </div>
                        </div>

                        <div className="newUI-section-padding">
                            <div className="row"> 
                                <WrappedDatePickerComponent
                                    newUI={true}
                                    className="col-sm-12 col-lg-6 form-margin-bottom-fix"
                                    showValidation={this.state.showValidation}
                                    keyName="is_start_date_contact"
                                    field={isStartDateContact}
                                    value={isStartDateContact.value}
                                    validationErrors={isStartDateContact.validationErrors}
                                    text={translateKey("contract-text-voraussichtlicher-begin")}
                                    setTypeChangeToParent={this.setTypeChangeDates}
                                    disabled={disabled}
                                    withYear={true}
                                    withMin={ new Date() }
                                />
                            </div>
                        </div>
                    </fieldset>
                </form>
                <SectionNavigationButtonsComponent
                    analizeFinished={analizeFinished}
                    currentSection={currentSection}
                    rerender={rerender}
                    previousPageClick={previousPageClick}
                    nextPageClick={nextPageClick}
                    finishMe={finishMe}
                />
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(null, mapDispatchToProps)(Section16));
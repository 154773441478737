import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import LoginPage from './_pages/login/login_page';
import LogOutPage from './_pages/logoutPage/logoutPage';
import LogoutToAuslogen from './_pages/logoutToAuslogen/logoutToAuslogen';
import MainPatientWrapper from './_pages/patientBB/mainPatientWrapper';
import ContactComponent from './_pages/contact/contact';
import AusLogenPage from './_pages/ausLogenPage/ausLogenPage';
import { baseData } from './_constance/base_data';
import { authCheckState } from './_redux/actions/auth';
import { getBasicSelectedLang, loadBasicDataToRedux, loadBasicEnumsToRedux, getCitiesData } from './_redux/actions/index';
import { connect } from 'react-redux';
require('es6-promise/auto');
require("jspolyfill-array.prototype.findIndex")
require('ie-array-find-polyfill');

class Root extends Component {

    componentDidMount() {
        this.props.tryAutoLogin();
        this.props.getBasicSelectedLang();
    }

    componentDidUpdate() {
        const {isAuthenticated, getCitiesData} = this.props;
        if (isAuthenticated) {
            //load basic data to redux
            getCitiesData();
        }
    }

    render() {
        let routes = null;
        if (!this.props.isAuthenticated) {
            routes = (
                <Switch>
                    <Route path={`${baseData.subFolders}auslogen`} exact component={AusLogenPage} />
                    <Route path={baseData.subFolders} component={LoginPage} />
                </Switch>
            );
        } else {
            routes = (
                <Switch>
                    <Route path={`${baseData.subFolders}logoutToAusLogen`} exact component={LogoutToAuslogen} />
                    <Route path={`${baseData.subFolders}logout`} exact component={LogOutPage} />
                    <Route path={`${baseData.subFolders}form`} exact component={MainPatientWrapper} />
                    <Route path={`${baseData.subFolders}contact`} exact component={ContactComponent} />
                    <Redirect to={`${baseData.subFolders}form`} />
                </Switch>
            );
        }

        return (
            <Router>
                {routes}
            </Router>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        tryAutoLogin: () => dispatch(authCheckState()),
        getBasicSelectedLang: () => dispatch(getBasicSelectedLang()),
        loadBasicDataToRedux: (lang) => dispatch(loadBasicDataToRedux(lang)),
        loadBasicEnumsToRedux: (lang) => dispatch(loadBasicEnumsToRedux(lang)),
        getCitiesData: () => dispatch(getCitiesData()),
    };
};
const mapStateToProps = (state) => {return {isAuthenticated: state.auth.appToken !== null && state.auth.appToken !== undefined && state.auth.appZoneToken}};
export default connect(mapStateToProps, mapDispatchToProps)(Root);
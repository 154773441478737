import { UPDATE_ACTION_TIMESTAMP, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_FAIL, AUTH_START, REFRESHING_TOKEN, REFRESHED_TOKEN, CLEAR_ALL_DATABASE, UPDATE_PATIENT_ID } from "./action-types"
import { refreshTokenTime } from '../../_constance/base_data';
import ReqHelper from '../../_helpers/request_helper';
import axiosZone from '../../_services/config/axios-zone-backend';


export const authCheckState = () => {
  return dispatch => {
    const currentTime = new Date().getTime();
    const zoneId = sessionStorage.getItem('ap:appZoneId');
    const zonetoken = sessionStorage.getItem('ap:appZoneToken');
    const appZoneName = sessionStorage.getItem('ap:appZoneName');
    const appZoneSurname = sessionStorage.getItem('ap:appZoneSurname');
    const appZonePatient = sessionStorage.getItem('ap:appZonePatient');
    const appZoneClientId = sessionStorage.getItem('ap:appZoneClientId');
    const token = sessionStorage.getItem('ap:appToken');
    const actionTime = sessionStorage.getItem('ap:appActionTime');
    const appZoneRest = sessionStorage.getItem('ap:appZoneRest');
    const appUserId = sessionStorage.getItem('ap:appUserId');
    const appZoneSecondPatient = sessionStorage.getItem('ap:appZoneSecondPatient');
    
    if (
      (
        zoneId &&
        appZoneName &&
        appZoneSurname &&
        appZonePatient &&
        zonetoken &&
        token && 
        appZoneRest
      ) 
      && 
      (actionTime >= currentTime)) {
      dispatch(
        authSuccess(
          zoneId, 
          zonetoken,
          appZoneName,
          appZoneSurname,
          appZonePatient,
          appZoneClientId,
          appZoneSecondPatient,
          token,
          appUserId,
          appZoneRest
        )
      );
      dispatch(authSetActionTimestamp());
    } 
    else if (actionTime < currentTime)  {
      onLogout();
    } else {
      onLogout();
    }
  }
}

export const authZone = (login, password) => {
  return dispatch => {
    dispatch(authStart());
    return axiosZone.post('ZoneApi/login', null, {
      headers: {
        'X-Auth-Login': login,
        'X-Auth-Password': password,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 200) {
          sessionStorage.setItem('ap:appZoneId', res.data.data.id);
          sessionStorage.setItem('ap:appZoneName', res.data.data.name);
          sessionStorage.setItem('ap:appZoneSurname', res.data.data.surname);
          sessionStorage.setItem('ap:appZonePatient', res.data.data.patient_id);
          sessionStorage.setItem('ap:appZoneClientId', res.data.data.client_id);
          sessionStorage.setItem('ap:appZoneSecondPatient', res.data.data.secondPatient);
          sessionStorage.setItem('ap:appToken', res.data.data.crm_token);
          sessionStorage.setItem('ap:appUserId', JSON.stringify(res.data.data));
          sessionStorage.setItem('ap:appZoneRest', JSON.stringify(res.data.data));
          dispatch(authSuccess(
            res.data.data.id,
            res.data.data.token,
            res.data.data.name,
            res.data.data.surname,
            res.data.data.patient_id,
            res.data.data.client_id,
            res.data.data.secondPatient,
            res.data.data.crm_token,
            res.data.data.user_id,
            JSON.stringify(res.data.data)
          ));
          dispatch(authSetActionTimestamp());
          return Promise.resolve();
        } else {
          dispatch(authFail("Not 200"))
          return Promise.reject(res.status);
        }
      })
      .catch(error => {
        if(error.code.response){
          dispatch(authFail(ReqHelper.responseErrorHelper(error.code.response.status)))
          return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
        }else{
          dispatch(authFail(ReqHelper.responseErrorHelper(999)))
          return Promise.reject(ReqHelper.responseErrorHelper(999));
        }
      });
  }
}

export const userFinishedKunde = (token, data) => {
  return dispatch => {
    dispatch(authStart());
    return axiosZone.post('ZoneApi/userFinishedKunde', data, {
      headers: {
        'X-Auth-Token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 200) {
          return Promise.resolve();
        } else {
          return Promise.reject(res.status);
        }
      })
      .catch(error => {
        if(error.code.response){
          return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
        }else{
          return Promise.reject(ReqHelper.responseErrorHelper(999));
        }
      });
  }
}

export const confirmSecondPatient = (token, data) => {
  return dispatch => {
    dispatch(authStart());
    return axiosZone.post('ZoneApi/confirmSecondPatient', data, {
      headers: {
        'X-Auth-Token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 200) {
          return Promise.resolve();
        } else {
          return Promise.reject(res.status);
        }
      })
      .catch(error => {
        if(error.code.response){
          return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
        }else{
          return Promise.reject(ReqHelper.responseErrorHelper(999));
        }
      });
  }
}

export const onLogout = () => {
  return dispatch => {
    dispatch({type: CLEAR_ALL_DATABASE});
    dispatch(logout());
  }
}

export const refreshToken = () => {
  return dispatch => {
      dispatch(authRefreshToken());
  }
}

export const onRefreshToken = (token) => {
  return dispatch => {
    dispatch(authUpdatedToken(token));
  };
}

export const logout = () => {
  sessionStorage.removeItem('ap:appZoneToken');
  sessionStorage.removeItem('ap:appZoneName');
  sessionStorage.removeItem('ap:appZoneSurname');
  sessionStorage.removeItem('ap:appZonePatient');
  sessionStorage.removeItem('ap:appZoneClientId');
  sessionStorage.removeItem('ap:appZoneSecondPatient');
  sessionStorage.removeItem("ap:appToken");
  sessionStorage.removeItem("ap:appUserId");
  sessionStorage.removeItem("ap:appZoneRest");
  return {
    type: AUTH_LOGOUT
  };
};

export const authStart = () => {
  return {
    type: AUTH_START
  };
};

export const authSaveAndUpdatePatient = (token, user_id, patient_id) => {
  return dispatch => {
    let preparedData = {
      "user_account_id": user_id,
      "user_patient_id": patient_id
    }
    return axiosZone.post('ZoneApi/updatePatient', preparedData, {
      headers: {
        'X-Auth-Token': token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 200) {
          dispatch(authUpdatedPatientID());
          return Promise.resolve();
        } else {
          dispatch(authFail("Not 200"))
          return Promise.reject(res.status);
        }
      })
      .catch(error => {
        if(error.code.response){
          dispatch(authFail(ReqHelper.responseErrorHelper(error.code.response.status)))
          return Promise.reject(ReqHelper.responseErrorHelper(error.code.response.status));
        }else{
          dispatch(authFail(ReqHelper.responseErrorHelper(999)))
          return Promise.reject(ReqHelper.responseErrorHelper(999));
        }
      });
  }
}

export const authUpdatedPatientID = (patientID) => {
  sessionStorage.setItem('ap:appZonePatient', patientID);
  return {
    type: UPDATE_PATIENT_ID,
    patientID: patientID
  };
};

export const authUpdatedToken = (token) => {
  sessionStorage.setItem("ap:appToken", token)
  return {
    type: REFRESHED_TOKEN,
    appToken: token
  };
};

export const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error: error
  };
}

export const authSuccess = (
  zoneId,
  zoneToken,
  appZoneName,
  appZoneSurname,
  appZonePatient,
  appZoneClientId,
  secondPatient,
  token,
  appUserId,
  appZoneRest
) => {
  sessionStorage.setItem('ap:appZoneId', zoneId);
  sessionStorage.setItem('ap:appZoneToken', zoneToken);
  sessionStorage.setItem('ap:appZoneName', appZoneName);
  sessionStorage.setItem('ap:appZoneSurname', appZoneSurname);
  sessionStorage.setItem('ap:appZonePatient', appZonePatient);
  sessionStorage.setItem('ap:appZoneClientId', appZoneClientId);
  sessionStorage.setItem('ap:appZoneSecondPatient', secondPatient);
  sessionStorage.setItem('ap:appToken', token);
  sessionStorage.setItem('ap:appUserId', appUserId);
  sessionStorage.setItem('ap:appZoneRest', appZoneRest);
  return {
    type: AUTH_SUCCESS,
    zoneToken: zoneToken,
    appZoneId: zoneId,
    appZoneName: appZoneName,
    secondPatient: secondPatient,
    appZoneSurname: appZoneSurname,
    appZonePatient: appZonePatient,
    appZoneClientId: appZoneClientId,
    appToken: token,
    appUserId: appUserId,
    appZoneRest: appZoneRest
  }
}

export const authSetActionTimestamp = () => {
  const currentTime = new Date().getTime();
  let actionTime = currentTime + refreshTokenTime.actionExpirationTime;
  sessionStorage.setItem("ap:appActionTime", actionTime);
  return {
    type: UPDATE_ACTION_TIMESTAMP,
    actionTimestamp: actionTime
  };
}

export const authRefreshToken = () => {
  return {
    type: REFRESHING_TOKEN
  };
}

import * as React from 'react';

export class TableColumHead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sorting: this.props.sorting,
            name: this.props.name,
            type: this.props.type
        }
    }

    handleClick(){
        this.props.onSortType(this.state.type);
    }

    render() {
        let isSorting = '';
        for(let i=0; i<this.props.sorting.length; i++){
            if(this.props.sorting[i].column===this.props.type){
                if(this.props.sorting[i].active){
                    if(this.props.sorting[i].type==="ASC"){
                        isSorting = <span className="sorting-icon"><i className="fas fa-sort-amount-down"></i></span>;
                    }else{
                        isSorting = <span className="sorting-icon"><i className="fas fa-sort-amount-up"></i></span>;
                    }
                }
            }
        }
        return (
            <th scope="col" onClick={()=>this.handleClick()}>{this.state.name}{isSorting}</th>
        );
    }
}
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translateKey } from "../../../_redux/actions/index";
import ValidationErrorsList from "../../validation-errors-list/validation-errors-list";

class ListTileComponent extends React.Component {
    setValue = (value) => {
        const { keyName, setTypeChangeToParent, shouldBeEdited } = this.props;
        if(!shouldBeEdited){
            setTypeChangeToParent(keyName, value);
        }
    };

    render() {
        const { valuesList = [], keyName, value, validationErrors, showValidation, className, translateKey, transKey, keyFix, type, newUI, icon, disabled } = this.props;
        const showErrors = showValidation && validationErrors.length > 0;
        return (
            <div className={`${className} list-component-wrapper ${showErrors ? 'is-invalid' : ''}`}>
                {icon &&
                    <div className="list-icon-class-wrapper">
                        <img src={icon} alt="" className="list-icon-class"/>
                    </div>
                }
                <label className={`form-label-basic ${newUI? 'newUI':''}`}><span className="quick-label-position-fix">{translateKey(transKey).toUpperCase()}</span></label>
                <div className={`beantragt-class-wrapper ${type?type:''} ${icon?"special-center-class-for-icon":''} ${disabled?'no-pointer-events':''}`}>
                    { valuesList.map(({ value: possibleValue, label }) => {
                        let id = `${keyName}_${possibleValue}`;
                        if(keyFix){
                            id = `${keyFix}_${possibleValue}`;
                        }
                        return (
                            <div className={`beantragt-class-tile ${disabled?'':'cursor-pointer'} ${value === possibleValue?'active':''}`} key={id} onClick={()=>this.setValue(possibleValue)}>
                                <div className="beantragt-class-tile-text">{label}</div>
                            </div>
                        )
                    })}
                </div>
                { showErrors && (
                    <ValidationErrorsList errors={validationErrors} keyName={keyName} />
                )}
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({ translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl)) });
export default withRouter(connect(null, mapDispatchToProps)(ListTileComponent));
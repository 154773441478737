import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ContractRateType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
        };
    }

    getStatusText(status){
        if(this.props.contractRateType){
            for(let i=0; i<this.props.contractRateType.length; i++){
                if(this.props.contractRateType[i].Value === status){
                    return this.props.contractRateType[i].Label;
                }
            }
        }
    }

    render() {
        return (
            <span>{this.getStatusText(this.props.element)}</span>
        );
    }
}
const mapStateToProps = state => {return {contractRateType: state.enums.contractRateType}};
export default withRouter(connect(mapStateToProps, null)(ContractRateType));
import * as React from 'react';
import * as ContactTypes from '../../_constance/values/contactFieldType';
import { IconsMap } from "../../_helpers/icons_set"

export class PhoneEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type
        };
    }

    render() {
        const { type, text } = this.props;

        return (
            <span className="basic-information-info icon">
                {type===ContactTypes.PHONE &&
                    <a href={"tel:"+text}>
                        <img src={IconsMap.svg.phone} alt="Phone"/>
                    </a>
                }
                {type===ContactTypes.EMAIL &&
                    <a href={"mailto:"+text} target="_top">
                        <img src={IconsMap.svg.email} alt="Email"/>
                    </a>
                }
                {text}
            </span>
        );
    }
}
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { translateKey } from "../../_redux/actions/index";
import { IconsMap } from '../../_helpers/icons_set'
import { baseData } from '../../_constance/base_data';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edited: false
        };
    }

    logMeOutWithCleaning = () =>{
        this.props.history.push(`${baseData.subFolders}logout`);
    }

    render() {
        const { translateKey, appZoneToken, appZoneName, appZoneSurname, patientName } = this.props;
        return (
            <MDBNavbar color="white" light expand="md">
            <div className="container navbar-zone-container">
                <MDBNavbarBrand>
                    <img className="prom-logo-top" src={IconsMap.other.prom_logo_top} alt="Prom Logo"/>
                    {patientName && (
                        <p className="patient-name">
                            {patientName}
                        </p>
                    )}
                </MDBNavbarBrand>
                {appZoneToken &&
                    <MDBNavbarNav right>
                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret className="dropdown-nav-link-zone">
                                    <span className="mr-2 text">{translateKey("zone-hello")+', '} {appZoneName + ' ' + appZoneSurname}</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu className="dropdown-list-zone-wrapper">
                                    <MDBDropdownItem className="dropdown-list-zone">
                                        <Link to={baseData.subFolders+"form"}>{translateKey("zone-fragebogen")}</Link>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem className="dropdown-list-zone">
                                        <Link to={baseData.subFolders+"contact"}>{translateKey("zone-kontakt-zum-vormund")}</Link>
                                    </MDBDropdownItem>
                                    <MDBDropdownItem className="dropdown-list-zone">
                                        <div onClick={()=>this.logMeOutWithCleaning()}>{translateKey("zone-ausloggen")}</div>
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavItem>
                    </MDBNavbarNav>
                }
            </div>
          </MDBNavbar>
        );
    }
}

const mapStateToProps = state => {
    return {
      appZoneToken: state.auth.appZoneToken,
      appZoneName: state.auth.appZoneName,
      appZoneSurname: state.auth.appZoneSurname,
    };
  }; 
const mapDispatchToProps = dispatch => ({translateKey: (firstLvl, secondLvl) => dispatch(translateKey(firstLvl, secondLvl))})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
export const ERR_REQUIRED = 'err_required';
export const ERR_TEXT_MIN_LENGTH = 'err_text_min_length';
export const ERR_TEXT_MAX_LENGTH = 'err_text_max_length';
export const ERR_WRONG_DATA_TYPE = 'err_wrong_data_type';
export const ERR_INCORRECT_VALUE = 'err_incorrect_value';

export const ERR_NOT_AN_EMAIL_ADDRESS = 'err_not_an_email_address';

export const ERR_MISSING_COUNTRY_CODE = 'err_missing_country_code';
export const ERR_INCORRECT_PHONE_NUMBER = 'err_incorrect_phone_number';
export const ERR_INCORRECT_ZERO_PHONE_NUMBER = 'err_incorrect_zero_phone_number';
export const ERR_INCORRECT_POLISH_PHONE_NUMBER = 'err_incorrect_polish_phone_number';
export const ERR_INCORRECT_GERMAN_PHONE_NUMBER = 'err_incorrect_german_phone_number';
export const ERR_INCORRECT_ENGLISH_PHONE_NUMBER = 'err_incorrect_english_phone_number';

export const AT_LEAST_ONE_YES = 'at_least_one_yes';